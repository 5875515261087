import { Divider, Input } from 'antd';
import Form from 'hew/Form';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Link from 'components/Link';
import RegionPicker from 'components/RegionPicker';
import Spinner from 'components/Spinner';
import VersionPicker from 'components/VersionPicker';
import { gcpMsgExplainCharges } from 'constants/messages';
import { useStore } from 'contexts/Store';
import { useMakeClusterCreateCall } from 'hooks/useFetch';
import {
  networkValid,
  throwCreateClusterError,
  validateClusterRequestObject,
} from 'pages/Clusters/cluster-util';
import { GkeResourcePools } from 'pages/Clusters/GkeCluster/GkeResourcePools';
import { MachineTypeDropdown } from 'pages/Clusters/GkeCluster/MachineTypeDropdown';
import NetworkingParamsPicker, {
  NetworkingParams,
} from 'pages/Clusters/GkeCluster/NetworkingParamsPicker';
import { InstanceUse, OverallConfig } from 'saasTypes';
import { ModelMasterConfig } from 'services/regional-bindings';
import { SemanticVersion } from 'types';
import handleError from 'utils/error';
import { generateGkeMasterConfig, getLatestVersion } from 'utils/saas';
import { stringToVersion, versionToString } from 'utils/string';

import css from './NewGkeMldeClusterModal.module.scss';
import { defaultPoolConfigs } from './NewGkeMldeClusterModal.settings';
import { clusterDefaults } from './utils';

interface Props {
  onFinish: () => Promise<void>;
  isModalOpen?: boolean;
  onClose: () => void;
}

const initialState = {
  overallConfig: {
    detVersion: '',
    masterInstanceType: clusterDefaults.MASTER_INSTANCE_TYPE,
  },
  poolConfigs: defaultPoolConfigs,
  resourceManager: {
    default_aux_resource_pool: clusterDefaults.CPU_POOL_NAME,
    default_compute_resource_pool: clusterDefaults.GPU_POOL_NAME,
  },
};

export const _NewGkeMldeClusterModal: React.FC<Props> = ({ onFinish, onClose }) => {
  const { supportMatrix } = useStore();

  const [canceler] = useState(() => new AbortController());
  const makeClusterCreateCall = useMakeClusterCreateCall(canceler);

  const [overallConfig, setOverallConfig] = useState<OverallConfig>(initialState.overallConfig);
  const [supportedDetVersions, setSupportedDetVersions] = useState<string[]>([]);
  const [clusterName, setClusterName] = useState<string>('');
  const [clusterRegion, setClusterRegion] = useState<string>('');
  const [networkingParams, setNetworkingParams] = useState<Partial<NetworkingParams>>({});
  const updateNetworkingParams = useCallback(
    (p: Partial<NetworkingParams>) => setNetworkingParams((prev) => ({ ...prev, ...p })),
    [setNetworkingParams],
  );

  // version update effect
  useEffect(() => {
    const latestVersion = getLatestVersion(supportedDetVersions);
    if (overallConfig.detVersion === '' && latestVersion !== undefined) {
      setOverallConfig((prev) => ({
        ...prev,
        detVersion: latestVersion,
      }));
    }
  }, [supportedDetVersions, overallConfig.detVersion]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  const [masterConfig, setMasterConfig] = useState<ModelMasterConfig>(() =>
    generateGkeMasterConfig(initialState.poolConfigs, initialState.resourceManager, true),
  );

  const handleSubmit = useCallback(() => {
    if (!masterConfig) {
      throwCreateClusterError('Invalid configuration, please resolve validation errors');
    }
    const { ipRange, ...subnetRanges } = networkingParams;
    const req = {
      masterConfig,
      ...overallConfig,
      ipRange: ipRange ?? '',
      name: clusterName,
      subnetRanges,
    };
    validateClusterRequestObject(req);
    return makeClusterCreateCall(clusterRegion, req, onFinish);
  }, [
    makeClusterCreateCall,
    clusterName,
    clusterRegion,
    masterConfig,
    onFinish,
    overallConfig,
    networkingParams,
  ]);

  useEffect(() => {
    if (supportMatrix) {
      setSupportedDetVersions(supportMatrix.supportedDetVersions);
      updateNetworkingParams({
        ipRange: supportMatrix.gkeSupportMatrix.defaultIpRange,
        ...supportMatrix.gkeSupportMatrix.defaultSubnetRanges,
      });
    }
    if (!clusterRegion && supportMatrix)
      setClusterRegion(
        supportMatrix.supportedLocations.find((region) => region.startsWith('gcp')) ?? '',
      );
  }, [supportMatrix, clusterRegion, updateNetworkingParams]);

  const [form] = Form.useForm();
  const ipRange = Form.useWatch('ipRange', form);
  const privateRange = Form.useWatch('private', form);
  const clusterRange = Form.useWatch('cluster', form);
  const servicesRange = Form.useWatch('services', form);
  const [hideMasterDropdown, setHideMasterDropdown] = useState(true);

  const onShowDropdown = useCallback(() => {
    setHideMasterDropdown(false);
  }, [setHideMasterDropdown]);

  const updateVersion = useCallback(
    (ver: SemanticVersion) => {
      setOverallConfig({ ...overallConfig, detVersion: versionToString(ver, false) });
    },
    [setOverallConfig, overallConfig],
  );

  const selectInstanceType = useCallback(
    (instanceType: string) => {
      setOverallConfig({
        ...overallConfig,
        ...{ masterInstanceType: instanceType },
      });
      setHideMasterDropdown(true);
    },
    [setHideMasterDropdown, setOverallConfig, overallConfig],
  );

  const modalContent = useMemo(() => {
    if (!supportedDetVersions.length || !clusterRegion) return <Spinner tip="Loading..." />;
    return (
      <div className={css.modalContent}>
        <Form labelCol={{ span: 24 }}>
          <Form.Item label="Region" name="region">
            <RegionPicker
              curRegion={clusterRegion}
              id="region"
              regionFilter={(region) => region.startsWith('gcp')}
              onChange={setClusterRegion}
            />
          </Form.Item>
          <Form.Item
            label="Cluster Name"
            name="clusterName"
            rules={[{ message: 'Cluster name required', required: true }]}>
            <Input
              value={clusterName}
              onChange={(e) => {
                setClusterName(e.target.value);
              }}
            />
          </Form.Item>
          <Divider />
          <h2 className={css.sectionHeader}>
            <span>Configuration</span>
          </h2>
        </Form>
        <Form
          form={form}
          initialValues={{
            detVersion: overallConfig.detVersion,
            masterInstanceType: overallConfig.masterInstanceType,
          }}
          labelCol={{ span: 24 }}>
          <Form.Item label="MLDE Version" name="mldeVersion">
            <VersionPicker
              versions={supportedDetVersions.map((v) => stringToVersion(v))}
              onChange={updateVersion}
            />
          </Form.Item>
          <Form.Item
            className={css.formItemWithLink}
            label={
              <>
                <span>Master Instance Type</span>
                <Link
                  external={true}
                  path="https://cloud.google.com/compute/docs/general-purpose-machines#n1_machines"
                  popout={true}>
                  List of Instance Types
                </Link>
              </>
            }
            labelCol={{ span: 24 }}
            name="masterInstanceType">
            <MachineTypeDropdown
              buttonLabel={overallConfig.masterInstanceType}
              clusterRegion={clusterRegion}
              hide={hideMasterDropdown}
              id="masterInstanceType"
              showArrow={false}
              use={InstanceUse.Master}
              onSelect={selectInstanceType}
              onVisibleChange={onShowDropdown}
            />
          </Form.Item>
        </Form>
        <NetworkingParamsPicker
          form={form}
          networkingParams={networkingParams}
          updateNetworkingParams={updateNetworkingParams}
        />
        <Divider />
        <GkeResourcePools updateMasterConfig={setMasterConfig} />
        <Divider />
        <div> {gcpMsgExplainCharges} </div>
      </div>
    );
  }, [
    clusterName,
    clusterRegion,
    form,
    hideMasterDropdown,
    networkingParams,
    onShowDropdown,
    overallConfig.detVersion,
    overallConfig.masterInstanceType,
    selectInstanceType,
    supportedDetVersions,
    updateNetworkingParams,
    updateVersion,
  ]);

  const submitDisabled = useMemo(
    () =>
      !(networkingParams && networkValid(ipRange, privateRange, clusterRange, servicesRange)) ||
      !clusterName ||
      !masterConfig ||
      masterConfig?.resource_pools.length === 0 ||
      supportedDetVersions.length === 0,
    [
      clusterName,
      clusterRange,
      ipRange,
      masterConfig,
      networkingParams,
      privateRange,
      servicesRange,
      supportedDetVersions.length,
    ],
  );

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="medium"
      submit={{
        disabled: submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Create Cluster',
      }}
      title="New Cluster"
      onClose={onClose}>
      {modalContent}
    </Modal>
  );
};

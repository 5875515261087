import { Divider } from 'antd';
import Form from 'hew/Form';
import Input from 'hew/Input';
import { Modal } from 'hew/Modal';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import RegionPicker from 'components/RegionPicker';
import { useStore } from 'contexts/Store';
import { useMakeClusterCreateCall } from 'hooks/useFetch';
import { OverallConfig } from 'saasTypes';
import { byokClusterCreationPrecheck, getAllocatedResources } from 'services/api';
import {
  ModelAuxiliaryResources,
  ModelByokSupportMatrix,
  ModelClusterInfo,
  ModelCreateClusterRequest,
  ModelMasterConfig,
} from 'services/regional-bindings';
import handleError from 'utils/error';
import { generateByokMasterConfig, getLatestVersion } from 'utils/saas';

import AdvancedConfigForm from './AdvancedConfig';
import css from './NewByokMldeClusterModal.module.scss';
import { initialState } from './NewByokMldeClusterModal.settings';
import { KeyValuePair, validateClusterRequestObject } from './utils';

interface Props {
  isModalOpen?: boolean;
  onFinish: () => Promise<void>;
  onClose: () => void;
}

interface RPProps {
  canceler: AbortController;
  children: React.ReactNode;
  cluster?: ModelClusterInfo;
  isModalOpen?: boolean;
  masterConfig: ModelMasterConfig;
  availableNodes: ModelByokSupportMatrix;
  setSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface NetworkingParams {
  ipRange: string;
  private0: string;
  private1: string;
  public: string;
}

type ResourceCount = {
  gpuCount: number;
  cpuCount: number;
};

type RemainingResources = {
  cpuCount: number | undefined;
  gpuCount: number | undefined;
};

type ResourceInfo = {
  nodeCapacities: Record<string, ResourceCount>;
  remainingResources: RemainingResources;
  cpuExceedsQuota: string;
  gpuExceedsQuota: string;
  quotasEnabled: boolean;
};

export const ResourceContext = createContext<ResourceInfo | null>(null);

export const ResourceInfoProvider: React.FC<RPProps> = ({
  canceler,
  cluster,
  children,
  availableNodes,
  isModalOpen,
  masterConfig,
  setSubmitDisabled,
}) => {
  const {
    orgState: { selectedOrg, orgQuotas },
    supportMatrix,
  } = useStore();

  const [supportedDetVersions, setSupportedDetVersions] = useState<string[]>([]);
  const [overallConfig, setOverallConfig] = useState<OverallConfig>(initialState.overallConfig);

  const [cpuExceedsQuota, setCpuExceedsQuota] = useState('');
  const [gpuExceedsQuota, setGpuExceedsQuota] = useState('');

  useEffect(() => {
    if (supportMatrix) {
      setSupportedDetVersions(supportMatrix.supportedDetVersions);
    }
  }, [supportMatrix]);

  const [remainingResources, setRemainingResources] = useState<RemainingResources>(
    initialState.remainingResources,
  );
  const nodeCapacities = useMemo(() => {
    const caps: Record<string, ResourceCount> = {};
    availableNodes.availableNodes.forEach((node) => {
      caps[node.name] = {
        cpuCount: parseInt(node.capacity['cpu'] ?? 0),
        gpuCount: parseInt(node.capacity['nvidia.com/gpu'] ?? 0),
      };
    });
    return caps;
  }, [availableNodes.availableNodes]);

  const selectedResources = useMemo(() => {
    const pools = masterConfig.resource_pools;
    const resources = { cpuCount: 0, gpuCount: 0 };
    const countedNodes: Set<string> = new Set();
    pools.forEach((p) => {
      const name = p.pool_name;
      const nodeCap = nodeCapacities[name];
      if (name && nodeCap && !countedNodes.has(name)) {
        resources.gpuCount += nodeCap.gpuCount;
        resources.cpuCount += nodeCap.cpuCount;
        countedNodes.add(name);
      }
    });
    return resources;
  }, [masterConfig.resource_pools, nodeCapacities]);

  useEffect(() => {
    setCpuExceedsQuota(
      remainingResources.cpuCount == null
        ? ''
        : remainingResources.cpuCount < selectedResources.cpuCount
        ? `Selected nodes cpu capacity exceeds quota by ${
            selectedResources.cpuCount - remainingResources.cpuCount
          }`
        : '',
    );
    setGpuExceedsQuota(
      remainingResources.gpuCount == null
        ? ''
        : remainingResources.gpuCount < selectedResources.gpuCount
        ? `Selected nodes gpu capacity exceeds quota by ${
            selectedResources.gpuCount - remainingResources.gpuCount
          }`
        : '',
    );
  }, [remainingResources, selectedResources, setCpuExceedsQuota, setGpuExceedsQuota]);

  useEffect(() => {
    const fetchAllocatedResources = async () => {
      if (selectedOrg?.id) {
        try {
          const allocatedResources = await getAllocatedResources(
            {
              orgId: selectedOrg?.id || '',
              regionId: 'byok-us-west-2',
            },
            { signal: canceler.signal },
          );
          let gpuQuota = orgQuotas?.gpuQuota;
          let cpuQuota = orgQuotas?.cpuQuota;
          if (cpuQuota === -1) {
            cpuQuota = undefined;
          }
          if (gpuQuota === -1) {
            gpuQuota = undefined;
          }
          const gpuUsed = allocatedResources.gpuCount ?? 0;
          const cpuUsed = allocatedResources.cpuCount ?? 0;
          if (cluster !== undefined) {
            let currentClusterCPU = 0;
            let currentClusterGPU = 0;
            cluster.resourcePools.forEach((rp) => {
              const nodeCap = nodeCapacities[rp.pool_name];
              if (nodeCap) {
                currentClusterCPU += nodeCap.cpuCount;
                currentClusterGPU += nodeCap.gpuCount;
              }
            });
            setRemainingResources({
              cpuCount: cpuQuota && cpuQuota - cpuUsed + currentClusterCPU,
              gpuCount: gpuQuota && gpuQuota - gpuUsed + currentClusterGPU,
            });
          } else {
            setRemainingResources({
              cpuCount: cpuQuota && cpuQuota - cpuUsed,
              gpuCount: gpuQuota && gpuQuota - gpuUsed,
            });
          }
        } catch (e) {
          // tslint:disable:no-empty
        }
      }
    };

    if (isModalOpen && supportMatrix?.orgQuotasEnabled && orgQuotas) {
      fetchAllocatedResources();
    }
  }, [
    canceler.signal,
    cluster,
    isModalOpen,
    nodeCapacities,
    orgQuotas,
    selectedOrg?.id,
    supportMatrix?.orgQuotasEnabled,
  ]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  useEffect(() => {
    const latestVersion = getLatestVersion(supportedDetVersions);
    if (overallConfig.detVersion === '' && latestVersion !== undefined) {
      // set default detVersion value once supportedDetVersions is loaded:
      setOverallConfig((c) => ({
        ...c,
        detVersion: latestVersion,
      }));
    }
  }, [supportedDetVersions, overallConfig.detVersion]);

  useEffect(() => {
    setSubmitDisabled((prev) => prev || !!cpuExceedsQuota || !!gpuExceedsQuota);
  }, [cpuExceedsQuota, gpuExceedsQuota, setSubmitDisabled]);

  return (
    <ResourceContext.Provider
      value={{
        cpuExceedsQuota,
        gpuExceedsQuota,
        nodeCapacities,
        quotasEnabled: supportMatrix?.orgQuotasEnabled ?? false,
        remainingResources,
      }}>
      {children}
    </ResourceContext.Provider>
  );
};

export const _NewByokMldeClusterModal: React.FC<Props> = ({
  onFinish,
  isModalOpen = false,
  onClose,
}) => {
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();

  const [canceler] = useState(() => new AbortController());
  const [clusterName, setClusterName] = useState<string>('');
  const [clusterRegion, setClusterRegion] = useState<string>('');
  const [masterConfig, setMasterConfig] = useState<ModelMasterConfig>(() =>
    generateByokMasterConfig(initialState.poolConfigs, initialState.resourceManager),
  );
  const [availableNodes, setAvailableNodes] = useState<ModelByokSupportMatrix>({
    availableNodes: [],
  });
  const [supportedDetVersions, setSupportedDetVersions] = useState<string[]>([]);
  const [overallConfig, setOverallConfig] = useState<OverallConfig>(initialState.overallConfig);

  const [auxiliaryResources, setAuxiliaryResources] = useState<ModelAuxiliaryResources>(
    initialState.auxiliaryResources,
  );

  const [detMasterSvcAnnotations, setDetMasterSvcAnnotations] = useState<KeyValuePair[]>([]);
  const [detMasterSvcLabels, setDetMasterSvcLabels] = useState<KeyValuePair[]>([]);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    if (supportMatrix) {
      setSupportedDetVersions(supportMatrix.supportedDetVersions);
    }
  }, [supportMatrix]);

  useEffect(() => {
    const availableNodesPrecheck = async () => {
      if (selectedOrg?.id !== undefined && selectedOrg?.id !== '') {
        try {
          const { supportMatrix: byokSupportMatrix } = await byokClusterCreationPrecheck(
            {
              orgId: selectedOrg?.id || '',
              regionId: 'byok-us-west-2',
            },
            { signal: canceler.signal },
          );
          setAvailableNodes(byokSupportMatrix);
        } catch (e) {
          // tslint:disable:no-empty
        }
      }
    };

    if (isModalOpen) {
      availableNodesPrecheck();
    }
  }, [canceler, isModalOpen, selectedOrg?.id]);

  const makeClusterCreateCall = useMakeClusterCreateCall(canceler);

  const handleSubmit = useCallback(() => {
    // Create the request object with all required creation parameters
    // const clusterRequest = makeBasicClusterRequestObject(basicStandardClusterRequest);
    const clusterRequest: ModelCreateClusterRequest = {
      ...overallConfig,

      appVersions: {
        mlde: overallConfig.detVersion,
      },

      auxiliaryResources: {
        dbCpuReq: '1',
        dbMemReq: '2Gi',
        dbName: 'test-db',
        dbStorageSize: '10Gi',
        ...auxiliaryResources,
        detMasterServiceAnnotations: detMasterSvcAnnotations.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {},
        ),
        detMasterServiceLabels: detMasterSvcLabels.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {},
        ),
      },
      // TODO: temporary workaround
      ipRange: '10.0.0.0/20',
      masterConfig: masterConfig,
      name: clusterName,
      subnetRanges: {},
    };

    validateClusterRequestObject(clusterRequest);
    return makeClusterCreateCall(clusterRegion, clusterRequest, onFinish);
  }, [
    clusterName,
    clusterRegion,
    makeClusterCreateCall,
    onFinish,
    overallConfig,
    masterConfig,
    auxiliaryResources,
    detMasterSvcAnnotations,
    detMasterSvcLabels,
  ]);

  const modalContent = useMemo(() => {
    return (
      <div className={css.modalContent}>
        <Form
          form={form}
          initialValues={{
            detVersion: overallConfig.detVersion,
            masterInstanceType: overallConfig.masterInstanceType,
          }}
          labelCol={{ span: 24 }}>
          {/* TODO: need to address with the regional service split */}
          <Form.Item label="Region" name="region">
            <RegionPicker
              curRegion={clusterRegion}
              id="region"
              regionFilter={(region) => region.startsWith('byok')}
              onChange={setClusterRegion}
            />
          </Form.Item>
          <Form.Item
            label="Cluster Name"
            name="clusterName"
            rules={[{ message: 'Cluster name required', required: true }]}>
            <Input
              value={clusterName}
              onChange={(e) => {
                setClusterName(e.target.value);
              }}
            />
          </Form.Item>
          <Divider />
          <AdvancedConfigForm
            detMasterSvcAnnotations={detMasterSvcAnnotations}
            detMasterSvcLabels={detMasterSvcLabels}
            form={form}
            overallConfig={overallConfig}
            supportedDetVersions={supportedDetVersions}
            supportMatrix={availableNodes}
            updateMasterConfig={setMasterConfig}
            onUpdateAuxiliaryConfig={setAuxiliaryResources}
            onUpdateDetMasterSvcAnnotations={setDetMasterSvcAnnotations}
            onUpdateDetMasterSvcLabels={setDetMasterSvcLabels}
            onUpdateOverallConfig={setOverallConfig}
          />
        </Form>
      </div>
    );
  }, [
    clusterName,
    clusterRegion,
    form,
    availableNodes,
    supportedDetVersions,
    overallConfig,
    detMasterSvcAnnotations,
    detMasterSvcLabels,
    setOverallConfig,
    setMasterConfig,
  ]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmitDisabled(
          !clusterName ||
            !masterConfig ||
            masterConfig.resource_manager.default_aux_resource_pool === '' ||
            masterConfig.resource_manager.default_compute_resource_pool === '' ||
            masterConfig.resource_pools.length === 0,
        );
      })
      .catch(() => setSubmitDisabled(true));
  }, [clusterName, masterConfig, form, formValues]);

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="medium"
      submit={{
        disabled: submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Create Cluster',
      }}
      title="New Cluster"
      onClose={onClose}>
      <ResourceInfoProvider
        availableNodes={availableNodes}
        canceler={canceler}
        isModalOpen={isModalOpen}
        masterConfig={masterConfig}
        setSubmitDisabled={setSubmitDisabled}>
        {modalContent}
      </ResourceInfoProvider>
    </Modal>
  );
};

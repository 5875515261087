export const initialState = {
  auxiliaryResources: {},
  overallConfig: {
    detVersion: '',
    masterInstanceType: '',
  },
  poolConfigs: [],
  remainingResources: { cpuCount: Number.MAX_SAFE_INTEGER, gpuCount: Number.MAX_SAFE_INTEGER },
  resourceManager: {
    default_aux_resource_pool: '',
    default_compute_resource_pool: '',
  },
};

import React from 'react';

import css from './TrialStartInfo.module.scss';

const TrialStartInfo: React.FC = () => {
  return (
    <div className={css.base}>
      <div className={css.content}>
        <h2 className={css.header}>
          HPE Machine Learning Development Environment Software (MLDES) trial license
        </h2>
        <h4 className={css.subheader}>Overview:</h4>
        <p>
          Explore all the features of the HPE Machine Learning Development Environment Software
          (MLDES) by easily deploying and managing clusters in your cloud of choice.
        </p>
        <h4 className={css.subheader}>Key features:</h4>
        <ul>
          <li>Deploy to your AWS or GCP cloud environment.</li>
          <li>Continual performance and availability monitoring.</li>
          <li>
            Develop models at scale without the need for upfront investments in infrastructure or
            hardware.
          </li>
          <li>
            Eliminate operational hassle related to infrastructure setup, management, upgrades, and
            backups of the MLDE product.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TrialStartInfo;

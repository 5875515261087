import { Rule } from 'antd/es/form';

import { InstanceTypeReferences, InstanceTypeValues } from 'components/InstanceTypeDropdown.values';
import { ModelCreateClusterRequest } from 'services/regional-bindings';
import handleError, { ErrorLevel } from 'utils/error';

type seriesMapper = (instanceName: string) => string;

/**
 * Helper function that makes use of the reference table `InstanceTypeReferences`
 * to map a given instance type to a category used in the InstanceTypeDropdown list.
 * NOTE: This will only follow direct references, it will not follow reference chains.
 * @param instanceTypeName the name of the instance type as referred to by the support matrix
 * @returns the category name that this instance should be classified under
 */
export const getRootCategoryName = (instanceTypeName: string): string => {
  const prefix = instanceTypeName.split('.')[0];
  if (InstanceTypeReferences[prefix]) {
    if (InstanceTypeValues[InstanceTypeReferences[prefix].reference]) {
      return InstanceTypeReferences[prefix].reference;
    } else {
      return prefix;
    }
  } else {
    return prefix;
  }
};

export const regionSeriesMappers: {
  [regionName: string]: seriesMapper;
} = {
  aws: getRootCategoryName,
  gcp: (instanceName: string) => {
    const components = instanceName.split('-', 3);
    if (components[0] === 'a2' || components[0] === 'g2') return components[0];
    else if (components.length > 1) return components[0] + '-' + components[1];
    else return '';
  },
};

export const throwCreateClusterError = (message: string): void => {
  throw handleError(undefined, {
    level: ErrorLevel.Error,
    publicSubject: message,
    silent: false,
  });
};

export const validateClusterRequestObject = (
  clusterRequest: ModelCreateClusterRequest | undefined,
): void => {
  if (!clusterRequest) {
    throwCreateClusterError('Failed to create cluster.');
  } else {
    if (!clusterRequest.name) {
      throwCreateClusterError('Cluster name required');
    }
    if (!clusterRequest.detVersion && !clusterRequest.appVersions) {
      throwCreateClusterError('Version required');
    }
    if (!clusterRequest.ipRange || clusterRequest.ipRange === '') {
      throwCreateClusterError('IP range required');
    }
    if (!clusterRequest.subnetRanges) {
      throwCreateClusterError('Subnet ranges required');
    }
  }
};

export const cidrRules: Rule[] = [
  { message: 'Network CIDR required', required: true },
  {
    message: 'Invalid CIDR',
    pattern: new RegExp(
      '^((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)\\.){3}(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)\\/([0-8]|[[1-3][0-9])$',
    ),
  },
];

export const networkValid = (
  ipRange: string,
  two: string,
  three: string,
  four: string,
): boolean => {
  const regex = new RegExp(
    '^((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)\\.){3}(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)\\/([0-8]|[[1-3][0-9])$',
  );
  return (
    ipRange === undefined ||
    (regex.test(ipRange) && regex.test(two) && regex.test(three) && regex.test(four))
  );
};

import Form from 'hew/Form';
import Input from 'hew/Input';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFetchWithRetry } from 'hooks/useFetch';
import { editCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import handleError, { ErrorLevel, ErrorType } from 'utils/error';

interface Props {
  onClose?: () => void;
  cluster: GlobalApi.ModelClusterInfo;
  orgId: string;
}

export const _RenameClusterModal: React.FC<Props> = ({ orgId, cluster, onClose }) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);

  const [form] = Form.useForm();
  const clusterName = Form.useWatch('clusterName', form);
  const renameClusterRequest = useCallback(async () => {
    try {
      await fetchWithRetry(
        async () =>
          await editCluster(
            {
              cluster: { name: clusterName },
              clusterId: cluster.id,
              orgId,
              regionId: cluster.location,
            },
            { signal: canceler.signal },
          ),
      );
    } catch (error) {
      handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to update Cluster Name',
        silent: false,
        type: ErrorType.Server,
      });
    }
  }, [canceler.signal, cluster, clusterName, fetchWithRetry, orgId]);

  const handleSubmit = useCallback(async () => {
    await fetchWithRetry(async () => await renameClusterRequest());
    onClose?.();
  }, [fetchWithRetry, onClose, renameClusterRequest]);

  const submitDisabled = useMemo(
    () => !clusterName || cluster.name === clusterName,
    [cluster.name, clusterName],
  );

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="small"
      submit={{
        disabled: submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Rename Cluster',
      }}
      title={`Rename ${cluster.id}`}
      onClose={onClose}>
      <div>
        <Form form={form}>
          <Form.Item
            label="Cluster Name"
            name="clusterName"
            rules={[{ message: 'Cluster name required', required: true }]}>
            <Input placeholder={cluster.name} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

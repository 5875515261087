import { Modal } from 'hew/Modal';

import Link from 'components/Link';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { getOrganizationIdentifier } from 'utils/saas';

const salesEmail = 'hpc-ai-gtm-software-sales@hpe.com';

export const _TrialEndModal: React.FC = () => {
  const { orgState } = useStore();
  const user = useUser();
  const currentUserID = user.userId;
  const currentOrgOwnerID = orgState.selectedOrg?.ownerId;
  const userIsOwner = currentUserID === currentOrgOwnerID;

  return (
    <Modal size="medium" title={'Trial Ended'}>
      <div>
        <>
          Thank you for participating in a free trial of Machine Learning Development Environment
          Software (MLDES)! Your trial has now concluded, and any running clusters have been or will
          soon be paused.
          {userIsOwner ? (
            <>
              {' '}
              Please contact our sales team at{' '}
              <Link external={true} path={'mailto:{salesEmail}'}>
                {salesEmail}
              </Link>{' '}
              to purchase a license and resume your workloads.
            </>
          ) : (
            <>
              {' '}
              Please reach out to your {getOrganizationIdentifier()} administrator about purchasing
              a license to resume your workloads.
            </>
          )}
        </>
      </div>
    </Modal>
  );
};

import { ColumnsType } from 'antd/lib/table';
import React, { createElement } from 'react';

export const auxSeries = ['n1-standard', 'n1-highmem', 'n1-highcpu'];
export const computeSeries = ['n1-standard', 'n1-highmem', 'n1-highcpu', 'g2', 'a2'];
export const directorSeries = ['n1-standard'];

export const KEY_COLUMN_NAME = 'instance';
export const KEY_COLUMN_TITLE = 'Machine Instance';

export interface MachineTypeInfoBasic {
  [KEY_COLUMN_NAME]: string;
}

export interface MachineTypeInfoGeneral extends MachineTypeInfoBasic {
  localSsd: boolean | number;
  mem: number;
  network: number;
  vcpu: number;
}

export interface MachineTypeInfoAccelerator extends MachineTypeInfoGeneral {
  accelerators: number;
  gpuMem: number;
}

interface GeneralMachineTypeValuesSeries {
  columns: ColumnsType<MachineTypeInfoGeneral>;
  description: string;
  isLockedToAccelerator: false;
  supportedAccelerators: string[];
  values: {
    [machineType: string]: MachineTypeInfoGeneral;
  };
}

interface AcceleratorMachineTypeValuesSeries {
  acceleratorType: string;
  columns: ColumnsType<MachineTypeInfoAccelerator>;
  description: string;
  isLockedToAccelerator: true;
  values: {
    [machineType: string]: MachineTypeInfoAccelerator;
  };
}

export type MachineCombinedType =
  | MachineTypeInfoBasic
  | MachineTypeInfoGeneral
  | MachineTypeInfoAccelerator;

export type MachineSeries = GeneralMachineTypeValuesSeries | AcceleratorMachineTypeValuesSeries;

interface MachineTypeValuesBySeries {
  [seriesKey: string]: MachineSeries;
}

export const IndependentAcceleratorTypesToSupportedCount: {
  [acceleratorType: string]: number[];
} = {
  'nvidia-tesla-p100': [1, 2, 4],
  'nvidia-tesla-t4': [1, 2, 4],
  'nvidia-tesla-v100': [1, 2, 4, 8],
};

export const MachineTypeValues: MachineTypeValuesBySeries = {
  'a2': {
    acceleratorType: 'nvidia-tesla-a100',
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_TITLE,
      },
      {
        dataIndex: 'accelerators',
        title: 'GPU count',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPUs',
      },
      {
        dataIndex: 'mem',
        title: 'Memory (GB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
      {
        dataIndex: 'localSsd',
        render: (_: unknown, record: MachineTypeInfoAccelerator): React.ReactNode => {
          return createElement('span', null, record.localSsd ? 'Yes' : 'No');
        },
        title: 'Local SSD',
      },
    ],
    description: 'Accelerator optimized machine type that offers high performance A100 GPUs',
    isLockedToAccelerator: true,
    values: {
      'a2-highgpu-1g': {
        accelerators: 1,
        gpuMem: 40,
        instance: 'a2-highgpu-1g',
        localSsd: true,
        mem: 85,
        network: 24,
        vcpu: 12,
      },
      'a2-highgpu-2g': {
        accelerators: 2,
        gpuMem: 80,
        instance: 'a2-highgpu-2g',
        localSsd: true,
        mem: 170,
        network: 32,
        vcpu: 24,
      },
      'a2-highgpu-4g': {
        accelerators: 4,
        gpuMem: 160,
        instance: 'a2-highgpu-4g',
        localSsd: true,
        mem: 340,
        network: 50,
        vcpu: 48,
      },
      'a2-highgpu-8g': {
        accelerators: 8,
        gpuMem: 320,
        instance: 'a2-highgpu-8g',
        localSsd: true,
        mem: 680,
        network: 100,
        vcpu: 96,
      },
    },
  },

  'g2': {
    acceleratorType: 'nvidia-l4',
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_TITLE,
      },
      {
        dataIndex: 'accelerators',
        title: 'GPU count',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPUs',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GB)',
      },
      {
        dataIndex: 'mem',
        title: 'Memory (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
      {
        dataIndex: 'localSsd',
        title: 'Max Local SSD Support (GB)',
      },
    ],
    description: 'Accelerator optimized machine type offering NVIDIA L4 GPUs',
    isLockedToAccelerator: true,
    values: {
      'g2-standard-4': {
        accelerators: 1,
        gpuMem: 24,
        instance: 'g2-standard-4',
        localSsd: 375,
        mem: 16,
        network: 10,
        vcpu: 4,
      },
      'g2-standard-8': {
        accelerators: 1,
        gpuMem: 24,
        instance: 'g2-standard-8',
        localSsd: 375,
        mem: 32,
        network: 16,
        vcpu: 8,
      },
      'g2-standard-12': {
        accelerators: 1,
        gpuMem: 24,
        instance: 'g2-standard-12',
        localSsd: 375,
        mem: 48,
        network: 16,
        vcpu: 12,
      },
      'g2-standard-16': {
        accelerators: 1,
        gpuMem: 24,
        instance: 'g2-standard-16',
        localSsd: 375,
        mem: 64,
        network: 32,
        vcpu: 16,
      },
      'g2-standard-24': {
        accelerators: 2,
        gpuMem: 48,
        instance: 'g2-standard-24',
        localSsd: 750,
        mem: 96,
        network: 32,
        vcpu: 24,
      },
      'g2-standard-32': {
        accelerators: 1,
        gpuMem: 24,
        instance: 'g2-standard-32',
        localSsd: 375,
        mem: 128,
        network: 32,
        vcpu: 32,
      },
      'g2-standard-48': {
        accelerators: 4,
        gpuMem: 96,
        instance: 'g2-standard-48',
        localSsd: 1500,
        mem: 192,
        network: 50,
        vcpu: 48,
      },
      'g2-standard-96': {
        accelerators: 8,
        gpuMem: 192,
        instance: 'g2-standard-96',
        localSsd: 3000,
        mem: 384,
        network: 100,
        vcpu: 96,
      },
    },
  },
  'n1-highcpu': {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_TITLE,
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPUs',
      },
      {
        dataIndex: 'mem',
        title: 'Memory (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
      {
        dataIndex: 'localSsd',
        render: (_: unknown, record: MachineTypeInfoGeneral): React.ReactNode => {
          return createElement('span', null, record.localSsd ? 'Yes' : 'No');
        },
        title: 'Local SSD',
      },
    ],
    description: 'Same series as n1-standard but with higher vCPU count per unit memory',
    isLockedToAccelerator: false,
    supportedAccelerators: ['nvidia-tesla-p100', 'nvidia-tesla-t4', 'nvidia-tesla-v100'],
    values: {
      'n1-highcpu-2': {
        instance: 'n1-highcpu-2',
        localSsd: true,
        mem: 1.8,
        network: 10,
        vcpu: 2,
      },
      'n1-highcpu-4': {
        instance: 'n1-highcpu-4',
        localSsd: true,
        mem: 3.6,
        network: 10,
        vcpu: 4,
      },
      'n1-highcpu-8': {
        instance: 'n1-highcpu-8',
        localSsd: true,
        mem: 7.2,
        network: 16,
        vcpu: 8,
      },
      'n1-highcpu-16': {
        instance: 'n1-highcpu-16',
        localSsd: true,
        mem: 14.4,
        network: 32,
        vcpu: 16,
      },
      'n1-highcpu-32': {
        instance: 'n1-highcpu-32',
        localSsd: true,
        mem: 28.8,
        network: 32,
        vcpu: 32,
      },
      'n1-highcpu-64': {
        instance: 'n1-highcpu-64',
        localSsd: true,
        mem: 57.6,
        network: 32,
        vcpu: 64,
      },
      'n1-highcpu-96': {
        instance: 'n1-highcpu-96',
        localSsd: true,
        mem: 86.4,
        network: 32,
        vcpu: 96,
      },
    },
  },
  'n1-highmem': {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_TITLE,
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPUs',
      },
      {
        dataIndex: 'mem',
        title: 'Memory (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
      {
        dataIndex: 'localSsd',
        render: (_: unknown, record: MachineTypeInfoGeneral): React.ReactNode => {
          return createElement('span', null, record.localSsd ? 'Yes' : 'No');
        },
        title: 'Local SSD',
      },
    ],
    description: 'Same series as n1-standard but with higher memory per core',
    isLockedToAccelerator: false,
    supportedAccelerators: ['nvidia-tesla-p100', 'nvidia-tesla-t4', 'nvidia-tesla-v100'],
    values: {
      'n1-highmem-1': {
        instance: 'n1-highmem-1',
        localSsd: true,
        mem: 6.5,
        network: 2,
        vcpu: 1,
      },
      'n1-highmem-2': {
        instance: 'n1-highmem-2',
        localSsd: true,
        mem: 13.0,
        network: 10,
        vcpu: 2,
      },
      'n1-highmem-4': {
        instance: 'n1-highmem-4',
        localSsd: true,
        mem: 26.0,
        network: 10,
        vcpu: 4,
      },
      'n1-highmem-8': {
        instance: 'n1-highmem-8',
        localSsd: true,
        mem: 52.0,
        network: 16,
        vcpu: 8,
      },
      'n1-highmem-16': {
        instance: 'n1-highmem-16',
        localSsd: true,
        mem: 104.0,
        network: 32,
        vcpu: 16,
      },
      'n1-highmem-32': {
        instance: 'n1-highmem-32',
        localSsd: true,
        mem: 208.0,
        network: 32,
        vcpu: 32,
      },
      'n1-highmem-64': {
        instance: 'n1-highmem-64',
        localSsd: true,
        mem: 416.0,
        network: 32,
        vcpu: 64,
      },
      'n1-highmem-96': {
        instance: 'n1-highmem-96',
        localSsd: true,
        mem: 624.0,
        network: 32,
        vcpu: 96,
      },
    },
  },
  'n1-standard': {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_TITLE,
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPUs',
      },
      {
        dataIndex: 'mem',
        title: 'Memory (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
      {
        dataIndex: 'localSsd',
        render: (_: unknown, record: MachineTypeInfoGeneral): React.ReactNode => {
          return createElement('span', null, record.localSsd ? 'Yes' : 'No');
        },
        title: 'Local SSD',
      },
    ],
    description: 'First generation general-purpose machine series',
    isLockedToAccelerator: false,
    supportedAccelerators: ['nvidia-tesla-p100', 'nvidia-tesla-t4', 'nvidia-tesla-v100'],
    values: {
      'n1-standard-1': {
        instance: 'n1-standard-1',
        localSsd: true,
        mem: 3.75,
        network: 2,
        vcpu: 1,
      },
      'n1-standard-2': {
        instance: 'n1-standard-2',
        localSsd: true,
        mem: 7.5,
        network: 10,
        vcpu: 2,
      },
      'n1-standard-4': {
        instance: 'n1-standard-4',
        localSsd: true,
        mem: 15.0,
        network: 10,
        vcpu: 4,
      },
      'n1-standard-8': {
        instance: 'n1-standard-8',
        localSsd: true,
        mem: 30.0,
        network: 16,
        vcpu: 8,
      },
      'n1-standard-16': {
        instance: 'n1-standard-16',
        localSsd: true,
        mem: 60.0,
        network: 32,
        vcpu: 16,
      },
      'n1-standard-32': {
        instance: 'n1-standard-32',
        localSsd: true,
        mem: 120.0,
        network: 32,
        vcpu: 32,
      },
      'n1-standard-64': {
        instance: 'n1-standard-64',
        localSsd: true,
        mem: 240.0,
        network: 32,
        vcpu: 64,
      },
      'n1-standard-96': {
        instance: 'n1-standard-96',
        localSsd: true,
        mem: 360.0,
        network: 32,
        vcpu: 96,
      },
    },
  },
};

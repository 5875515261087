import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { Modal } from 'hew/Modal';
import Tooltip from 'hew/Tooltip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ResponsiveTable from 'components/ResponsiveTable';
import Spinner from 'components/Spinner';
import TableFilterSearch from 'components/TableFilterSearch';
import { useAuth } from 'contexts/Auth';
import { useUser } from 'contexts/User';
import { useFetchWithRetry } from 'hooks/useFetch';
import RoleSelect from 'pages/Members/Roles/RoleSelect';
import { ClusterRole, OrgRole } from 'saasTypes';
import { getClusterUsers, updateClusterLevelRole } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import handleError from 'utils/error';
import { isClusterAdmin } from 'utils/saas';
import { alphaNumericSorter } from 'utils/sort';

interface Props {
  clusterId: string;
  orgId: string;
}

export const _ClusterUsersModal: React.FC<Props> = ({ orgId, clusterId }) => {
  const { roles } = useUser();
  const userCanModify = !!orgId && isClusterAdmin(roles, orgId, clusterId);
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [hasError, setHasError] = useState(false);
  const { checkAuth } = useAuth();
  const [nameFilter, setNameFilter] = useState('');
  const [users, setUsers] = useState<GlobalApi.ModelClusterUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<GlobalApi.ModelClusterUser[]>([]);

  const fetchData = useCallback(async () => {
    try {
      const users = await fetchWithRetry(
        async () => await getClusterUsers({ clusterId, orgId }, { signal: canceler.signal }),
      );
      setUsers(users);
      // updates roles in store
      checkAuth(canceler);
    } catch (e) {
      setHasError(true);
      handleError(e, {
        publicSubject: 'Failed to fetch users',
      });
    }
  }, [canceler, checkAuth, fetchWithRetry, clusterId, orgId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  const handleSearchNameApply = useCallback(
    (newSearch: string) => {
      setNameFilter(newSearch);
      newSearch = newSearch.toLowerCase();
      const filtered = users.filter((user) => user.name.toLowerCase().includes(newSearch));
      setFilteredUsers(filtered);
    },
    [users],
  );

  /**
   * Update the user's access level to a cluster
   */
  const updateUserClusterRole = useCallback(
    async (userId: string, newAccess: ClusterRole) => {
      try {
        await fetchWithRetry(
          async () => await updateClusterLevelRole({ clusterId, orgId, role: newAccess, userId }),
        );
        fetchData();
        await checkAuth(canceler);
      } catch (e) {
        handleError(e, {
          publicSubject: 'Failed to update user access level',
        });
      }
    },
    [canceler, clusterId, fetchData, checkAuth, fetchWithRetry, orgId],
  );

  useEffect(() => {
    handleSearchNameApply(nameFilter);
  }, [handleSearchNameApply, nameFilter]);

  const tableFilterSearchName = useCallback(
    (filterProps: FilterDropdownProps) => {
      const { close } = filterProps;
      return (
        <TableFilterSearch
          {...filterProps}
          value={nameFilter}
          onReset={() => {
            setNameFilter('');
            close();
          }}
          onSearch={(val) => setNameFilter(val)}
        />
      );
    },
    [nameFilter],
  );

  const columns = useMemo(() => {
    const cols: ColumnType<GlobalApi.ModelClusterUser>[] = [
      {
        dataIndex: 'name',
        defaultSortOrder: 'ascend',
        filterDropdown: tableFilterSearchName,
        filterIcon: <SearchOutlined />,
        key: 'name',
        sorter: (a, b) => alphaNumericSorter(a.name, b.name),
        title: 'Name',
      },
      {
        dataIndex: 'email',
        defaultSortOrder: 'ascend',
        key: 'email',
        sorter: (a, b) => alphaNumericSorter(a.email, b.email),
        title: 'Email',
      },
      {
        dataIndex: 'role',
        key: 'role',
        render: (_, record) => {
          if (!userCanModify) return record.role;
          return (
            <RoleSelect
              availableRoles={[ClusterRole.Admin, ClusterRole.User, ClusterRole.None]}
              defaultValue={record.role as ClusterRole}
              disabled={record.orgRole === OrgRole.Admin}
              onChange={(level: ClusterRole) => updateUserClusterRole(record.id, level)}
            />
          );
        },
        sorter: (a, b) => alphaNumericSorter(a.role, b.role),
        title: (
          <div>
            Cluster Role&nbsp;&nbsp;&nbsp;
            <Tooltip
              content={
                "Each member's role is prepopulated by their Default Cluster Role. You can override these roles on a cluster by cluster basis here."
              }>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        ),
      },
    ];
    return cols;
  }, [updateUserClusterRole, userCanModify, tableFilterSearchName]);

  return (
    <Modal
      size="medium"
      submit={{
        handleError,
        handler: () => Promise.resolve(),
        text: 'Ok',
      }}
      title={`${userCanModify ? 'Manage' : 'View'} Cluster Members`}>
      {hasError ? (
        <div>Unable to fetch user access info. Please try again in a minute.</div>
      ) : users === null ? (
        <Spinner tip="Loading initial data..." />
      ) : (
        <div>
          <ResponsiveTable<GlobalApi.ModelClusterUser>
            columns={columns}
            dataSource={nameFilter ? filteredUsers : users}
            pagination={{ hideOnSinglePage: true }}
            rowKey="id"
            showSorterTooltip={false}
          />
        </div>
      )}
    </Modal>
  );
};

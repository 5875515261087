import { fetchRegionalClusters } from 'services/api';
import { ModelClusterInfo } from 'services/regional-bindings';
import { RegionID } from 'services/types';
import { FetchOptions } from 'types';

/* Clusters */

interface Params {
  orgId: string;
  regions: string[];
}

export type RegionalClusters = Record<RegionID, ModelClusterInfo[]>;

/**
 * Stitch a list of regional cluster together.
 * refer to README.md for more details.
 */
export const fetchSelectRegionalClusters = async (
  { regions, orgId }: Params,
  options?: FetchOptions,
): Promise<RegionalClusters> => {
  const promises = regions.map(async (regionId) => {
    const clusters = await fetchRegionalClusters(
      {
        orgId,
        regionId,
      },
      options,
    );
    return clusters;
  });

  const responses = await Promise.all(promises);

  // record of regionId to clusters
  return responses.reduce((acc, clusters, i) => {
    if (clusters) acc[regions[i]] = clusters;
    return acc;
  }, {} as RegionalClusters);
};

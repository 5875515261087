import { BrandingType } from 'types';
import { parseUrl, routeToExternalUrl } from 'utils/routes';

import { isGLCPDeployment } from './saas';
/*
 * In mobile view the definition of viewport height varies between
 * whether it should include the chrome's vertical space or not.
 * This function dynamically calculates the available height on the
 * browser view minus the chrome height.
 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 * The HPE header / MSP header also need to be adjusted for so that they don't
 * push our app down and require scroll to see all our buttons.
 * This function also subtracts the space needed from the header.
 * isMsp is passed in to help with testing.
 */
export const correctViewportHeight = (isMsp: boolean): void => {
  let headerHeight;
  if (isMsp) {
    // default height all custom msp logos should be set to
    headerHeight = 50;
  } else {
    const header = document.getElementById(isGLCPDeployment() ? 'greenlakeHeader' : 'header');
    if (!header) {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      return;
    } else {
      headerHeight = header.offsetHeight;
    }
  }

  // This check is needed because the header height on initial load occasionally returns a big number
  // When this happens on the initial loading check, the spinner isn't displayed
  if (headerHeight < window.innerHeight) {
    let height = headerHeight ? window.innerHeight - headerHeight : window.innerHeight;

    // setting a min height
    const pageHeader = document.querySelectorAll('div[class^="NavigationHeader"]');
    const navHeader = document.querySelectorAll('div[class*="NavigationSideBar_user"]');
    const navItem = document.querySelectorAll('a[class*="NavigationSideBar_navItem"]');
    const navFooter = document.querySelectorAll('div[class^="NavigationSideBar_version"]');
    if (navItem.length && navFooter.length && navHeader.length && pageHeader.length) {
      const pageHeaderHeight = parseInt(
        window.getComputedStyle(pageHeader[0], null)['height'] as string,
      );
      const navItemHeight = parseInt(window.getComputedStyle(navItem[0], null)['height'] as string);
      const navFooterHeight = parseInt(
        window.getComputedStyle(navFooter[0], null)['height'] as string,
      );
      const navHeaderHeight = parseInt(
        window.getComputedStyle(navHeader[0], null)['height'] as string,
      );

      // min height = our mldes header + nav bar height
      const min_height =
        navItemHeight * navItem.length + navFooterHeight + navHeaderHeight + pageHeaderHeight;
      height = height < min_height ? min_height : height;
    }

    const vh = height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
};

const downloadBlob = (filename: string, data: Blob): void => {
  const url = window.URL.createObjectURL(data);
  const element = document.createElement('a');
  element.setAttribute('download', filename);
  element.style.display = 'none';
  element.href = url;
  document.body.appendChild(element);
  element.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(element);
};

// Different JS engines impose different maximum string lenghts thus for downloading
// large text we split it into different parts and then stich them together as a Blob
export const downloadText = (filename: string, parts: BlobPart[]): void => {
  const data = new Blob(parts, { type: 'text/plain' });
  downloadBlob(filename, data);
};

export const getCookie = (name: string): string | null => {
  const regex = new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`);
  const value = document.cookie.replace(regex, '$1');
  return value ? value : null;
};

/*
 * The method of cache busting here is to send a query string as most
 * modern browsers treat different URLs as different files, causing a
 * request of a fresh copy. The previous method of using `location.reload`
 * with a `forceReload` boolean has been deprecated and not reliable.
 */
export const refreshPage = (): void => {
  const now = Date.now();
  const url = parseUrl(window.location.href);
  url.searchParams.set('ts', now.toString());
  routeToExternalUrl(url.toString());
};

const updateFavicon = (iconPath: string): void => {
  const linkEl: HTMLLinkElement | null = document.querySelector("link[rel*='shortcut icon']");
  if (!linkEl) return;
  linkEl.type = 'image/png';
  linkEl.href = iconPath;
};

export const updateFaviconType = (active: boolean, branding = BrandingType.Determined): void => {
  const suffixDev = process.env.IS_DEV ? '-dev' : '';
  const suffixActive = active ? '-active' : '';
  updateFavicon(`${process.env.PUBLIC_URL}/${branding}/favicon${suffixDev}${suffixActive}.png`);
};

import Spinner from 'hew/Spinner';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useFetchLicenseStatus } from 'hooks/useFetch';
import usePolling from 'hooks/usePolling';
import MLDEError from 'pages/Error/Error';
import { Roles } from 'saasTypes';
import * as GlobalApi from 'services/global-bindings';
import { User } from 'types';

import { useAuth } from './Auth';

interface Props {
  children?: React.ReactNode;
}

export interface UserHook extends User {
  refetchLicenseStatus: () => Promise<void>;
}

const UserContext = React.createContext<UserHook | undefined>(undefined);

const UserProvider: React.FC<Props> = ({ children }: Props) => {
  const [licensing, setLicensing] = useState<
    GlobalApi.ModelUserLicensingStatusResponse | undefined
  >(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<UserHook | undefined>(undefined);
  const { jwtInfo } = useAuth();
  const fetchLicenseStatus = useFetchLicenseStatus();

  const refetchLicenseStatus = useCallback(async () => {
    try {
      const license = await fetchLicenseStatus(jwtInfo.userId);
      setLicensing(license);
    } catch (error) {
      setError('Failed to retrieve user info');
    }
  }, [fetchLicenseStatus, jwtInfo.userId]);

  useEffect(() => {
    if (licensing && jwtInfo) {
      const roles: Roles = {};
      for (const orgId in jwtInfo.orgRoles) {
        roles[orgId] = jwtInfo.orgRoles[orgId];
      }
      const user: UserHook = {
        email: jwtInfo.email,
        licensing: licensing,
        name: jwtInfo.name,
        refetchLicenseStatus: refetchLicenseStatus,
        roles: roles,
        superAdmin: jwtInfo.superAdmin,
        userId: jwtInfo.userId,
      };
      setUser(user);
    }
  }, [jwtInfo, licensing, refetchLicenseStatus]);

  // TODO: This will cause all components in the authenticated router tree to re-render every minute
  // We could check for diffs in the object before setting the licensing state to prevent this in the future
  usePolling(refetchLicenseStatus, { interval: 60000 });

  if (error) {
    return <MLDEError message={error} />;
  } else if (user) {
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  } else {
    return (
      <div style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
        <Spinner center spinning>
          <div />
        </Spinner>
      </div>
    );
  }
};

export const useUser = (): UserHook => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export default UserProvider;

import React, { useEffect } from 'react';

import { telemetryInstance } from 'analytics';
import Spinner from 'components/Spinner';
import { StoreAction, useStoreDispatch } from 'contexts/Store';
import { globalStorage } from 'globalStorage';
import { externalLogoutAddress } from 'routes/utils';
import { routeToExternalUrl } from 'utils/routes';

const SignOut: React.FC = () => {
  const storeDispatch = useStoreDispatch();
  useEffect(() => {
    storeDispatch({ type: StoreAction.SetDisplayTrialBanner, value: true });
    globalStorage.removeSelectedOrg();
    telemetryInstance.reset();
    routeToExternalUrl(externalLogoutAddress());
  }, [storeDispatch]);

  return (
    <Spinner
      center
      style={{ height: 'calc(var(--vh, 1vh) * 100)', width: '100%' }}
      tip={'Logging out of Machine Learning Development Environment Software...'}>
      <div />
    </Spinner>
  );
};

export default SignOut;

import Button from 'hew/Button';
import React from 'react';

import Link from 'components/Link';
import PageMessage from 'components/PageMessage';
import { paths } from 'routes/utils';
import { refreshPage } from 'utils/browser';
import { getPropFromMspConfig, isMspDeployment } from 'utils/saas';
interface Props {
  actionText?: string;
  message?: string;
  onAction?: () => void;
}

const Error: React.FC<Props> = ({ actionText, message, onAction }: Props) => {
  const supportEmail = isMspDeployment()
    ? getPropFromMspConfig('supportEmail')
    : 'ai-support@hpe.com';
  return (
    <div style={{ height: 'calc(var(--vh, 1vh) * 100)', width: '100%' }}>
      <PageMessage title="Error">
        <p>{message ?? 'An unexpected error occurred'}</p>
        <p>
          Please check our <Link path={paths.docs()}>documentation</Link>
          for more information or contact customer support for further assistance.
          <br />
          Support email:{' '}
          <Link external={true} path={'mailto:' + supportEmail}>
            {supportEmail}
          </Link>
        </p>
        <Button onClick={onAction ?? refreshPage}>{actionText ?? 'Try Again'}</Button>
      </PageMessage>
    </div>
  );
};

export default Error;

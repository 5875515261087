import { Menu } from 'antd';
import React from 'react';

import { mspLink } from 'saasTypes';
import { getPropFromMspConfig } from 'utils/saas';

import css from './MspHeader.module.scss';

const MspHeader: React.FC = () => {
  const mspLogo: string = getPropFromMspConfig('imgUrl');
  const links: mspLink[] = getPropFromMspConfig<mspLink[]>('links');
  const Links = links.map((link: mspLink) => {
    return {
      key: 'nav' + link.title,
      label: (
        <a href={link.url} rel="noopener noreferrer" target="_blank">
          {link.title}
        </a>
      ),
    };
  });

  return (
    <div className={css.base}>
      <img alt="logo" className={css.logo} src={mspLogo} />
      {Links.length ? (
        <Menu className={css.links} items={Links} mode="horizontal" selectedKeys={[]} />
      ) : null}
    </div>
  );
};

export default MspHeader;

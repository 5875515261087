import { DownOutlined } from '@ant-design/icons';
import Dropdown from 'hew/Dropdown';
import Icon, { Props as IconProps } from 'hew/Icon';
import Tooltip from 'hew/Tooltip';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import AvatarCard from 'components/AvatarCard';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import useSettings, { BaseType, SettingsConfig } from 'hooks/useSettings';
import { paths } from 'routes/utils';
import {
  getOrganizationIdentifier,
  isGLCPDeployment,
  isMspDeployment,
  isOrgAdmin,
} from 'utils/saas';
import { getDisplayName } from 'utils/user';

import Link, { Props as LinkProps } from './Link';
import css from './NavigationSideBar.module.scss';
import ThemeToggle, { newThemeMode } from './ThemeToggle';
import useUI from './UIManager';

interface ItemProps extends LinkProps {
  badge?: number;
  icon: string;
  label: string;
  status?: string;
  tooltip?: boolean;
}

interface Settings {
  navbarCollapsed: boolean;
}

const settingsConfig: SettingsConfig = {
  settings: [
    {
      defaultValue: false,
      key: 'navbarCollapsed',
      skipUrlEncoding: true,
      storageKey: 'navbarCollapsed',
      type: { baseType: BaseType.Boolean },
    },
  ],
  storagePath: 'navigation',
};

const topMenuItems = [{ icon: 'cluster', label: 'Clusters', path: paths.clusters() }];
const bottomMenuItems = [
  { external: true, icon: 'docs', label: 'Docs', path: paths.docs(), popout: true },
];

if (!isGLCPDeployment()) {
  topMenuItems.push({ icon: 'user', label: 'Members', path: paths.members() });
}

if (!isMspDeployment()) {
  bottomMenuItems.push({
    external: true,
    icon: 'info',
    label: 'Privacy',
    path: paths.privacy(),
    popout: true,
  });
  bottomMenuItems.push({
    external: true,
    icon: 'pencil',
    label: 'Feedback',
    path: paths.feedback(),
    popout: true,
  });
}
// the number of icons on the menu is (unfortunately) hard coded into
// correctViewportHeight() from browser.ts
// if you update the number of icons here, you should update that function as well
const menuConfig = { bottom: bottomMenuItems, top: topMenuItems };

const NavigationItem: React.FC<ItemProps> = ({ path, status, ...props }: ItemProps) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const classes = [css.navItem];

  if (isActive) classes.push(css.active);
  if (status) classes.push(css.hasStatus);

  useEffect(() => {
    if (!path) return;
    setIsActive(location.pathname.startsWith(path));
  }, [location.pathname, path]);

  const link = (
    <Link
      className={classes.join(' ')}
      disabled={isActive}
      external={props.external}
      path={path}
      {...props}>
      <Icon name={props.icon as IconProps['name']} size="large" title={props.label} />
      <div className={css.label}>{props.label}</div>
      {status && <div className={css.status}>{status}</div>}
    </Link>
  );

  return props.tooltip ? (
    <Tooltip content={props.label} placement="right">
      <div>{link}</div>
    </Tooltip>
  ) : (
    link
  );
};

const NavigationSideBar: React.FC = () => {
  // `nodeRef` padding is required for CSSTransition to work with React.StrictMode.
  const nodeRef = useRef(null);
  const { settings, updateSettings } = useSettings<Settings>(settingsConfig);
  const user = useUser();
  const {
    orgState: { selectedOrg },
  } = useStore();
  const { ui, actions: uiActions } = useUI();

  const version = process.env.BUILD_HASH || '';
  const shortVersion = version.replace(/-.*$/i, ''); // remove the -dirty suffix
  const isVersionLong = version !== shortVersion;

  const isAdmin = useMemo(() => {
    return user.roles && selectedOrg && isOrgAdmin(user.roles, selectedOrg?.id);
  }, [selectedOrg, user.roles]);

  const isSuperAdmin = useMemo(() => {
    return user.superAdmin;
  }, [user.superAdmin]);

  const handleCollapse = useCallback(() => {
    updateSettings({ navbarCollapsed: !settings.navbarCollapsed });
  }, [settings.navbarCollapsed, updateSettings]);

  // Look through our sidebar list and check if Organization row is visible
  let organizationIndex = -1;
  const organizationPresent = menuConfig.top.some((element, index) => {
    if (element.path === paths.organization()) {
      organizationIndex = index;
      return true;
    }
  });
  if (isAdmin) {
    // User is an admin, if the Organization option is not already displayed, add it
    if (!organizationPresent) {
      menuConfig.top.push({
        icon: 'settings',
        label: getOrganizationIdentifier(),
        path: paths.organization(),
      });
    }
  } else {
    // User is not an admin, if they are coming from an organization where they were an admin, we should remove the organization option
    if (organizationPresent) {
      menuConfig.top.splice(organizationIndex, 1);
    }
  }

  let customerOrgsIndex = -1;
  const customerOrgsPresent = menuConfig.top.some((element, index) => {
    if (element.path === paths.customerOrgs()) {
      customerOrgsIndex = index;
      return true;
    }
  });
  if (isSuperAdmin && selectedOrg?.isSuperAdminOrg) {
    if (!customerOrgsPresent) {
      menuConfig.top.push({ icon: 'group', label: 'Customer Orgs', path: paths.customerOrgs() });
    }
  } else {
    if (customerOrgsPresent) {
      menuConfig.top.splice(customerOrgsIndex, 1);
    }
  }

  return (
    <CSSTransition
      appear={true}
      classNames={{
        appear: css.collapsedAppear,
        appearActive: settings.navbarCollapsed ? css.collapsedEnterActive : css.collapsedExitActive,
        appearDone: settings.navbarCollapsed ? css.collapsedEnterDone : css.collapsedExitDone,
        enter: css.collapsedEnter,
        enterActive: css.collapsedEnterActive,
        enterDone: css.collapsedEnterDone,
        exit: css.collapsedExit,
        exitActive: css.collapsedExitActive,
        exitDone: css.collapsedExitDone,
      }}
      in={settings.navbarCollapsed}
      nodeRef={nodeRef}
      timeout={200}>
      <nav className={css.base} ref={nodeRef}>
        <header>
          <Dropdown
            menu={[
              {
                key: 'theme-toggle',
                label: <ThemeToggle />,
                onClick: () => {
                  newThemeMode(ui.mode, uiActions);
                },
              },
              {
                key: 'sign-out',
                label: <Link path={paths.logout()}>Sign Out</Link>,
              },
            ]}
            placement={'bottomLeft'}>
            <div className={css.user}>
              <AvatarCard className={css.avatarCard} noColor text={getDisplayName(user)} />
              <DownOutlined style={{ fontSize: '75%' }} />
            </div>
          </Dropdown>
        </header>
        <main>
          <section className={css.top}>
            {menuConfig.top.map((config) => (
              <NavigationItem
                key={config.label + config.path}
                tooltip={settings.navbarCollapsed}
                {...config}
              />
            ))}
          </section>
          <section className={css.bottom}>
            {menuConfig.bottom.map((config) => (
              <NavigationItem
                key={config.label + config.path}
                tooltip={settings.navbarCollapsed}
                {...config}
              />
            ))}
            <NavigationItem
              icon={settings.navbarCollapsed ? 'expand' : 'collapse'}
              label={settings.navbarCollapsed ? 'Expand' : 'Collapse'}
              tooltip={settings.navbarCollapsed}
              onClick={handleCollapse}
            />
          </section>
        </main>
        <footer>
          <div className={css.version}>
            {isVersionLong && settings.navbarCollapsed ? (
              <Tooltip content={`Version ${version}`} placement="right">
                <span className={css.versionLabel}>{shortVersion}</span>
              </Tooltip>
            ) : (
              <span className={css.versionLabel}>{version}</span>
            )}
          </div>
        </footer>
      </nav>
    </CSSTransition>
  );
};

export default NavigationSideBar;

import { generateUUID } from 'utils/string';

export const clusterDefaults = {
  AUX_INSTANCE_TYPE: 'n1-standard-4',
  COMPUTE_INSTANCE_TYPE: {
    acceleratorCount: 1,
    acceleratorType: 'nvidia-l4',
    type: 'g2-standard-4',
  },
  CPU_POOL_NAME: 'cpu_pool',
  CPU_SLOTS_ALLOWED: false,
  DEFAULT_AUX_KEY: generateUUID(),
  DEFAULT_COMPUTE_KEY: generateUUID(),
  GKE: 'gke',
  GPU_POOL_NAME: 'gpu_pool',
  MASTER_INSTANCE_TYPE: 'n1-standard-8',
  MAX_AUX_CONTAINERS_PER_AGENT: 100,
  MAX_AUX_INSTANCES: 1,
  MAX_COMPUTE_INSTANCES: 2,
  RP_PROVIDER_TYPE: 'gke',
};

import React from 'react';
import { Helmet } from 'react-helmet-async';

import BasePage, { Props as BasePageProps } from 'components/BasePage';
import PageHeader from 'components/PageHeader';
import { BrandingType } from 'types';
import { getPropFromMspConfig, isMspDeployment } from 'utils/saas';

export interface Props extends Omit<BasePageProps, 'pageHeader'> {
  docTitle?: string;
}

const getFullDocTitle = (title?: string) => {
  const brand = `${
    isMspDeployment() ? '' : 'HPE'
  } Machine Learning Development Environment Software`;
  const segmentList = [brand];

  if (title) segmentList.unshift(title);

  return segmentList.join(' - ');
};

const Page: React.FC<Props> = (props: Props) => {
  const docTitle = getFullDocTitle(props.docTitle || props.title);

  let favIcon: string, appleTouchIcon: string, siteManifest: string;
  if (isMspDeployment()) {
    favIcon = getPropFromMspConfig('favicon');
    appleTouchIcon = getPropFromMspConfig('appleTouchIcon');
    siteManifest = getPropFromMspConfig('siteManifest');
  } else {
    const brandingPath = `${process.env.PUBLIC_URL}/${BrandingType.HPE}`;
    favIcon = `${brandingPath}/favicon.ico`;
    appleTouchIcon = `${brandingPath}/apple-touch-icon.png`;
    siteManifest = `${brandingPath}/manifest.json`;
  }

  return (
    <>
      <Helmet>
        <title>{docTitle}</title>
        <>
          <link href={favIcon} rel="shortcut icon" type="image/x-icon" />
          <link href={appleTouchIcon} rel="apple-touch-icon" />
          <link href={siteManifest} rel="manifest" />
        </>
      </Helmet>
      <BasePage
        {...props}
        pageHeader={
          <PageHeader
            options={props.options}
            sticky={props.stickyHeader}
            subTitle={props.subTitle}
            title={props.title}
          />
        }
      />
    </>
  );
};

export default Page;

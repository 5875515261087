import { Card } from 'antd';
import Button from 'hew/Button';
import React, { useState } from 'react';

import css from './ActionCard.module.scss';

export enum OnboardingState {
  Init = 1,
  Welcome = 2,
  PendingInvites = 3,
  TrialCheck = 4,
  TrialSignUp = 5,
  OrgCreation = 6,
}

interface Props {
  title?: string;
  children?: React.ReactNode;
  nextButtonText?: string;
  backButtonText?: string;
  nextDisabled?: boolean;
  backDisabled?: boolean;
  onNext?: () => Promise<void>;
  onBack?: () => void;
}

const ActionCard: React.FC<Props> = ({
  children,
  title,
  nextButtonText,
  backButtonText,
  nextDisabled,
  backDisabled,
  onNext,
  onBack,
}) => {
  const [backLoading, setBackLoading] = useState<boolean>(false);
  const [nextLoading, setNextLoading] = useState<boolean>(false);

  return (
    <Card className={css.card}>
      <div className={css.text}>
        {title && (
          <>
            <h3 className={css.header}>{title}</h3>
            <br />
          </>
        )}
        {children}
      </div>
      <div className={css.actions}>
        {backButtonText && (
          <Button
            disabled={backDisabled}
            loading={backLoading}
            onClick={async () => {
              if (onBack) {
                setBackLoading(true);
                try {
                  await onBack();
                } catch (e) {
                  console.error(e);
                }
                setBackLoading(false);
              }
            }}>
            {backButtonText ? backButtonText : 'Back'}
          </Button>
        )}
        <Button
          disabled={nextDisabled}
          loading={nextLoading}
          type="primary"
          onClick={async () => {
            if (onNext) {
              setNextLoading(true);
              try {
                await onNext();
              } catch (e) {
                console.error(e);
              }
              setNextLoading(false);
            }
          }}>
          {nextButtonText ? nextButtonText : 'Next'}
        </Button>
      </div>
    </Card>
  );
};

export default ActionCard;

import { Mode } from 'hew/internal/Theme/theme';
import React from 'react';

import useUI, { UIActions } from 'components/UIManager';

import css from './ThemeToggle.module.scss';

interface ThemeOption {
  className: Mode;
  displayName: string;
  next: Mode;
}

export const ThemeOptions: Record<Mode, ThemeOption> = {
  [Mode.Light]: {
    className: Mode.Light,
    displayName: 'Light Mode',
    next: Mode.Dark,
  },
  [Mode.Dark]: {
    className: Mode.Dark,
    displayName: 'Dark Mode',
    next: Mode.System,
  },
  [Mode.System]: {
    className: Mode.System,
    displayName: 'System Mode',
    next: Mode.Light,
  },
};

export const newThemeMode = (mode: Mode, uiActions: UIActions): void => {
  uiActions.setMode(ThemeOptions[mode].next);
};

const ThemeToggle: React.FC = () => {
  const { ui, actions: uiActions } = useUI();

  const classes = [css.toggler];
  const currentThemeOption = ThemeOptions[ui.mode];
  classes.push(css[currentThemeOption.className]);

  return (
    <div
      className={css.base}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        newThemeMode(ui.mode, uiActions);
      }}>
      <div className={css.container}>
        <div className={classes.join(' ')} />
        <div className={css.mode}>{currentThemeOption.displayName}</div>
      </div>
    </div>
  );
};

export default ThemeToggle;

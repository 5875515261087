import { useState } from 'react';

import { useUser } from 'contexts/User';
import { Storage, Store } from 'utils/storage';

export const userPreferencesStorage = new Storage({
  basePath: 'u',
  delimiter: ':',
  store: window.localStorage,
});

export const useStorage = (basePath: string, store: Store = window.localStorage): Storage => {
  const user = useUser();
  const userNamespace = `u:${user.userId}`;
  const [storage] = useState(new Storage({ basePath: `${userNamespace}/${basePath}`, store }));
  return storage;
};

export default useStorage;

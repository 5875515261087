import { Spin } from 'antd';
import { SpinProps } from 'antd/es/spin';
import Icon, { IconSize } from 'hew/Icon';
import React from 'react';

import css from './Spinner.module.scss';

interface Props extends Omit<SpinProps, 'size'> {
  center?: boolean;
  children?: React.ReactNode;

  conditionalRender?: boolean;
  inline?: boolean;
  size?: IconSize;
}

const Spinner: React.FC<Props> = ({
  center,
  className,
  conditionalRender,
  indicator,
  size,
  spinning,
  tip,
  ...props
}: Props) => {
  const classes = [css.base];

  if (className) classes.push(className);
  if (center || tip) classes.push(css.center);

  return (
    <div className={classes.join(' ')}>
      <Spin
        indicator={
          indicator ?? (
            <div className={css.spin}>
              <Icon name="spinner" size={size} title="Loading..." />
            </div>
          )
        }
        spinning={spinning}
        tip={tip}
        {...props}>
        {conditionalRender ? (spinning ? null : props.children) : props.children}
      </Spin>
    </div>
  );
};

export default Spinner;

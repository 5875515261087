import { pathToRegexp } from 'path-to-regexp';

import { globalStorage } from 'globalStorage';
import { RouteConfig } from 'types';
import {
  AnyMouseEvent,
  AnyMouseEventHandler,
  isAbsolutePath,
  isFullPath,
  isNewTabClickEvent,
  openBlank,
  reactHostAddress,
  routeToExternalUrl,
  routeToReactUrl,
} from 'utils/routes';
import { getPropFromMspConfig, isMspDeployment } from 'utils/saas';

import routes from './routes';

/**
 *
 * @returns {string} the top level domain where the UI can find management and
 * regional services.
 */
const managementTLD = (): string => {
  // Prioritize dynamically set address.
  return globalStorage.serverAddress || process.env.SERVER_ADDRESS || window.location.hostname;
};

/**
 * @param id - region and cloud provider id.
 * @returns {string} the port for the regional services
 */
const regionalServerPort = (id: string): string => {
  const defaultPort = '4931';
  if (!process.env.REGIONAL_PORT_MAP) return defaultPort;

  const map = JSON.parse(process.env.REGIONAL_PORT_MAP);
  if (id in map) {
    return map[id];
  }

  return defaultPort;
};

/**
 * @returns {string} the port for the global service
 */
const globalServerPort = (): string => {
  return process.env.GLOBAL_PORT ?? '4930';
};

export const externalLoginAddress = (): string => {
  return globalServerAddress('/login');
};

export const externalLogoutAddress = (): string => {
  return globalServerAddress('/logout');
};

// points to global management plane's address.
export const globalServerAddress = (path = ''): string => {
  if (!!path && isFullPath(path)) return path;
  return `https://global.${managementTLD()}:${globalServerPort()}` + path;
};

/**
 * @param id - region and cloud provider id.
 * @param path - path to be appended to the address.
 * @returns regional management plane's address.
 */
export const regionalServerAddress = (id: string, path = ''): string => {
  if (!!path && isFullPath(path)) return path;
  return `https://${id}.${managementTLD()}:${regionalServerPort(id)}` + path;
};

export const handlePath = (
  event: AnyMouseEvent,
  options: {
    external?: boolean;
    onClick?: AnyMouseEventHandler;
    path?: string;
    popout?: boolean | 'tab' | 'window';
  } = {},
): void => {
  event.preventDefault();

  const href = options.path ? linkPath(options.path, options.external) : undefined;

  if (options.onClick) {
    options.onClick(event);
  } else if (href) {
    if (isNewTabClickEvent(event) || options.popout) {
      /**
       * `location=0` forces a new window instead of a tab to open.
       * https://stackoverflow.com/questions/726761/javascript-open-in-a-new-window-not-tab
       */
      const windowFeatures = options.popout === 'window' ? 'location=0' : undefined;
      openBlank(href, undefined, windowFeatures);
    } else {
      routeAll(href);
    }
  }
};
export const findReactRoute = (url: string): RouteConfig | undefined => {
  if (isFullPath(url)) {
    if (!url.startsWith(reactHostAddress())) return undefined;
    // Fit it into a relative path
    url = url.replace(reactHostAddress(), '');
  }
  if (!url.startsWith(process.env.PUBLIC_URL)) {
    return undefined;
  }
  // Check to see if the path matches any of the defined app routes.
  const pathname = url.replace(process.env.PUBLIC_URL, '');
  return routes
    .filter((route) => route.path !== '*')
    .find((route) => {
      const routeRegex = pathToRegexp(route.path);
      return routeRegex.test(pathname);
    });
};

/*
  routeAll determines whether a path should be routed through internal React router or hanled
  by the browser.
  input `path` should include the PUBLIC_URL if there is one set. eg if react is being served
  in a subdirectory.
*/
export const routeAll = (path: string): void => {
  const matchingReactRoute = findReactRoute(path);
  if (!matchingReactRoute) {
    routeToExternalUrl(path);
  } else {
    routeToReactUrl(path);
  }
};

export const linkPath = (aPath: string, external = false): string => {
  if (isFullPath(aPath)) return aPath;
  let path;
  if (external) {
    if (isAbsolutePath(aPath)) {
      path = globalServerAddress() + aPath;
    } else {
      path = aPath;
    }
  } else {
    path = process.env.PUBLIC_URL + aPath;
  }
  return path;
};

export const routesById: Record<string, RouteConfig> = routes.reduce((acc, cur) => {
  acc[cur.id] = cur;
  return acc;
}, {} as Record<string, RouteConfig>);

export const paths = {
  clusters: (): string => {
    return '/clusters';
  },
  customerOrgs: (): string => {
    return '/customer-orgs';
  },
  docs: (suffix?: string): string => {
    return `/docs/${suffix || 'index.html'}`;
  },
  error: (): string => {
    return '/error';
  },
  feedback: (): string => {
    return 'https://airtable.com/shrUKuwu8XmsQy20z';
  },
  loggedOut: (): string => {
    return '/logged-out';
  },
  login: (): string => {
    return '/login';
  },
  logout: (): string => {
    return '/logout';
  },
  logs: (clusterId: string): string => {
    return `/clusters/${clusterId}/logs`;
  },
  members: (): string => {
    return '/members';
  },
  organization: (): string => {
    return '/organization';
  },
  privacy: (): string => {
    return isMspDeployment() ? getPropFromMspConfig('privacyUrl') : 'https://www.hpe.com/privacy';
  },
  trial: (): string => {
    return '/trial';
  },
};

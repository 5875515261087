import Icon from 'hew/Icon';
import React, { MouseEvent, useCallback } from 'react';

import { handlePath, linkPath } from 'routes/utils';
import { AnyMouseEventHandler, windowOpenFeatures } from 'utils/routes';

import css from './Link.module.scss';
import useUI from './UIManager/UI';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  // does clicking this start a download?
  download?: boolean;
  // is this external to the assets hosted by React?
  external?: boolean;
  inherit?: boolean;
  label?: string;
  onClick?: AnyMouseEventHandler;
  path?: string;
  popout?: boolean;
  rawLink?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'large';
}

const Link: React.FC<Props> = ({ download, external, popout, onClick, ...props }: Props) => {
  const { ui } = useUI();
  const classes = [css.base];
  const rel = windowOpenFeatures.join(' ');

  if (props.className) classes.push(props.className);
  if (props.disabled) classes.push(css.disabled);
  if (props.inherit) classes.push(css.inherit);
  if (props.size) classes.push(css[props.size]);
  if (ui.darkLight === 'light') classes.push(css.lightMode);

  const href = props.path ? linkPath(props.path, external) : undefined;
  const handleClick = useCallback(
    (event: MouseEvent) => {
      handlePath(event, { external, onClick, path: props.path, popout });
    },
    [onClick, popout, props.path, external],
  );

  if (props.disabled) {
    <span className={classes.join(' ')}>{props.children}</span>;
  }

  return (
    <a
      aria-label={props.label}
      className={classes.join(' ')}
      href={href}
      rel={rel}
      onClick={!props.rawLink ? handleClick : undefined}>
      {props.children} {external && <Icon name="popout" title={props.label ?? 'View Link'} />}
      {download && <Icon name="download" title={props.label ?? 'Download'} />}
    </a>
  );
};

export default Link;

import ClipboardButton from 'hew/ClipboardButton';
import Form from 'hew/Form';
import Input from 'hew/Input';

import Link from './Link';
import css from './OrgGuid.module.scss';

interface Props {
  guid: string;
  label?: string;
  formItemName?: string;
}

const OrgGuid: React.FC<Props> = ({ guid, formItemName, label }) => {
  return (
    <Form.Item
      extra={
        <div>
          {label || 'External ID'} is used to restrict your cloud resources to only be used by this
          organization. For more information, see the&nbsp;
          <Link path="/docs/cloud-provider.html" popout={true}>
            Cloud Provider Documentation.
          </Link>
        </div>
      }
      label={label || 'External ID'}
      name={formItemName}>
      <div className={css.guidInputContainer}>
        <Input defaultValue={guid} disabled={true} value={guid} />
        <ClipboardButton getContent={() => guid} />
      </div>
    </Form.Item>
  );
};

export default OrgGuid;

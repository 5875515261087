import { useCallback, useEffect, useState } from 'react';

import InvitedUsers from 'components/InvitedUsers';
import { useFetchWithRetry } from 'hooks/useFetch';
import { cancelOrgInvite } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import handleError from 'utils/error';

import css from './Members.module.scss';

interface InvitesTabContentProps {
  fetchInvitedUsers: () => Promise<void>;
  invitedUsers: GlobalApi.ModelInvitedUser[];
  selectedOrgId: string;
}

const InvitesTabContent: React.FC<InvitesTabContentProps> = ({
  fetchInvitedUsers,
  invitedUsers,
  selectedOrgId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);

  useEffect(() => {
    try {
      fetchInvitedUsers();
      setIsLoading(false);
    } catch (err) {
      handleError(err, {
        level: 'error',
        publicSubject: 'Failed to fetch users',
        silent: false,
      });
    }
  }, [fetchInvitedUsers]);

  const onCancelOrgInvite = useCallback(
    async (inviteId: string) => {
      try {
        setIsLoading(true);
        await fetchWithRetry(
          async () =>
            await cancelOrgInvite({ inviteId, orgId: selectedOrgId }, { signal: canceler.signal }),
        );
        fetchInvitedUsers();
        setIsLoading(false);
      } catch (err) {
        handleError(err, {
          level: 'error',
          publicSubject: 'Failed to cancel invite',
          silent: false,
        });
      }
    },
    [canceler.signal, fetchInvitedUsers, fetchWithRetry, selectedOrgId],
  );

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return (
    <InvitedUsers
      className={css.pageTab}
      fetchInvites={fetchInvitedUsers}
      invitedUsers={invitedUsers}
      isLoading={isLoading}
      onCancelInvite={onCancelOrgInvite}
    />
  );
};

export default InvitesTabContent;

import { Typography } from 'antd';
import Form from 'hew/Form';
import Input from 'hew/Input';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFetchWithRetry } from 'hooks/useFetch';
import { deleteCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import handleError from 'utils/error';

const { Text } = Typography;

interface Props {
  onClose?: () => void;
  cluster: GlobalApi.ModelClusterInfo;
  loadClusters: () => void;
  orgId: string;
}

export const _ClusterDeleteModal: React.FC<Props> = ({ cluster, loadClusters, orgId, onClose }) => {
  const [canceler] = useState(() => new AbortController());
  const [deleteForm] = Form.useForm();
  const clusterNameInput = Form.useWatch('name', deleteForm);
  const fetchWithRetry = useFetchWithRetry(canceler);
  const handleSubmit = useCallback(() => {
    return fetchWithRetry(
      async () =>
        await deleteCluster(
          {
            clusterId: cluster.id,
            orgId: orgId,
            regionId: cluster.location,
          },
          { signal: canceler.signal },
        ),
    ).then(
      loadClusters,
      (e) =>
        handleError(e, {
          publicSubject: 'Failed to delete cluster',
        }) as void,
    );
  }, [canceler.signal, cluster.id, cluster.location, fetchWithRetry, loadClusters, orgId]);

  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  const submitDisabled = useMemo(
    () => !clusterNameInput || clusterNameInput !== cluster.name,
    [cluster.name, clusterNameInput],
  );

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      danger={true}
      size="medium"
      submit={{ disabled: submitDisabled, handleError, handler: handleSubmit, text: 'Delete' }}
      title={'Deleting a cluster is a permanent act and cannot be undone.'}
      onClose={onClose}>
      <Form form={deleteForm}>
        <Form.Item
          label={
            <div>
              Type <Text code>{cluster.name}</Text> to confirm
            </div>
          }
          name="name"
          rules={[
            { message: 'Cluster name required', required: true },
            {
              validator: (_, value) => {
                if (value === cluster.name || value.length === 0) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    'Please enter the full cluster name, exactly as it appears',
                  );
                }
              },
            },
          ]}>
          <Input autoComplete="off" min={1} type="text" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

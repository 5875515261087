import { Alert } from 'antd';

import { InstanceTypeInfoCpu, InstanceTypeValues } from 'components/InstanceTypeDropdown.values';
import {
  basicProClusterRequest,
  basicStandardClusterRequest,
} from 'pages/Clusters/NewAwsMldeClusterModal/NewAwsMldeClusterModal.settings';
import {
  BasicConfigOption,
  ClusterCreationResourcesConfig,
  OverallConfig,
  PoolConfig,
  WarningMessageMapping,
} from 'saasTypes';
import { ModelPrecheckInfo } from 'services/regional-bindings';
import { getOrganizationIdentifier } from 'utils/saas';

/*
 Quota-related utils
 */

export const createWarningMessage = (resourcesAtLimit: string[]): string => {
  const ResourcesAsStrings: WarningMessageMapping = {
    ec2VpcElasticIps: 'EC2-VPC Elastic IPs',
    gAndVt: 'G and Vt instance types',
    natGateways: 'Nat Gateways',
    p: 'P instance types',
    standard: 'Standard instance types (A, C, D, H, I, M, R, T, Z)',
    vpcs: 'VCPs',
  };
  const resourceLimitString = resourcesAtLimit
    .map((resource: string) => ResourcesAsStrings[resource])
    .join(', ');
  return `Cluster creation is likely to fail. You have exceeded your service quota for the following resource(s): ${resourceLimitString}. Speak to an administrator in your ${getOrganizationIdentifier()} about requesting an AWS service quota increase.`;
};

export const quotaAlert = (resourcesAtLimit: string[]): React.ReactNode => {
  const resourceLimitString = createWarningMessage(resourcesAtLimit);

  return (
    <div id="quota-alert">
      <Alert description={resourceLimitString} message="Quota Limit Reached" type="warning" />
      <br />
    </div>
  );
};

export const getResourcesWithLimitReached = (
  isAdvanced: boolean,
  overallConfig: OverallConfig,
  poolConfigs: PoolConfig[],
  basicConfigSelection: BasicConfigOption.Standard | BasicConfigOption.Pro,
  requiredAccountResources: ClusterCreationResourcesConfig,
  availableAccountResources: ClusterCreationResourcesConfig,
): string[] => {
  // Collect all instance types from user selection
  const allInstances: string[] = [];
  if (isAdvanced) {
    allInstances.push(overallConfig.masterInstanceType);
    poolConfigs.map((pool: PoolConfig) => allInstances.push(pool.instanceType));
  } else if (basicConfigSelection === BasicConfigOption.Standard) {
    basicStandardClusterRequest.masterConfig.resource_pools.map((pool) =>
      allInstances.push(pool.provider.instance_type.machine_type),
    );
    allInstances.push(basicStandardClusterRequest.masterInstanceType);
  } else if (basicConfigSelection === BasicConfigOption.Pro) {
    basicProClusterRequest.masterConfig.resource_pools.map((pool) =>
      allInstances.push(pool.provider.instance_type.machine_type),
    );
    allInstances.push(basicProClusterRequest.masterInstanceType);
  }
  // Determine the number of vcpus of each instance type requires
  const requestedResources: ClusterCreationResourcesConfig = {
    ...requiredAccountResources,
  };

  allInstances.forEach((instanceTypeAndSize) => {
    let prefix: string = instanceTypeAndSize.split('.')[0];

    // If prefix type is p4dn or p3dn, makes sure to look them up under "p4" and "p3" properties on InstanceTypeValues
    if (prefix[0] === 'p') {
      prefix = prefix.slice(0, 2);
    }

    if ('vcpu' in InstanceTypeValues[prefix].values[instanceTypeAndSize]) {
      // Can be cast either as InstanceTypeInfoCpu or InstanceTypeInfoGpu since either carries the vcpu property we need access to
      const instance = InstanceTypeValues[prefix].values[
        instanceTypeAndSize
      ] as InstanceTypeInfoCpu;

      if (prefix[0] === 'm' || prefix[0] === 'c') {
        requestedResources.standard += instance.vcpu;
      } else if (prefix[0] === 'g') {
        requestedResources['gAndVt'] += instance.vcpu;
      } else if (prefix[0] === 'p') {
        requestedResources['p'] += instance.vcpu;
      } else {
        // Note: In the future, if for some reason the user is able to select an instance type with that does not require
        // vcpus, then we can remove the else condition.
        throw new Error('Instance is not associated with any vcpus.');
      }
    }
  });

  // Check if there are enough resources to meet the users request
  const resourcesWithLimitReached: string[] = [];
  for (const resource in requestedResources) {
    const amountRequested = requestedResources[resource];
    const amountAvailable = availableAccountResources[resource];
    if (amountRequested > amountAvailable) {
      resourcesWithLimitReached.push(resource);
    }
  }
  return resourcesWithLimitReached;
};

export const getAvailableResources = (
  quotas: ModelPrecheckInfo,
  utilization: ModelPrecheckInfo,
): ClusterCreationResourcesConfig => {
  return {
    ec2VpcElasticIps: quotas.ec2VpcElasticIps - utilization.ec2VpcElasticIps,
    gAndVt: quotas.gAndVt - utilization.gAndVt,
    natGateways: quotas.natGateways - utilization.natGateways,
    p: quotas.p - utilization.p,
    standard: quotas.standard - utilization.standard,
    vpcs: quotas.vpcs - utilization.vpcs,
  };
};

import { DarkLight } from 'hew/internal/types';
import React from 'react';

import Logo, { LogoType } from 'components/Logo';
import Page from 'components/Page';
import useUI from 'components/UIManager';
import { BrandingType } from 'types';

import css from './PageMessage.module.scss';

interface Props {
  children: React.ReactNode;
  title: string;
}

const PageMessage: React.FC<Props> = ({ title, children }: Props) => {
  const { ui } = useUI();
  return (
    <Page docTitle={title}>
      <div className={css.base}>
        <div className={css.content}>
          <Logo
            branding={BrandingType.HPE}
            type={
              ui.darkLight === DarkLight.Light ? LogoType.OnLightVertical : LogoType.OnDarkVertical
            }
          />
          {children}
        </div>
      </div>
    </Page>
  );
};

export default PageMessage;

import React, { ReactElement } from 'react';

import Link from 'components/Link';
import { ClusterRole } from 'saasTypes';

import css from './RoleDelete.module.scss';

interface Props {
  id: string;
  onChange: (id: string, level: ClusterRole) => void;
}

const RoleDelete = ({ id, onChange }: Props): ReactElement => {
  return (
    <span className={css.action}>
      <Link onClick={() => onChange(id, ClusterRole.None)}>X</Link>
    </span>
  );
};

export default RoleDelete;

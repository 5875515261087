import dayjs from 'dayjs';
import Spinner from 'hew/Spinner';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { globalStorage } from 'globalStorage';
import usePolling from 'hooks/usePolling';
import MLDEError from 'pages/Error/Error';
import { paths } from 'routes/utils';
import { refreshToken, userInfo } from 'services/api';
import { ModelJWT } from 'services/global-bindings';
import { refreshPage } from 'utils/browser';
import handleError, { ErrorType } from 'utils/error';
import rootLogger from 'utils/Logger';
import { routeToReactUrl } from 'utils/routes';
import { isAborted, isAuthFailure } from 'utils/service';

interface AuthFields {
  currentAttempt: number;
  failed: boolean;
  isAuthenticated: boolean;
  jwtInfo: ModelJWT;
}

interface AuthFunctions {
  doRefreshToken: (canceler: AbortController) => Promise<void>;
  checkAuth: (canceler: AbortController) => Promise<void>;
}

type Auth = AuthFields & AuthFunctions;

interface Props {
  children?: React.ReactNode;
}

const initAuth: AuthFields = {
  currentAttempt: globalStorage.currentAuthAttempt,
  failed: false,
  isAuthenticated: false,
  jwtInfo: {} as ModelJWT,
};

const logger = rootLogger.extend('hooks/useAuthCheck');
const maxAuthRetries = 5;
const refreshThresholdMinutes = 10;

const AuthContext = React.createContext<Auth | undefined>(undefined);

const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const [canceler] = useState(() => new AbortController());
  const [authFields, setAuthFields] = useState<AuthFields>(initAuth);

  const resetAuthAttempt = useCallback(() => {
    globalStorage.removeCurrentAuthAttempt();
    globalStorage.removeAuthFailed();
  }, []);

  const updateUserInfo = useCallback(
    async (canceler: AbortController) => {
      const response = await userInfo(undefined, { signal: canceler.signal });
      resetAuthAttempt();
      setAuthFields((prev) => {
        return { ...prev, isAuthenticated: true, jwtInfo: response };
      });
    },
    [resetAuthAttempt],
  );

  const doRefreshToken = useCallback(
    async (canceler: AbortController): Promise<void> => {
      try {
        await refreshToken(undefined, { signal: canceler.signal });
        await updateUserInfo(canceler);
        logger.trace('verified auth token validity');
      } catch (e) {
        logger.trace('refreshToken call failed');
        if (isAborted(e)) return;
        logger.trace('failed to refresh auth token');

        if (isAuthFailure(e, true)) {
          setAuthFields((prev) => {
            return { ...prev, isAuthenticated: false };
          });
          routeToReactUrl(paths.login());
        } else {
          throw handleError(e, {
            isUserTriggered: false,
            publicMessage: 'Unable to verify current user.',
            publicSubject: 'GET user failed',
            silent: true,
            type: ErrorType.Server,
          });
        }
      }
    },
    [updateUserInfo],
  );

  const checkAuth = useCallback(
    async (canceler: AbortController): Promise<void> => {
      // retrieve auth cookies user info
      try {
        await updateUserInfo(canceler);
      } catch (e) {
        logger.trace('userInfo call failed');
        if (isAborted(e)) return;
        if (authFields.currentAttempt > maxAuthRetries) {
          setAuthFields((prev) => {
            return { ...prev, failed: true };
          });
        } else {
          routeToReactUrl(paths.login());
        }
        return;
      }
    },
    [authFields.currentAttempt, updateUserInfo],
  );

  const checkTokenExpiration = useCallback(async (): Promise<void> => {
    // Refresh token if it expires within 10 minutes
    if (authFields.jwtInfo?.exp) {
      const currentTime = dayjs();
      const expiry = dayjs.unix(authFields.jwtInfo.exp);
      const diff = expiry.diff(currentTime, 'minutes');
      if (diff < refreshThresholdMinutes) {
        try {
          await doRefreshToken(canceler);
        } catch (e) {
          logger.trace('failed to refresh and/or save new token');
          if (isAborted(e)) return;
        }
      }
    }
  }, [authFields.jwtInfo.exp, canceler, doRefreshToken]);

  usePolling(checkTokenExpiration, {
    continueWhenHidden: true,
    interval: 60000 * 10, // check token expiration every 10 minutes
    runImmediately: false,
  });

  useEffect(() => {
    checkAuth(canceler);
  }, [canceler, checkAuth]);

  useEffect(() => {
    if (authFields.jwtInfo.exp && authFields.jwtInfo.iat) {
      const expiry = dayjs.unix(authFields.jwtInfo.exp);
      const issuedAt = dayjs.unix(authFields.jwtInfo.iat);
      // Make sure the diff between expiration and issued at is greater than
      // the refresh threshold so that we don't get stuck in a loop
      if (expiry.diff(issuedAt, 'minutes') > refreshThresholdMinutes) {
        checkTokenExpiration();
      }
    }
  }, [authFields.jwtInfo, checkTokenExpiration]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  if (authFields.failed) {
    return (
      <MLDEError
        message={'Your credentials could not be verified.'}
        onAction={() => {
          resetAuthAttempt();
          refreshPage();
        }}
      />
    );
  } else if (authFields.isAuthenticated) {
    const auth: Auth = {
      ...authFields,
      checkAuth: checkAuth,
      doRefreshToken: doRefreshToken,
    };
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
  } else {
    return (
      <div style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
        <Spinner center spinning>
          <div />
        </Spinner>
      </div>
    );
  }
};

export const useAuth = (): Auth => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export default AuthProvider;

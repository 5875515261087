import { Alert } from 'antd';
import Select, { Option } from 'hew/Select';
import React, { useCallback, useEffect, useState } from 'react';

import { useStore } from 'contexts/Store';
import { useFetchSupportMatrix } from 'hooks/useFetch';

import css from './RegionPicker.module.scss';

interface Props {
  curRegion?: string;
  id?: string;
  onChange: (change: string) => void;
  regionFilter?: (regionCode: string) => boolean;
}

const RegionPicker: React.FC<Props> = ({
  curRegion,
  id,
  onChange,
  regionFilter = () => true,
}: Props) => {
  const [regions, setRegions] = useState<string[]>();
  const { supportMatrix } = useStore();
  const [canceler] = useState(() => new AbortController());
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);

  const selectDefaultRegions = useCallback(() => {
    if (regions) onChange(regions[0]);
  }, [regions, onChange]);

  useEffect(() => {
    if (!supportMatrix) {
      fetchSupportMatrix();
    } else {
      setRegions(supportMatrix.supportedLocations.filter(regionFilter));
    }
  }, [fetchSupportMatrix, supportMatrix, regionFilter]);

  useEffect(() => {
    if (!curRegion) {
      selectDefaultRegions();
    }
  }, [curRegion, selectDefaultRegions]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  return (
    <>
      {regions && regions.length > 1 && (
        <div className={css.alert}>
          <Alert
            message="Cluster region cannot be changed after cluster creation."
            type="warning"
          />
        </div>
      )}
      <Select
        id={id}
        value={curRegion}
        onChange={(value) => {
          value && onChange(value as string);
        }}>
        {regions?.map((region) => (
          <Option key={region}>{region}</Option>
        ))}
      </Select>
    </>
  );
};

export default RegionPicker;

import { awsMldeClusterDefaults } from 'constants/defaults';
import { BasicConfigOption, PoolConfig, ResourcePoolUse } from 'saasTypes';
import { ModelCreateClusterRequest } from 'services/regional-bindings';
import { generateUUID } from 'utils/string';

export const basicStandardClusterRequest: Required<
  Omit<
    ModelCreateClusterRequest,
    'detVersion' | 'ipRange' | 'subnetRanges' | 'appVersions' | 'auxiliaryResources'
  >
> = {
  masterConfig: {
    resource_manager: {
      default_aux_resource_pool: awsMldeClusterDefaults.CPU_POOL_NAME,
      default_compute_resource_pool: awsMldeClusterDefaults.GPU_POOL_NAME,
    },
    resource_pools: [
      {
        max_aux_containers_per_agent: 100,
        pool_name: awsMldeClusterDefaults.CPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: awsMldeClusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: {
            machine_type: awsMldeClusterDefaults.AUX_INSTANCE_TYPE,
          },
          max_instances: awsMldeClusterDefaults.MAX_AUX_INSTANCES,
          type: awsMldeClusterDefaults.AWS,
        },
      },
      {
        max_aux_containers_per_agent: 0,
        pool_name: awsMldeClusterDefaults.GPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: awsMldeClusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: {
            machine_type: awsMldeClusterDefaults.COMPUTE_INSTANCE_TYPE,
          },
          max_instances: awsMldeClusterDefaults.MAX_COMPUTE_INSTANCES,
          type: awsMldeClusterDefaults.AWS,
        },
      },
    ],
  },
  masterInstanceType: awsMldeClusterDefaults.MASTER_INSTANCE_TYPE_STANDARD,
  name: '',
};

export const basicProClusterRequest: Required<
  Omit<
    ModelCreateClusterRequest,
    'detVersion' | 'ipRange' | 'subnetRanges' | 'appVersions' | 'auxiliaryResources'
  >
> = {
  masterConfig: {
    resource_manager: {
      default_aux_resource_pool: awsMldeClusterDefaults.CPU_POOL_NAME,
      default_compute_resource_pool: awsMldeClusterDefaults.GPU_POOL_NAME,
    },
    resource_pools: [
      {
        max_aux_containers_per_agent: 100,
        pool_name: awsMldeClusterDefaults.CPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: awsMldeClusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: {
            machine_type: awsMldeClusterDefaults.AUX_INSTANCE_TYPE,
          },
          max_instances: awsMldeClusterDefaults.MAX_AUX_INSTANCES,
          type: awsMldeClusterDefaults.AWS,
        },
      },
      {
        max_aux_containers_per_agent: 0,
        pool_name: awsMldeClusterDefaults.GPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: awsMldeClusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: {
            machine_type: awsMldeClusterDefaults.COMPUTE_INSTANCE_TYPE_PRO,
          },
          max_instances: awsMldeClusterDefaults.MAX_COMPUTE_INSTANCES,
          type: awsMldeClusterDefaults.AWS,
        },
      },
    ],
  },
  masterInstanceType: awsMldeClusterDefaults.MASTER_INSTANCE_TYPE_PRO,
  name: '',
};

export const defaultPoolConfigs: PoolConfig[] = [
  {
    cpuSlotsAllowed: true,
    instanceType: awsMldeClusterDefaults.AUX_INSTANCE_TYPE,
    key: generateUUID(),
    maxIdleAgentPeriod: awsMldeClusterDefaults.MAX_IDLE_AGENT_PERIOD,
    maxInstances: awsMldeClusterDefaults.MAX_AUX_INSTANCES,
    minInstances: awsMldeClusterDefaults.MIN_AUX_INSTANCES,
    poolName: awsMldeClusterDefaults.CPU_POOL_NAME,
    primaryUse: ResourcePoolUse.Aux,
  },
  {
    cpuSlotsAllowed: false,
    instanceType: awsMldeClusterDefaults.COMPUTE_INSTANCE_TYPE,
    key: generateUUID(),
    maxIdleAgentPeriod: awsMldeClusterDefaults.MAX_IDLE_AGENT_PERIOD,
    maxInstances: awsMldeClusterDefaults.MAX_COMPUTE_INSTANCES,
    minInstances: awsMldeClusterDefaults.MIN_COMPUTE_INSTANCES,
    poolName: awsMldeClusterDefaults.GPU_POOL_NAME,
    primaryUse: ResourcePoolUse.Compute,
  },
];

export const initialState = {
  basicConfigSelection: BasicConfigOption.Standard,
  defaultAccountResources: {
    // Set to infinity so if the precheck fails, no warning message will be displayed
    ec2VpcElasticIps: Number.POSITIVE_INFINITY,
    gAndVt: Number.POSITIVE_INFINITY,
    natGateways: Number.POSITIVE_INFINITY,
    p: Number.POSITIVE_INFINITY,
    standard: Number.POSITIVE_INFINITY,
    vpcs: Number.POSITIVE_INFINITY,
  },
  isAdvanced: false,
  overallConfig: {
    detVersion: '',
    masterInstanceType: awsMldeClusterDefaults.MASTER_INSTANCE_TYPE_STANDARD,
  },
  poolConfigs: defaultPoolConfigs,
  requiredAccountResources: {
    // # of instances are calculated based on user selection, below are static amounts per cluster creation
    ec2VpcElasticIps: awsMldeClusterDefaults.REQUIRED_EC2VPCELASTICIPS,
    gAndVt: 0,
    natGateways: awsMldeClusterDefaults.REQUIRED_NATGATEWAYS,
    p: 0,
    standard: 0,
    vpcs: awsMldeClusterDefaults.REQUIRED_VPCS,
  },
  resourceManager: {
    default_aux_resource_pool: awsMldeClusterDefaults.CPU_POOL_NAME,
    default_compute_resource_pool: awsMldeClusterDefaults.GPU_POOL_NAME,
  },
};

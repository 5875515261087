import { Storage } from 'utils/storage';

class GlobalStorage {
  private keys: Record<string, string>;
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
    this.keys = {
      authFailed: 'auth-failed',
      currentAuthAttempt: 'current-auth-attempt',
      displayTrialBanner: 'display-trial-banner',
      selectedOrgId: 'selected-org-id',
      serverAddress: 'server-address',
      userIdentified: 'user-identified',
    };
  }

  get authFailed() {
    return this.storage.get<boolean>(this.keys.authFailed) || false;
  }

  get currentAuthAttempt() {
    return this.storage.get<number>(this.keys.currentAuthAttempt) || 1;
  }

  get displayTrialBanner() {
    return this.storage.get<boolean>(this.keys.displayTrialBanner) ?? true;
  }

  get serverAddress() {
    return this.storage.get<string>(this.keys.serverAddress) || '';
  }

  get selectedOrgId() {
    return this.storage.get<string>(this.keys.selectedOrgId) || '';
  }

  get userIdentified() {
    return this.storage.get<boolean>(this.keys.userIdentified) || false;
  }

  set authFailed(failed: boolean) {
    this.storage.set(this.keys.authFailed, failed);
  }

  set currentAuthAttempt(attempt: number) {
    this.storage.set(this.keys.currentAuthAttempt, attempt);
  }

  set displayTrialBanner(display: boolean) {
    this.storage.set(this.keys.displayTrialBanner, display);
  }

  set serverAddress(address: string) {
    this.storage.set(this.keys.serverAddress, address);
  }

  set selectedOrgId(address: string) {
    this.storage.set(this.keys.selectedOrgId, address);
  }

  set userIdentified(identified: boolean) {
    this.storage.set(this.keys.userIdentified, identified);
  }

  removeAuthFailed() {
    this.storage.remove(this.keys.authFailed);
  }

  removeCurrentAuthAttempt() {
    this.storage.remove(this.keys.currentAuthAttempt);
  }

  removeDisplayTrialBanner() {
    this.storage.remove(this.keys.displayTrialBanner);
  }

  removeServerAddress() {
    this.storage.remove(this.keys.serverAddress);
  }

  removeSelectedOrg() {
    this.storage.remove(this.keys.selectedOrgId);
  }

  removeUserIdenfitied() {
    this.storage.remove(this.keys.userIdentified);
  }
}

export const globalStorage = new GlobalStorage(
  new Storage({ basePath: 'global', store: window.localStorage }),
);

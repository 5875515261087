import Icon, { Props as IconProps } from 'hew/Icon';
import { AnyMouseEventHandler } from 'hew/internal/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AvatarCard from 'components/AvatarCard';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { handlePath, paths } from 'routes/utils';
import { AnyMouseEvent } from 'utils/routes';
import { getOrganizationIdentifier, isGLCPDeployment, isOrgAdmin } from 'utils/saas';
import { getDisplayName } from 'utils/user';

import ActionSheet, { ActionItem } from './ActionSheet';
import Link, { Props as LinkProps } from './Link';
import css from './NavigationTabbar.module.scss';

interface ToolbarItemProps extends LinkProps {
  badge?: number;
  icon: string;
  label: string;
  status?: string;
}

interface MenuItem {
  icon: string;
  label: string;
  path?: string;
  showTooltip?: boolean;
  onClick?: AnyMouseEventHandler;
}

const ToolbarItem: React.FC<ToolbarItemProps> = ({ path, status, ...props }: ToolbarItemProps) => {
  const location = useLocation();
  const classes = [css.toolbarItem];
  const [isActive, setIsActive] = useState(false);

  if (isActive) classes.push(css.active);

  useEffect(() => setIsActive(location.pathname === path), [location.pathname, path]);

  return (
    <Link className={classes.join(' ')} path={path} {...props}>
      <Icon name={props.icon as IconProps['name']} size="large" title={props.label} />
      {status && <div className={css.status}>{status}</div>}
    </Link>
  );
};

const NavigationTabbar: React.FC = () => {
  const user = useUser();
  const {
    orgState: { selectedOrg },
  } = useStore();
  const [isShowingOverflow, setIsShowingOverflow] = useState(false);

  const handleOverflowOpen = useCallback(() => setIsShowingOverflow(true), []);
  const handleActionSheetCancel = useCallback(() => setIsShowingOverflow(false), []);

  const handlePathUpdate = useCallback((e: AnyMouseEvent, path: string) => {
    handlePath(e, { path });
    setIsShowingOverflow(false);
  }, []);

  const menuItems = useMemo(() => {
    const arr: MenuItem[] = [
      { icon: 'cluster', label: 'Clusters', path: paths.clusters(), showTooltip: true },
    ];

    if (!isGLCPDeployment()) {
      arr.push({ icon: 'user', label: 'Members', path: paths.members(), showTooltip: true });
    }

    if (user.roles && selectedOrg && isOrgAdmin(user.roles, selectedOrg?.id)) {
      arr.push({
        icon: 'settings',
        label: getOrganizationIdentifier(),
        path: paths.organization(),
        showTooltip: true,
      });
    }

    arr.push({ icon: 'overflow-vertical', label: 'Overflow Menu', onClick: handleOverflowOpen });

    return arr;
  }, [user.roles, selectedOrg, handleOverflowOpen]);

  const actionSheetItems = useMemo(() => {
    const arr: ActionItem[] = [
      {
        render: () => (
          <div key="avatar-card">
            <AvatarCard className={css.user} text={getDisplayName(user)} />
          </div>
        ),
      },
    ];

    if (user.superAdmin && selectedOrg?.isSuperAdminOrg) {
      arr.push({
        icon: 'group',
        label: 'Customer Orgs',
        path: paths.customerOrgs(),
      });
    }

    arr.push(
      { external: true, icon: 'docs', label: 'Docs', path: paths.docs(), popout: true },
      { external: true, icon: 'info', label: 'Privacy', path: paths.privacy(), popout: true },
      {
        external: true,
        icon: 'pencil',
        label: 'Feedback',
        path: paths.feedback(),
        popout: true,
      },
      {
        icon: 'user',
        label: 'Sign out',
        onClick: (e: AnyMouseEvent) => handlePathUpdate(e, paths.logout()),
      },
    );

    return arr;
  }, [user, selectedOrg, handlePathUpdate]);

  return (
    <nav className={css.base}>
      <div className={css.toolbar}>
        {menuItems.map((m) => {
          return (
            <ToolbarItem
              icon={m.icon}
              key={m.label + m.path}
              label={m.label}
              path={m.path}
              onClick={m.onClick}
            />
          );
        })}
      </div>
      <ActionSheet
        actions={actionSheetItems}
        show={isShowingOverflow}
        onCancel={handleActionSheetCancel}
      />
    </nav>
  );
};

export default NavigationTabbar;

import { useLayoutEffect, useState } from 'react';

import { config, Settings } from 'components/ThemeProvider.settings';
import useUI from 'components/UIManager';
import useSettings from 'hooks/useSettings';

const useUserTheme = (): void => {
  const { ui, actions: uiActions } = useUI();
  const [isSettingsReady, setIsSettingsReady] = useState(false);
  const { settings, updateSettings } = useSettings<Settings>(config);

  // Update setting mode when mode changes.
  useLayoutEffect(() => {
    if (isSettingsReady) {
      // We have read from the settings, going forward any mode difference requires an update.
      if (settings.mode !== ui.mode) updateSettings({ mode: ui.mode });
    } else {
      // Initially set the mode from settings.
      uiActions.setMode(settings.mode);
      setIsSettingsReady(true);
    }
  }, [isSettingsReady, settings, uiActions, ui.mode, updateSettings]);
};

export default useUserTheme;

import { ModelClusterInfo } from 'services/regional-bindings';
import { DetError } from 'utils/error';
import { Storage } from 'utils/storage';

import { ensureNotificationPermission } from './popup';
// user storage, subscription, and unsubscription

export enum SubscriptionType {
  ClusterCreated = 'ClusterCreated',
  ClusterDeleted = 'ClusterDeleted',
  ClusterPaused = 'ClusterPaused',
  ClusterResumed = 'ClusterResumed',
  ClusterUpgraded = 'ClusterUpgraded',
  ClusterReconfigured = 'ClusterReconfigured',
  ClusterReprovisioned = 'ClusterReprovisioned',
}

export interface Subscription {
  id: string;
  type: SubscriptionType;
}

/**
 * track user subscriptions.
 * can track on subscription type per id.
 */
class SubscStorage {
  private _storage: Storage;
  private orgStorage: Record<string, Storage>;
  private user: string;
  private orgId: string;

  constructor(storage: Storage) {
    this.orgStorage = {};
    this._storage = storage;
  }

  get hasActiveSubscriptions(): boolean {
    return this.storage.keys().length > 0;
  }

  get subscriptions(): Subscription[] {
    return this.storage.keys().map((id) => {
      return { id, type: this.storage.get<SubscriptionType>(id) as SubscriptionType };
    });
  }

  private get storage(): Storage {
    if (!this.isStorageSetup())
      throw new DetError(undefined, { publicMessage: 'Notification storage is not initialized.' });
    if (!this.orgStorage[this.orgId]) {
      this.orgStorage[this.orgId] = this._storage.fork(`${this.orgId}-${this.user}`);
    }
    return this.orgStorage[this.orgId];
  }

  setup(orgId: string, userId: string) {
    this.user = userId;
    this.orgId = orgId;
  }

  getSubscription(id: string) {
    return this.storage.get<SubscriptionType>(id);
  }

  addSubscription(s: Subscription) {
    ensureNotificationPermission();
    this.storage.set(s.id, s.type);
  }

  removeSubscription(id: string) {
    this.storage.remove(id);
  }

  /**
   * check whether the storage has been initialized with the necessary
   * org and user info
   * CHECK: could we design this so that it's not necessary? I think yes but
   * not worth the effort for an experimental feature.
   */
  isStorageSetup(): boolean {
    return !!this.orgId && !!this.user;
  }
}

class ClusterSubscStorage extends SubscStorage {
  private namespace = 'cluster/';

  addClusterSub(cluster: Pick<ModelClusterInfo, 'id' | 'location'>, type: SubscriptionType) {
    return this.addSubscription({ id: this.clusterSubId(cluster), type });
  }

  removeClusterSub(cluster: Pick<ModelClusterInfo, 'id' | 'location'>) {
    return this.removeSubscription(this.clusterSubId(cluster));
  }

  /**
   * @returns a mapping of region to cluster id subscriptions
   */
  listClusterSubs() {
    const subs: Record<string, Record<string, SubscriptionType>> = {};
    this.subscriptions
      .filter((s) => s.id.startsWith(this.namespace))
      .forEach((s) => {
        const [region, id] = JSON.parse(s.id.substring(this.namespace.length));
        subs[region] = subs[region] || {};
        subs[region][id] = s.type;
      });
    return subs;
  }

  private clusterSubId(cluster: Pick<ModelClusterInfo, 'id' | 'location'>) {
    return this.namespace + JSON.stringify([cluster.location, cluster.id]);
  }
}

/** notification storage */
export const notifStorage = new ClusterSubscStorage(
  new Storage({ basePath: 'notification', store: window.localStorage }),
);

import { validate } from '@aws-sdk/util-arn-parser';
import Form from 'hew/Form';
import Pivot, { TabItem } from 'hew/Pivot';
import Select, { Option } from 'hew/Select';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FaAws } from 'react-icons/fa';
import { SiCheckmarx, SiGooglecloud, SiKubernetes } from 'react-icons/si';

import Link from 'components/Link';
import OrgGuid from 'components/OrgGuid';
import { useAuth } from 'contexts/Auth';
import { useStore } from 'contexts/Store';
import useConfig, { Config } from 'hooks/useConfig';
import { useFetchOrgs, useFetchWithRetry } from 'hooks/useFetch';
import { globalServerAddress } from 'routes/utils';
import { createBackendProvider, createOrg } from 'services/api';
import { globalVersion } from 'services/apiConfig';
import { ModelCreateOrgRequest, ModelCreateOrgResponse } from 'services/global-bindings';
import { Backend, Eventually, Location } from 'types';
import handleError, { ErrorLevel, wrapPublicMessage } from 'utils/error';
import { getOrganizationIdentifier, isMspDeployment } from 'utils/saas';

import {
  AwsAgentInstanceProfile,
  AwsCrossAccountRoleItem,
  AwsMasterInstanceProfile,
  ByokKubeConfig,
  GcpProjectId,
  OrgName,
} from './OrgFormItems';

interface Props {
  existingOrg: boolean;
  fetchOrgBackendProviders?: () => Promise<void>;
  onCreate?: (newOrgId: string, newOrgName: string) => Eventually<void>;
  setIsSubmitEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  nextOrgGuid: string;
  agentInstanceProfile: string;
  masterInstanceProfile: string;
  name: string;
  projectId: string;
  type: string;
  xAcctRole: string;
  kubeconfig: string;
}

interface AWSPaneProps {
  config?: Config;
  formValues: FormValues;
  guid?: string;
}

const AWSPaneContent: React.FC<AWSPaneProps> = ({ config, formValues, guid }) => {
  const checkArn = (_: object, arn: string) => {
    if (validate(arn)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('ARN syntax is invalid'));
  };

  const selectItems = useMemo(() => {
    return (
      <>
        <Option key={Backend.EC2} value={Backend.EC2}>
          {Backend.EC2.toUpperCase()}
        </Option>
        {config?.mldmEnabled && (
          <Option key={Backend.EKS} value={Backend.EKS}>
            {Backend.EKS.toUpperCase()}
          </Option>
        )}
      </>
    );
  }, [config]);

  if (!config) return null;

  return (
    <div>
      <span>
        A{' '}
        <Link
          path={globalServerAddress(
            `${globalVersion}/${formValues?.type ?? 'ec2'}/cloudformation${
              guid ? `?externalId=${guid}` : ''
            }`,
          )}
          popout={true}>
          CloudFormation template
        </Link>{' '}
        is available for creating the resources for this particular organization. Upload it to{' '}
        <Link
          path={
            'https://us-west-2.console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/create'
          }
          popout={true}>
          AWS CloudFormation
        </Link>{' '}
        to create the resources required here.
      </span>
      <br />
      <br />
      <div style={{ display: !config.mldmEnabled ? 'none' : undefined }}>
        <Form.Item
          extra={<div>Choose between EC2 or EKS for your infrastructure.</div>}
          label="Backend Type"
          name="type"
          required={true}>
          <Select disabled={!config.mldmEnabled}>{selectItems}</Select>
        </Form.Item>
      </div>
      <AwsCrossAccountRoleItem rules={[{ required: true, validator: checkArn }]} />
      {formValues && formValues.type === Backend.EC2 && (
        <>
          <AwsMasterInstanceProfile rules={[{ required: true, validator: checkArn }]} />
          <AwsAgentInstanceProfile rules={[{ required: true, validator: checkArn }]} />
        </>
      )}
    </div>
  );
};

const defaultTab = Location.AWS;

export interface OrgFormHandles {
  handleSubmit: () => Promise<void>;
}
/**
 * A modal that gives user option to create a new
 * organization by providing a name through a form
 * and confirming.
 */
const OrgForm = forwardRef<OrgFormHandles, Props>(
  ({ existingOrg, fetchOrgBackendProviders, onCreate, setIsSubmitEnabled }, ref) => {
    const [canceler] = useState(() => new AbortController());
    const fetchOrgs = useFetchOrgs(canceler);
    const { supportMatrix } = useStore();
    const [form] = Form.useForm<FormValues>();
    const formValues = Form.useWatch<FormValues>([], form);
    const [currentTab, setTab] = useState<Location>(defaultTab);
    const [file, setFile] = useState<string>('');
    const fetchWithRetry = useFetchWithRetry(canceler);
    const { doRefreshToken } = useAuth();
    const {
      orgState: { orgs, selectedOrg, nextOrgGuid },
    } = useStore();
    const config = useConfig(canceler);

    // signal cancellation on unmount
    useEffect(() => {
      return () => {
        canceler.abort();
      };
    }, [canceler]);

    useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setIsSubmitEnabled(true))
        .catch(() => {
          setIsSubmitEnabled(false);
        });
    }, [form, formValues, currentTab, setIsSubmitEnabled]);

    const handleSubmit = useCallback(async () => {
      const inputs = form.getFieldsValue();
      inputs.kubeconfig = file;

      let createOrgRequest = {} as ModelCreateOrgRequest;
      if (currentTab === Location.AWS) {
        createOrgRequest = {
          backendProvider: {
            connectionInfo: {
              agentInstanceProfile: inputs.agentInstanceProfile,
              masterInstanceProfile: inputs.masterInstanceProfile,
              xAcctRole: inputs.xAcctRole,
            },
            type: inputs.type,
          },
          name: inputs.name,
          nextOrgGuid,
        };
      } else if (currentTab === Location.GCP) {
        createOrgRequest = {
          backendProvider: { connectionInfo: { projectId: inputs.projectId }, type: Backend.GKE },
          name: inputs.name,
          nextOrgGuid,
        };
      } else if (currentTab === Location.BYOK8S) {
        createOrgRequest = {
          backendProvider: {
            connectionInfo: { kubeconfig: inputs.kubeconfig },
            type: Backend.BYOK8S,
          },
          name: inputs.name,
          nextOrgGuid,
        };
      }
      let newOrg: ModelCreateOrgResponse | null = null;

      try {
        if (!existingOrg) {
          newOrg = await fetchWithRetry(
            async () => await createOrg(createOrgRequest, { signal: canceler.signal }),
          );
        } else {
          if (!selectedOrg) return;
          await fetchWithRetry(
            async () =>
              await createBackendProvider(
                { backendProvider: createOrgRequest.backendProvider, orgId: selectedOrg.id },
                {
                  signal: canceler.signal,
                },
              ),
          );
        }
        await doRefreshToken(canceler);
        setTab(defaultTab);
        if (fetchOrgBackendProviders) await fetchOrgBackendProviders();
      } catch (error) {
        handleError(error, {
          leaveOpen: true,
          level: ErrorLevel.Error,
          publicMessage: wrapPublicMessage(
            error,
            `Failed to create ${getOrganizationIdentifier()}`,
          ),
          silent: false,
        });
        throw error;
      }

      try {
        await fetchOrgs();
        newOrg && onCreate?.(newOrg.id, createOrgRequest.name);
      } catch (error) {
        handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: `Failed to create ${getOrganizationIdentifier()}`,
          silent: false,
        });
      }
    }, [
      form,
      file,
      currentTab,
      existingOrg,
      doRefreshToken,
      canceler,
      fetchOrgBackendProviders,
      fetchWithRetry,
      selectedOrg,
      nextOrgGuid,
      fetchOrgs,
      onCreate,
    ]);

    useImperativeHandle(ref, () => ({ handleSubmit }), [handleSubmit]);

    const tabItems = useMemo(() => {
      const items: TabItem[] = [];

      const orgGuid = existingOrg && selectedOrg?.guid ? selectedOrg.guid : nextOrgGuid;
      // Support AWS backend regardless
      items.push({
        children: currentTab === Location.AWS && (
          <div>
            <br />
            <AWSPaneContent config={config} formValues={formValues} guid={orgGuid} />
            <OrgGuid formItemName="nextOrgGuid" guid={orgGuid} />
          </div>
        ),
        key: Location.AWS,
        label:
          currentTab === Location.AWS ? (
            <span>
              <FaAws /> AWS <SiCheckmarx />
            </span>
          ) : (
            <span>
              <FaAws /> AWS
            </span>
          ),
      });

      // Support GCP backend for all non-MSP deployments
      if (!isMspDeployment()) {
        items.push({
          children: currentTab === Location.GCP && (
            <div>
              <br />
              <GcpProjectId />
              <OrgGuid formItemName="nextOrgGuid" guid={orgGuid} />
            </div>
          ),
          key: Location.GCP,
          label:
            currentTab === Location.GCP ? (
              <span>
                <SiGooglecloud /> GCP <SiCheckmarx />
              </span>
            ) : (
              <span>
                <SiGooglecloud /> GCP
              </span>
            ),
        });
      }

      // Support BYOK backend as long as it's enabled in the support matrix
      if (supportMatrix?.byokEnabled) {
        items.push({
          children: currentTab === Location.BYOK8S && (
            <div>
              <br />
              <ByokKubeConfig setFile={setFile} />
            </div>
          ),
          key: Location.BYOK8S,
          label:
            currentTab === Location.BYOK8S ? (
              <span>
                <SiKubernetes /> K8S <SiCheckmarx />
              </span>
            ) : (
              <span>
                <SiKubernetes /> K8S
              </span>
            ),
        });
      }

      return items;
    }, [
      config,
      currentTab,
      existingOrg,
      formValues,
      nextOrgGuid,
      selectedOrg?.guid,
      supportMatrix?.byokEnabled,
    ]);

    return (
      <Form
        form={form}
        initialValues={{ name: existingOrg ? selectedOrg?.name : '', type: Backend.EC2 }}
        labelCol={{ span: 6 }}>
        <OrgName currentOrgs={orgs?.map((org) => org.name)} disabled={existingOrg} />
        <b>Backend Provider </b>
        <Pivot
          activeKey={currentTab}
          defaultActiveKey={defaultTab}
          destroyInactiveTabPane={true}
          items={tabItems}
          onChange={(e: string) => {
            setTab(e as Location);
          }}
        />
      </Form>
    );
  },
);

export default OrgForm;

import { ColumnType } from 'antd/es/table';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ResponsiveTable from 'components/ResponsiveTable';
import { useAuth } from 'contexts/Auth';
import { useStore } from 'contexts/Store';
import { useFetchClusters } from 'experimental/notifications/hooks';
import { useFetchSupportMatrix, useFetchWithRetry } from 'hooks/useFetch';
import RoleAdd from 'pages/Members/Roles/RoleAdd';
import RoleDelete from 'pages/Members/Roles/RoleDelete';
import RoleSelect from 'pages/Members/Roles/RoleSelect';
import { ClusterRole, OrgRole } from 'saasTypes';
import { getUserClusters, updateClusterLevelRole } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import handleError from 'utils/error';
import { alphaNumericSorter, semVerIsOlder } from 'utils/sort';
import { stringToVersion } from 'utils/string';

interface Props {
  onClose?: () => void;
  orgId: string;
  user: GlobalApi.ModelOrgUser;
}

export const _UserClustersModal: React.FC<Props> = ({ user, onClose, orgId }) => {
  const [orgClusters, setOrgClusters] = useState<GlobalApi.ModelClusterInfo[]>([]);
  const [userClusters, setUserClusters] = useState<GlobalApi.ModelClusterAccessInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const { supportMatrix } = useStore();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const fetchClusters = useFetchClusters(canceler);
  const { checkAuth } = useAuth();

  const loadOrgClusters = useCallback(async () => {
    const clusters = await fetchClusters();
    if (!clusters) return;
    setOrgClusters(clusters);
  }, [fetchClusters]);

  const loadUserClusters = useCallback(async () => {
    try {
      const clusters = await fetchWithRetry(
        async () =>
          await getUserClusters(
            {
              orgId,
              userId: user.id,
            },
            { signal: canceler.signal },
          ),
      );
      if (!clusters) return;
      setUserClusters(clusters);
    } catch (e) {
      handleError(e, {
        publicSubject: 'Failed to fetch clusters',
      });
    }
  }, [canceler.signal, fetchWithRetry, orgId, user.id]);

  const fetchData = useCallback(async () => {
    if (!supportMatrix) return;
    await loadOrgClusters();
    await loadUserClusters();
    setLoading(false);
  }, [loadOrgClusters, loadUserClusters, supportMatrix]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [supportMatrix, fetchSupportMatrix]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  /**
   * Update the user's access level to a cluster
   */
  const updateUserClusterRole = useCallback(
    async (clusterId: string, newAccess: ClusterRole) => {
      try {
        await fetchWithRetry(
          async () =>
            await updateClusterLevelRole(
              {
                clusterId,
                orgId,
                role: newAccess,
                userId: user.id,
              },
              { signal: canceler.signal },
            ),
        );
        checkAuth(canceler);
        fetchData();
      } catch (e) {
        handleError(e, {
          publicSubject: 'Failed to update user access level',
        });
      }
    },
    [canceler, checkAuth, fetchData, fetchWithRetry, orgId, user.id],
  );

  const columns = useMemo(() => {
    const cols: ColumnType<GlobalApi.ModelClusterAccessInfo>[] = [
      {
        dataIndex: 'id',
        defaultSortOrder: 'ascend',
        key: 'id',
        sorter: (a, b) => alphaNumericSorter(a.id, b.id),
        title: 'ID',
        width: 300,
      },
      {
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => alphaNumericSorter(a.name, b.name),
        title: 'Name',
      },
      {
        dataIndex: 'location',
        key: 'location',
        sorter: (a, b) => alphaNumericSorter(a.location, b.location),
        title: 'Location',
      },
      {
        dataIndex: 'detVersion',
        key: 'detVersion',
        sorter: (a, b) =>
          semVerIsOlder(stringToVersion(a.detVersion || ''), stringToVersion(b.detVersion || ''))
            ? -1
            : 1,
        title: 'Version',
      },
      {
        dataIndex: 'role',
        key: 'role',
        render: (_, record) => {
          return (
            <RoleSelect
              availableRoles={[ClusterRole.Admin, ClusterRole.User]}
              defaultValue={record.role as ClusterRole}
              disabled={record.orgRole === OrgRole.Admin}
              onChange={(level: ClusterRole) => updateUserClusterRole(record.id, level)}
            />
          );
        },
        sorter: (a, b) => alphaNumericSorter(a.role, b.role),
        title: 'Cluster Role',
      },
      {
        dataIndex: 'delete',
        key: 'delete',
        render: (_, record) => {
          if (record.orgRole === OrgRole.Admin) {
            return;
          }
          return <RoleDelete id={record.id} onChange={updateUserClusterRole} />;
        },
        title: '',
      },
    ];
    return cols;
  }, [updateUserClusterRole]);

  const excludedClusters = useMemo(() => {
    return orgClusters.filter((oc) => !userClusters.some((uc) => uc.id === oc.id));
  }, [orgClusters, userClusters]);

  return (
    <Modal
      size="medium"
      submit={{
        handleError,
        handler: () => Promise.resolve(),
        text: 'Ok',
      }}
      title={`Manage Cluster Access for ${user.name}`}
      onClose={onClose}>
      <RoleAdd
        initRole={() =>
          user.defaultClusterRole === ClusterRole.None ? ClusterRole.User : user.defaultClusterRole
        }
        optionIdField="id"
        optionLabelField="name"
        options={excludedClusters}
        optionType="cluster"
        onUpdate={updateUserClusterRole}
      />
      <ResponsiveTable<GlobalApi.ModelClusterAccessInfo>
        columns={columns}
        dataSource={userClusters}
        loading={loading}
        pagination={{ hideOnSinglePage: true }}
        rowKey="id"
        showSorterTooltip={false}
      />
    </Modal>
  );
};

import Select, { Option, SelectValue } from 'hew/Select';
import useConfirm from 'hew/useConfirm';
import React, { useCallback } from 'react';

import { ModelOrgUserWithOverrides, RoleType } from 'saasTypes';
import { Eventually } from 'types';
import handleError, { wrapPublicMessage } from 'utils/error';
type MembersPageInfo = {
  record: ModelOrgUserWithOverrides;
  users: ModelOrgUserWithOverrides[];
};

interface Props<R extends RoleType> {
  availableRoles: R[];
  defaultValue: R;
  disabled?: boolean;
  onChange?: (value: R) => Eventually<void>;
  membersPageInfo?: MembersPageInfo;
}

/** render a list of access levels as a Select */
const RoleSelect = <R extends RoleType>({
  availableRoles,
  membersPageInfo,
  onChange,
  defaultValue,
  disabled,
}: Props<R>): React.ReactElement => {
  const confirm = useConfirm();
  const wrappedOnChange = useCallback(
    async (value: SelectValue) => {
      if (!onChange) return;
      // If this is on the members page, the error will be caught in the useConfirm handler
      if (membersPageInfo) {
        await onChange(value as R);
      } else {
        try {
          await onChange(value as R);
        } catch (e) {
          handleError(e, {
            publicMessage: wrapPublicMessage(e, 'Failed to update user access level'),
          });
          // TODO [DET-6907]: we either need to revert the change to Select on failure
          // or put it behind a loading state until we verify the changes took place.
        }
      }
    },
    [onChange, membersPageInfo],
  );

  const handleSelectChangeWithModal = (newDefaultRole: SelectValue) => {
    let modalText = "Are you sure you would like to change this member's access level? ";
    switch (newDefaultRole) {
      case 'admin':
        modalText += 'They will have admin access on all clusters.\n';
        break;
      case 'user':
        modalText += 'They will have user access on all clusters.\n';
        break;
      case 'none':
        modalText += 'They will not have access to any clusters.\n';
        break;
      default:
        break;
    }
    // Only mention an exception if the cluster override role value is different from the one that has been selected
    const clustersWithOverridesForThisUser = [];
    for (const cluster in membersPageInfo?.record.overrides) {
      const overrideRoleInCluster = membersPageInfo?.record.overrides[cluster];
      if (overrideRoleInCluster !== newDefaultRole) {
        clustersWithOverridesForThisUser.push({ cluster, overrideRoleInCluster });
      }
    }
    if (clustersWithOverridesForThisUser.length) {
      modalText +=
        '\n The following clusters will not be affected as they have overrides set for this member: \n';
      clustersWithOverridesForThisUser.forEach((override) => {
        modalText += `\n ${override.cluster} - ${override.overrideRoleInCluster}`;
      });
    }

    confirm({
      content: <div style={{ whiteSpace: 'pre-line' }}>{modalText}</div>,
      danger: true,
      onConfirm: () => wrappedOnChange(newDefaultRole),
      onError: (e) => {
        handleError(e, {
          publicMessage: wrapPublicMessage(e, 'Failed to update user access level'),
        });
      },
      title: 'Warning',
    });
  };

  return (
    <div>
      <Select
        disabled={disabled}
        value={defaultValue}
        onChange={membersPageInfo ? handleSelectChangeWithModal : wrappedOnChange}>
        {availableRoles.map((role) => (
          <Option key={role} value={role}>
            {role}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default RoleSelect;

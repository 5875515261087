import { Divider } from 'antd';
import Avatar, { Size } from 'hew/Avatar';
import Pivot from 'hew/Pivot';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { telemetryInstance } from 'analytics';
import Message, { MessageType } from 'components/Message';
import Page from 'components/Page';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchClusters } from 'experimental/notifications/hooks';
import { useFetchSupportMatrix } from 'hooks/useFetch';
import usePolling from 'hooks/usePolling';
import { routeToReactUrl } from 'utils/routes';
import { getOrganizationIdentifier, isOrgAdmin } from 'utils/saas';

import BackendProviderForm from './BackendProviderForm';
import css from './Organization.module.scss';
import OrganizationInfoForm from './OrganizationInfoForm';
import OrganizationQuotasForm from './OrganizationQuotas';

enum PageTab {
  org = 'Info',
  backendProvider = 'Backend Provider',
  orgQuotas = 'Resource Quotas',
}

const ORG_PAGE_TAB_ROUTES: { [key in PageTab]: string } = {
  [PageTab.org]: 'info',
  [PageTab.backendProvider]: 'backendProvider',
  [PageTab.orgQuotas]: 'quotas',
};

const Organization: React.FC = () => {
  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();
  const { roles } = useUser();
  const { tab } = useParams<{ tab: string }>();
  const [canceler] = useState(() => new AbortController());
  const [defaultTab, setDefaultTab] = useState<PageTab>();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const fetchClusters = useFetchClusters(canceler);
  usePolling(fetchClusters);

  useEffect(() => {
    if (!tab) routeToReactUrl(`/organization/${ORG_PAGE_TAB_ROUTES[PageTab.org]}`);
    else if (tab === ORG_PAGE_TAB_ROUTES[PageTab.org]) {
      setDefaultTab(PageTab.org);
    } else if (tab === ORG_PAGE_TAB_ROUTES[PageTab.backendProvider]) {
      setDefaultTab(PageTab.backendProvider);
    } else {
      setDefaultTab(PageTab.orgQuotas);
    }
  }, [tab]);

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [supportMatrix, fetchSupportMatrix]);

  useEffect(() => {
    fetchClusters();
  }, [fetchClusters, selectedOrg]);

  useEffect(() => telemetryInstance.page(), []);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const tabItems = useMemo(() => {
    const items = [
      {
        children: (
          <div className={css.base}>
            <div className={css.orgAvatar}>
              <Avatar noColor size={Size.Large} text={selectedOrg ? selectedOrg.name : ''} />
            </div>
            <Divider />
            <OrganizationInfoForm />
          </div>
        ),
        key: PageTab.org,
        label: PageTab.org,
      },
      {
        children: (
          <div className={css.base}>
            <BackendProviderForm />
          </div>
        ),
        key: PageTab.backendProvider,
        label: PageTab.backendProvider,
      },
    ];

    if (supportMatrix?.orgQuotasEnabled) {
      items.push({
        children: (
          <div className={css.base}>
            <div className={css.orgAvatar}>
              <Avatar noColor size={Size.Large} text={selectedOrg ? selectedOrg.name : ''} />
            </div>
            <Divider />
            <OrganizationQuotasForm />
          </div>
        ),
        key: PageTab.orgQuotas,
        label: PageTab.orgQuotas,
      });
    }

    return items;
  }, [selectedOrg, supportMatrix?.orgQuotasEnabled]);

  const isAdmin = useMemo(() => {
    return selectedOrg && isOrgAdmin(roles, selectedOrg?.id);
  }, [roles, selectedOrg]);

  if (!isAdmin) {
    return (
      <div className={css.base}>
        <Message title="You Are Not Authorized To Access This Page" type={MessageType.Warning} />
      </div>
    );
  }

  if (!defaultTab) return null;

  return (
    <Page title={getOrganizationIdentifier()}>
      <Pivot
        activeKey={defaultTab}
        items={tabItems}
        onChange={(e: string) => {
          routeToReactUrl(`/organization/${ORG_PAGE_TAB_ROUTES[e as PageTab]}`);
        }}
      />
    </Page>
  );
};

export default Organization;

import Form from 'hew/Form';
import Input from 'hew/Input';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ClusterLevelAccessIcon, OrgLevelAccessIcon } from 'components/AccessLevelInfoIcon';
import { useStore } from 'contexts/Store';
import { useFetchWithRetry } from 'hooks/useFetch';
import usePolling from 'hooks/usePolling';
import RoleSelect from 'pages/Members/Roles/RoleSelect';
import { availableClusterRoles, availableOrgRoles, ClusterRole, OrgRole } from 'saasTypes';
import { getOrgMembers, inviteOrgUser } from 'services/api';
import { Eventually } from 'types';
import handleError, { ErrorLevel, fieldsErrorToMessage } from 'utils/error';

interface Props {
  onClose?: () => Eventually<void>;
  orgId: string;
}

interface FormValues {
  defaultClusterRole: ClusterRole;
  orgRole: OrgRole;
  userId: string;
}

const initialValues = {
  defaultClusterRole: ClusterRole.User,
  orgRole: OrgRole.User,
  userId: '',
};

export const _AddMemberModal: React.FC<Props> = ({ orgId, onClose }) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [memberEmails, setMemberEmails] = useState<string[]>([]);
  const {
    orgState: { selectedOrg },
  } = useStore();
  const [form] = Form.useForm<FormValues>();
  const formValues: FormValues | undefined = Form.useWatch([], form);

  useEffect(() => {
    if (formValues?.orgRole === 'admin') form.setFieldValue('defaultClusterRole', 'admin');
    form.validateFields({ validateOnly: true }).then(
      () => setIsSubmitEnabled(!isLoading),
      () => setIsSubmitEnabled(false),
    );
  }, [form, formValues, isLoading]);

  const fetchUsers = useCallback(async () => {
    if (!selectedOrg) return;
    const memberRes = await fetchWithRetry(
      async () => await getOrgMembers({ orgId: selectedOrg.id }, { signal: canceler.signal }),
    );
    const emails = memberRes.map((user) => user.email);
    setMemberEmails(emails);
  }, [canceler, fetchWithRetry, selectedOrg]);

  useEffect(() => {
    try {
      fetchUsers();
    } catch (err) {
      handleError(err, {
        level: 'error',
        publicSubject: 'Failed to fetch users',
        silent: false,
      });
    }
  }, [fetchUsers]);

  usePolling(fetchUsers, {
    continueWhenHidden: false,
    interval: 15000, // check again every 15 sec
  });

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const handleSubmit = useCallback(async () => {
    try {
      if (isLoading) {
        return;
      }
      let fields;
      try {
        fields = await form?.validateFields();
      } catch (e) {
        const message = fieldsErrorToMessage(form?.getFieldsError());

        handleError(e, {
          isUserTriggered: true,
          publicMessage: message,
          publicSubject: 'Field Error',
        });
        return;
      }

      const inviteReq = {
        orgId,
        userInfo: {
          defaultClusterRole: fields.defaultClusterRole,
          email: formValues?.userId,
          role: formValues?.orgRole,
        },
      };
      setLoading(true);
      await fetchWithRetry(async () => await inviteOrgUser(inviteReq, { signal: canceler.signal }));
      form.resetFields();
      onClose?.();
    } catch (error) {
      handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to add member',
        silent: false,
      });
    } finally {
      setLoading(false);
    }
  }, [
    fetchWithRetry,
    isLoading,
    orgId,
    formValues?.userId,
    formValues?.orgRole,
    canceler,
    form,
    onClose,
  ]);

  const modalContent = useMemo(() => {
    return (
      <div>
        <Form form={form} initialValues={initialValues}>
          <Form.Item
            label="Email"
            name="userId"
            rules={[
              {
                validator(_, value) {
                  if (memberEmails.includes(value)) {
                    return Promise.reject(new Error('Already a member'));
                  }
                  return Promise.resolve();
                },
              },
              {
                message: 'Valid email required for invite',
                required: true,
                type: 'email',
              },
            ]}>
            <Input autoComplete="off" min={4} placeholder="User email" type="text" />
          </Form.Item>
          <Form.Item label={OrgLevelAccessIcon()} name="orgRole">
            <RoleSelect
              availableRoles={availableOrgRoles}
              defaultValue={formValues?.orgRole ?? initialValues.orgRole}
              onChange={(role) => form.setFieldValue('orgRole', role)}
            />
          </Form.Item>
          <Form.Item label={ClusterLevelAccessIcon()} name="defaultClusterRole">
            <RoleSelect
              availableRoles={availableClusterRoles}
              defaultValue={formValues?.defaultClusterRole ?? initialValues.defaultClusterRole}
              disabled={formValues?.orgRole === 'admin'}
              onChange={(role) => form.setFieldValue('defaultClusterRole', role)}
            />
          </Form.Item>
        </Form>
      </div>
    );
  }, [form, formValues?.defaultClusterRole, formValues?.orgRole, memberEmails]);

  return (
    <Modal
      size="medium"
      submit={{
        disabled: !isSubmitEnabled,
        handleError,
        handler: handleSubmit,
        text: 'Add',
      }}
      title="Add Member">
      {modalContent}
    </Modal>
  );
};

import { Input } from 'antd';
import Accordion from 'hew/Accordion';
import Form, { FormInstance } from 'hew/Form';
import React from 'react';

import { cidrRules as rules } from 'pages/Clusters/cluster-util';

export interface NetworkingParams {
  ipRange: string;
  cluster: string;
  services: string;
  private: string;
}

export type UpdateNetworkingParams = (n: Partial<NetworkingParams>) => void;

interface Props {
  form: FormInstance;
  updateNetworkingParams: UpdateNetworkingParams;
  networkingParams: Partial<NetworkingParams>;
}

/** renders a list of inputs to enter subnet ranges */
const NetworkingParamsPicker: React.FC<Props> = ({
  updateNetworkingParams,
  networkingParams,
  form,
}) => {
  return (
    <Form form={form}>
      <Accordion title={'Networking'}>
        <Form.Item
          initialValue={networkingParams.ipRange}
          label="Primary Subnet Range"
          name="ipRange"
          rules={rules}
          tooltip="The primary address range for this cluster. Must be a RFC-1918 private IP address range">
          <Input onChange={(e) => updateNetworkingParams({ ipRange: e.target.value })} />
        </Form.Item>
        <Form.Item
          initialValue={networkingParams.private}
          label="Private Subnet Range"
          name="private"
          rules={rules}
          tooltip="The address range for the private subnet">
          <Input onChange={(e) => updateNetworkingParams({ private: e.target.value })} />
        </Form.Item>
        <Form.Item
          initialValue={networkingParams.cluster}
          label="Pod Address Range"
          name="cluster"
          rules={rules}
          tooltip="Pods in this cluster will be assigned an IP address from this range">
          <Input onChange={(e) => updateNetworkingParams({ cluster: e.target.value })} />
        </Form.Item>
        <Form.Item
          initialValue={networkingParams.services}
          label="Service Address Range"
          name="services"
          rules={rules}
          tooltip="Cluster services will be assigned an IP address from this range">
          <Input onChange={(e) => updateNetworkingParams({ services: e.target.value })} />
        </Form.Item>
      </Accordion>
    </Form>
  );
};

export default NetworkingParamsPicker;

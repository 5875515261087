import { FilterDropdownProps } from 'antd/lib/table/interface';
import Avatar from 'hew/Avatar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ResponsiveTable from 'components/ResponsiveTable';
import TableFilterSearch from 'components/TableFilterSearch';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchWithRetry } from 'hooks/useFetch';
import RoleSelect from 'pages/Members/Roles/RoleSelect';
import {
  availableClusterRoles,
  availableOrgRoles,
  ModelOrgUserWithOverrides,
  OrgRole,
} from 'saasTypes';
import { getOrgMembers, upsertOrgUser } from 'services/api';
import handleError from 'utils/error';

import { addOverridesToMembers } from './members-util';
import css from './Members.module.scss';
import { columns } from './Members.table';
import MembersActionDropdown from './MembersActionDropdown';

interface MembersTabContentProps {
  isAdmin: boolean;
}

const MembersTabContent: React.FC<MembersTabContentProps> = ({ isAdmin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState<ModelOrgUserWithOverrides[]>([]);
  const [updatingRoles, setUpdatingRoles] = useState(false);
  const [nameFilter, setNameFilter] = useState('');
  const [filteredMembers, setFilteredMembers] = useState<ModelOrgUserWithOverrides[]>([]);

  const {
    orgState: { selectedOrg, clusters },
  } = useStore();
  const { supportMatrix } = useStore();

  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);

  const user = useUser();

  const fetchUsers = useCallback(async () => {
    if (!selectedOrg) return;
    const members = await fetchWithRetry(
      async () => await getOrgMembers({ orgId: selectedOrg.id }, { signal: canceler.signal }),
    );

    // Overrides are only needed to prompt a warning to admins when they change a member's default cluster role
    if (!isAdmin) {
      setMembers(members);
    } else {
      const membersAndOverrides = await addOverridesToMembers(
        members,
        clusters ? clusters : {},
        selectedOrg.id,
        fetchWithRetry,
        canceler,
      );
      setMembers(membersAndOverrides);
    }
    setIsLoading(false);
  }, [canceler, clusters, fetchWithRetry, isAdmin, selectedOrg]);

  const canModifyUser = useCallback(
    (targetUser: ModelOrgUserWithOverrides) => {
      if (!selectedOrg) return false;
      if (supportMatrix?.glcpEnabled) return false;
      const curUserRole = user.roles[selectedOrg.id];
      if (curUserRole.role !== OrgRole.Admin) return false;
      if (user.userId === targetUser.id) return false;
      return true;
    },
    [supportMatrix, selectedOrg, user.roles, user.userId],
  );

  /**
   * Update the user's access level and handle any errors.
   */
  const updateUserRole = useCallback(
    async (newUser: ModelOrgUserWithOverrides, roleChanged: boolean) => {
      if (!selectedOrg) return;
      setUpdatingRoles(true);
      if (roleChanged) {
        if (newUser.role === 'admin') {
          newUser.defaultClusterRole = 'admin';
        }
      }
      try {
        await fetchWithRetry(
          async () =>
            await upsertOrgUser(
              {
                orgId: selectedOrg.id,
                userId: newUser.id,
                userRoles: { defaultClusterRole: newUser.defaultClusterRole, role: newUser.role },
              },
              { signal: canceler.signal },
            ),
        );
        setUpdatingRoles(false);
      } catch (e) {
        handleError(e, {
          publicSubject: 'Failed to update user',
        });
      }
    },
    [canceler.signal, fetchWithRetry, selectedOrg],
  );

  const handleSearchNameApply = useCallback(
    (newSearch: string) => {
      setNameFilter(newSearch);
      newSearch = newSearch.toLowerCase();
      const filtered = members.filter((member) => member.name.toLowerCase().includes(newSearch));
      setFilteredMembers(filtered);
    },
    [members],
  );

  useEffect(() => {
    handleSearchNameApply(nameFilter);
  }, [handleSearchNameApply, nameFilter]);

  const tableFilterSearchName = useCallback(
    (filterProps: FilterDropdownProps) => {
      const { close } = filterProps;
      return (
        <TableFilterSearch
          {...filterProps}
          value={nameFilter}
          onReset={() => {
            setNameFilter('');
            close();
          }}
          onSearch={(val) => setNameFilter(val)}
        />
      );
    },
    [nameFilter],
  );

  const updatedColumns = useMemo(() => {
    return columns.map((col) => {
      if (!selectedOrg) return col;
      switch (col.key) {
        case 'defaultClusterRole':
          col.render = (text: string, record: ModelOrgUserWithOverrides) => {
            return canModifyUser(record) ? (
              <RoleSelect
                availableRoles={availableClusterRoles}
                defaultValue={record.defaultClusterRole}
                disabled={updatingRoles || record.role === 'admin'}
                membersPageInfo={{ record, users: members }}
                onChange={(role) => updateUserRole({ ...record, defaultClusterRole: role }, false)}
              />
            ) : (
              <span>{record.defaultClusterRole}</span>
            );
          };
          break;
        case 'role':
          col.render = (text: string, record: ModelOrgUserWithOverrides) => {
            return canModifyUser(record) ? (
              <RoleSelect
                availableRoles={availableOrgRoles}
                defaultValue={record.role}
                disabled={updatingRoles}
                onChange={(role) => updateUserRole({ ...record, role }, true)}
              />
            ) : (
              <span>{record.role}</span>
            );
          };
          break;
        case 'actions':
          col.render = (text: string, record: ModelOrgUserWithOverrides) => {
            if (!canModifyUser(record)) return null;
            return (
              <MembersActionDropdown fetchUsers={fetchUsers} orgId={selectedOrg.id} user={record} />
            );
          };
          break;
        case 'name':
          (col.filterDropdown = tableFilterSearchName),
            (col.render = (_: string, record: ModelOrgUserWithOverrides): React.ReactNode => {
              return (
                <div className={css.user}>
                  <Avatar noColor text={record.name} />
                  <span>{record.name}</span>
                </div>
              );
            });
          break;
        case 'email':
          col.render = (_: string, record: ModelOrgUserWithOverrides): React.ReactNode => {
            return (
              <div className={css.user}>
                <span>{record.email}</span>
              </div>
            );
          };
          break;

        default:
          break;
      }
      return col;
    });
  }, [
    selectedOrg,
    canModifyUser,
    updateUserRole,
    fetchUsers,
    updatingRoles,
    members,
    tableFilterSearchName,
  ]);

  useEffect(() => {
    if (updatingRoles) return;
    fetchUsers();
  }, [fetchUsers, updatingRoles]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return (
    <ResponsiveTable<ModelOrgUserWithOverrides>
      className={css.pageTab}
      columns={updatedColumns}
      dataSource={nameFilter ? filteredMembers : members}
      loading={isLoading}
      pagination={{ hideOnSinglePage: true }}
      rowKey={(record) => record.id}
      scroll={{ x: 1000 }}
      showSorterTooltip={false}
      size="small"
    />
  );
};

export default MembersTabContent;

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { analyticsEvents, telemetryInstance } from 'analytics';
import Page from 'components/Page';
import ResponsiveTable from 'components/ResponsiveTable';
import { useStore } from 'contexts/Store';
import { useFetchSupportMatrix, useFetchWithRetry } from 'hooks/useFetch';
import { paths } from 'routes/utils';
import { fetchAllOrgs, updateOrgQuotas } from 'services/api';
import { ModelListOrgsEntry } from 'services/global-bindings/api';
import { notification } from 'utils/dialogApi';
import handleError, { ErrorLevel } from 'utils/error';
import { routeToReactUrl } from 'utils/routes';
import { getOrganizationIdentifier } from 'utils/saas';
import { alphaNumericSorter } from 'utils/sort';

import css from './CustomerOrgs.module.scss';

const InputCell = ({
  initialValue,
  orgId,
  onSave,
}: {
  initialValue: number;
  orgId: string;
  onSave: (selectedOrg: string, val: number) => Promise<void>;
}) => {
  const [value, setValue] = useState<number | null>(initialValue);
  const timer = useRef<NodeJS.Timeout>();

  const updateQuota = useCallback(
    (val: number | null) => {
      if (val !== null) {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        setValue(val);
        timer.current = setTimeout(() => onSave(orgId, val), 1000);
      }
    },
    [onSave, orgId],
  );

  const disable = <CloseOutlined onClick={() => updateQuota(-1)} />;

  const enable = <CheckOutlined onClick={() => updateQuota(1)} />;

  return value !== null && value > -1 ? (
    <InputNumber
      addonAfter={disable}
      defaultValue={initialValue}
      min={-1}
      value={value}
      variant={'outlined'}
      onChange={updateQuota}
    />
  ) : (
    <InputNumber
      addonAfter={enable}
      min={-1}
      placeholder={'disabled'}
      value={undefined}
      variant={'filled'}
      onChange={updateQuota}
    />
  );
};

const CustomerOrgs: React.FC = () => {
  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();
  const [canceler] = useState(() => new AbortController());
  const [isLoading, setIsLoading] = useState(false);
  const [orgs, setOrgs] = useState<ModelListOrgsEntry[]>([]);
  const fetchWithRetry = useFetchWithRetry(canceler);
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const getAllOrgs = useCallback(async () => {
    try {
      const orgs = await fetchWithRetry(
        async () => await fetchAllOrgs(undefined, { signal: canceler.signal }),
      );
      setOrgs(orgs);
    } catch (e) {
      handleError(e, {
        publicSubject: `Failed to fetch ${getOrganizationIdentifier()}`,
      });
    } finally {
      setIsLoading(false);
    }
  }, [canceler, fetchWithRetry]);

  const updateCpuQuota = useCallback(
    async (selectedOrg: string, cpuQuota: number) => {
      try {
        await fetchWithRetry(
          async () =>
            await updateOrgQuotas({
              orgId: selectedOrg,
              orgQuotas: { quotas: { cpuQuota: cpuQuota } },
            }),
        );
        notification.info({
          message: `${getOrganizationIdentifier()} CPU quota updated`,
        });
        telemetryInstance.track(analyticsEvents.orgQuotaUpdate, {
          orgId: selectedOrg,
          to: { cpuQuota: cpuQuota },
        });
      } catch (error) {
        handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: 'Failed to update org cpu quotas',
          silent: false,
        });
        return;
      }
    },
    [fetchWithRetry],
  );

  const updateGpuQuota = useCallback(
    async (selectedOrg: string, gpuQuota: number) => {
      try {
        await fetchWithRetry(
          async () =>
            await updateOrgQuotas({
              orgId: selectedOrg,
              orgQuotas: { quotas: { gpuQuota: gpuQuota } },
            }),
        );
        notification.info({
          message: `${getOrganizationIdentifier()} GPU quota updated`,
        });
        telemetryInstance.track(analyticsEvents.orgQuotaUpdate, {
          orgId: selectedOrg,
          to: { gpuQuota: gpuQuota },
        });
      } catch (error) {
        handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: 'Failed to update org gpu quotas',
          silent: false,
        });
        return;
      }
    },
    [fetchWithRetry],
  );

  const cpuQuotaRenderer = useCallback(
    (_: unknown, org: ModelListOrgsEntry) => {
      return (
        <InputCell
          initialValue={org.quotas.cpuQuota ?? -1}
          orgId={org.id}
          onSave={updateCpuQuota}
        />
      );
    },
    [updateCpuQuota],
  );

  const gpuQuotaRenderer = useCallback(
    (_: unknown, org: ModelListOrgsEntry) => {
      return (
        <InputCell
          initialValue={org.quotas.gpuQuota ?? -1}
          orgId={org.id}
          onSave={updateGpuQuota}
        />
      );
    },
    [updateGpuQuota],
  );

  const columns: ColumnType<ModelListOrgsEntry>[] = useMemo(
    () => [
      {
        dataIndex: 'id',
        defaultSortOrder: 'ascend',
        key: 'id',
        sorter: (a, b) => alphaNumericSorter(a.id, b.id),
        title: 'ID',
      },
      {
        dataIndex: 'name',
        defaultSortOrder: 'ascend',
        key: 'name',
        sorter: (a, b) => alphaNumericSorter(a.name, b.name),
        title: 'Name',
      },
      {
        dataIndex: 'ownerId',
        defaultSortOrder: 'ascend',
        key: 'ownerId',
        sorter: (a, b) => alphaNumericSorter(a.ownerId, b.ownerId),
        title: 'Owner',
      },
      {
        dataIndex: 'quotas.cpuQuota',
        defaultSortOrder: 'ascend',
        key: 'orgCpuQuotas',
        render: cpuQuotaRenderer,
        title: 'CPU Quota',
      },
      {
        dataIndex: 'quotas.gpuQuota',
        defaultSortOrder: 'ascend',
        key: 'orgGpuQuotas',
        render: gpuQuotaRenderer,
        title: 'GPU Quota',
      },
    ],
    [cpuQuotaRenderer, gpuQuotaRenderer],
  );

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [supportMatrix, fetchSupportMatrix]);

  useEffect(() => {
    // Since the current tab becomes hidden in the sidebar when the selected org changes to a non-super-admin-org,
    // we are redirecting the user to clusters page.
    if (!selectedOrg?.isSuperAdminOrg) {
      routeToReactUrl(paths.clusters());
      return;
    }

    getAllOrgs();
  }, [getAllOrgs, selectedOrg]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return (
    <Page title={`Customer ${getOrganizationIdentifier()}`}>
      <ResponsiveTable
        className={css.orgsTable}
        columns={supportMatrix?.orgQuotasEnabled ? columns : columns.slice(0, 3)}
        dataSource={orgs}
        loading={isLoading}
        pagination={{ hideOnSinglePage: true, pageSize: orgs.length }}
        scroll={{ x: 1000 }}
        showSorterTooltip={false}
        size="small"
      />
    </Page>
  );
};

export default CustomerOrgs;

import { Modal } from 'hew/Modal';
import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ResourcePools from 'components/ResourcePools';
import Spinner from 'components/Spinner';
import { useStore } from 'contexts/Store';
import useBackendProvider from 'hooks/useBackendProvider';
import { useFetchWithRetry } from 'hooks/useFetch';
import { ClusterDetails } from 'saasTypes';
import { byokClusterCreationPrecheck, getCluster, reconfigureCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { ModelByokSupportMatrix, ModelMasterConfig } from 'services/regional-bindings';
import { Backend } from 'types';
import handleError, { ErrorLevel } from 'utils/error';
import {
  generateByokMasterConfig,
  generateGkeMasterConfig,
  generateMasterConfig,
} from 'utils/saas';

import { GkeResourcePools } from './GkeCluster/GkeResourcePools';
import { ResourceInfoProvider } from './NewByokMldeClusterModal';
import { ByokResourcePools } from './NewByokMldeClusterModal/AdvancedConfig';
import css from './UpdateResourcePoolsModal.module.scss';

interface Props {
  onClose: () => void;
  cluster: GlobalApi.ModelClusterInfo;
  orgId: string;
}

export const _UpdateResourcePoolsModal: React.FC<Props> = ({ orgId, cluster, onClose }) => {
  const {
    orgState: { selectedOrg },
  } = useStore();
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [clusterDetails, setClusterDetails] = useState<ClusterDetails>();
  const backendProvider = useBackendProvider(canceler);

  const [masterConfig, setMasterConfig] = useState<ModelMasterConfig>({
    resource_manager: { default_aux_resource_pool: '', default_compute_resource_pool: '' },
    resource_pools: [],
  });

  const [availableNodes, setAvailableNodes] = useState<ModelByokSupportMatrix>({
    availableNodes: [],
  });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  useEffect(() => {
    const availableNodesPrecheck = async () => {
      if (selectedOrg?.id !== undefined && selectedOrg?.id !== '') {
        try {
          const { supportMatrix: byokSupportMatrix } = await byokClusterCreationPrecheck(
            {
              orgId: selectedOrg?.id || '',
              regionId: 'byok-us-west-2',
            },
            { signal: canceler.signal },
          );
          setAvailableNodes(byokSupportMatrix);
        } catch (e) {
          // tslint:disable:no-empty
        }
      }
    };

    availableNodesPrecheck();
  }, [canceler, selectedOrg?.id]);

  const reconfigureClusterRequest = useCallback(async () => {
    try {
      if (!masterConfig) return;
      await fetchWithRetry(
        async () =>
          await reconfigureCluster(
            {
              clusterId: cluster.id,
              config: {
                masterConfig: masterConfig,
              },
              orgId,
              regionId: cluster.location,
            },
            { signal: canceler.signal },
          ),
      );
    } catch (error) {
      throw handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to update',
        silent: false,
      });
    }
  }, [canceler.signal, cluster.id, cluster.location, fetchWithRetry, masterConfig, orgId]);

  const handleSubmit = useCallback(async () => {
    await reconfigureClusterRequest();
    onClose?.();
  }, [reconfigureClusterRequest, onClose]);

  const clusterHasUpdates = useMemo(
    () =>
      !!masterConfig &&
      masterConfig?.resource_manager.default_aux_resource_pool &&
      masterConfig?.resource_manager.default_compute_resource_pool &&
      masterConfig?.resource_pools.length > 0 &&
      (!clusterDetails?.masterConfig ||
        !isEqual(
          backendProvider === Backend.GKE
            ? generateGkeMasterConfig(
                clusterDetails.masterConfig.resource_pools,
                clusterDetails.masterConfig.resource_manager,
                false,
              )
            : backendProvider === Backend.BYOK8S
            ? generateByokMasterConfig(
                clusterDetails.masterConfig.resource_pools,
                clusterDetails.masterConfig.resource_manager,
                false,
              )
            : generateMasterConfig(
                clusterDetails.masterConfig.resource_pools,
                clusterDetails.masterConfig.resource_manager,
                false,
              ),
          masterConfig,
        )),
    [backendProvider, clusterDetails?.masterConfig, masterConfig],
  );

  const fetchClusterDetails = useCallback(async () => {
    try {
      const clusterDetailsRes = await fetchWithRetry(
        async () =>
          await getCluster(
            {
              clusterId: cluster.id,
              orgId,
              regionId: cluster.location,
            },
            { signal: canceler.signal },
          ),
      );
      setClusterDetails(clusterDetailsRes);
      setMasterConfig(
        generateByokMasterConfig(
          clusterDetailsRes.masterConfig.resource_pools,
          clusterDetailsRes.masterConfig.resource_manager,
          false,
        ),
      );
    } catch (e) {
      handleError(e, {
        publicSubject: 'Failed to fetch cluster details',
      });
    }
  }, [canceler.signal, cluster.id, cluster.location, fetchWithRetry, orgId]);

  useEffect(() => {
    fetchClusterDetails();
  }, [cluster.id, orgId, fetchClusterDetails]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const modalContent = useMemo(() => {
    return (
      <ByokResourcePools
        availableNodesIn={availableNodes.availableNodes.map((n) => n.name)}
        updateMasterConfig={setMasterConfig}
      />
    );
  }, [availableNodes, setMasterConfig]);

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="medium"
      submit={{
        disabled: !clusterHasUpdates || submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Update Resource Pools',
      }}
      title={`Update Resource Pools for ${cluster?.name}`}
      onClose={onClose}>
      {!clusterDetails ? (
        <Spinner tip="Loading cluster details" />
      ) : (
        <div className={css.modalContent}>
          {backendProvider === Backend.GKE ? (
            <GkeResourcePools cluster={clusterDetails} updateMasterConfig={setMasterConfig} />
          ) : backendProvider === Backend.BYOK8S ? (
            <ResourceInfoProvider
              availableNodes={availableNodes}
              canceler={canceler}
              cluster={cluster}
              isModalOpen={true}
              masterConfig={masterConfig}
              setSubmitDisabled={setSubmitDisabled}>
              {modalContent}
            </ResourceInfoProvider>
          ) : (
            <ResourcePools cluster={clusterDetails} updateMasterConfig={setMasterConfig} />
          )}
        </div>
      )}
    </Modal>
  );
};

import { PoolConfig, ResourcePoolUse } from 'saasTypes';

import { clusterDefaults } from './utils';

export const defaultPoolConfigs: PoolConfig[] = [
  {
    cpuSlotsAllowed: true,
    instanceType: clusterDefaults.AUX_INSTANCE_TYPE,
    key: clusterDefaults.DEFAULT_AUX_KEY,
    maxInstances: clusterDefaults.MAX_AUX_INSTANCES,
    poolName: clusterDefaults.CPU_POOL_NAME,
    primaryUse: ResourcePoolUse.Aux,
  },
  {
    acceleratorCount: clusterDefaults.COMPUTE_INSTANCE_TYPE.acceleratorCount,
    acceleratorType: clusterDefaults.COMPUTE_INSTANCE_TYPE.acceleratorType,
    cpuSlotsAllowed: false,
    instanceType: clusterDefaults.COMPUTE_INSTANCE_TYPE.type,
    key: clusterDefaults.DEFAULT_COMPUTE_KEY,
    maxInstances: clusterDefaults.MAX_COMPUTE_INSTANCES,
    poolName: clusterDefaults.GPU_POOL_NAME,
    primaryUse: ResourcePoolUse.Compute,
  },
];

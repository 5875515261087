import { Card, Checkbox } from 'antd';
import Button from 'hew/Button';
import Form from 'hew/Form';
import Input from 'hew/Input';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useTelemetry, { analyticsEvents } from 'analytics';
import Link from 'components/Link';
import useUI from 'components/UIManager';
import { trialRequest } from 'services/api';
import { ModelTrialSignUpRequest } from 'services/global-bindings';
import handleError from 'utils/error';
import { getOrganizationIdentifier } from 'utils/saas';

import css from './TrialLanding.module.scss';

enum TrialRequest {
  Init = 0,
  Submitting = 1,
  Submitted = 2,
}

const TrialLanding: React.FC = () => {
  const [canceler] = useState(() => new AbortController());
  const [trialRequestProgress, setTrialRequestProgress] = useState<TrialRequest>(TrialRequest.Init);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [trialSignupForm] = Form.useForm<ModelTrialSignUpRequest>();
  const trialSignupValues = Form.useWatch<ModelTrialSignUpRequest>([], trialSignupForm);
  const { actions: uiActions } = useUI();
  // This ref ensures that we only send the tracking event once in useEffect
  const trackingSent = useRef(false);
  const { track, trackPage } = useTelemetry();

  useEffect(() => {
    uiActions.hideChrome();
    return uiActions.showChrome;
  }, [uiActions]);

  useEffect(() => {
    trialSignupForm
      .validateFields({ validateOnly: true })
      .then(() => setIsSubmitEnabled(agreementChecked))
      .catch(() => setIsSubmitEnabled(false));
  }, [agreementChecked, trialSignupForm, trialSignupValues]);

  useEffect(() => {
    if (!trackingSent.current) {
      trackPage();
      trackingSent.current = true;
    }
  }, [trackPage]);

  const trialSignup = useCallback(async () => {
    setTrialRequestProgress(TrialRequest.Submitting);
    try {
      await trialRequest(trialSignupValues, { signal: canceler.signal });
      setTrialRequestProgress(TrialRequest.Submitted);
      track(analyticsEvents.trialRequest, {
        trialSignupValues,
      });
    } catch (error) {
      setTrialRequestProgress(TrialRequest.Init);
      handleError(error, {
        publicSubject: 'Error Submitting Trial Request',
        silent: false,
      });
    }
  }, [canceler.signal, track, trialSignupValues]);

  return (
    <div className={css.base}>
      <div className={css.signupText}>
        <h1>HPE Machine Learning Development Environment Free Trial</h1>
        <h2>Overview:</h2>
        <p>
          Connect with an HPE expert to explore all of the features of the HPE Machine Learning
          Development Environment with multiple deployment options through a 90-day trial.
        </p>
        <h2>Key Features:</h2>
        <ul>
          <li>Train your models faster with distributed training</li>
          <li>
            Evaluate and customize self-hosted LLM models. Make prompt engineering and fine-tuning
            easy, secure, and explainable
          </li>
          <li>Eliminate operations hassle like setup, upgrades, and backup</li>
          <li>Configure and manage clusters effortlessly</li>
          <li>Deploy on-prem or in the cloud</li>
        </ul>
      </div>
      <div className={css.signupFormWrapper}>
        <Card className={css.card}>
          <p className={css.cardTitle}>Register to learn more</p>
          <Form
            className={css.signupForm}
            form={trialSignupForm}
            labelCol={{ span: 24 }}
            layout="vertical"
            wrapperCol={{ span: 24 }}
            onFinish={trialSignup}>
            <Form.Item label="Email">
              <div>
                <Form.Item
                  name="email"
                  noStyle
                  rules={[
                    {
                      message: 'Invalid email',
                      required: true,
                      type: 'email',
                    },
                  ]}>
                  <Input className={css.input} placeholder={'me@example.com'} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label="First Name">
              <div>
                <Form.Item
                  name="firstName"
                  noStyle
                  rules={[
                    {
                      message: 'This is a required field.',
                      required: true,
                    },
                  ]}>
                  <Input className={css.input} placeholder={'First Name'} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label="Last Name">
              <div>
                <Form.Item
                  name="lastName"
                  noStyle
                  rules={[
                    {
                      message: 'This is a required field.',
                      required: true,
                    },
                  ]}>
                  <Input className={css.input} placeholder={'Last Name'} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label={getOrganizationIdentifier()}>
              <div>
                <Form.Item
                  name="company"
                  noStyle
                  rules={[
                    {
                      message: 'This is a required field.',
                      required: true,
                    },
                  ]}>
                  <Input className={css.input} placeholder={getOrganizationIdentifier()} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item>
              <Checkbox onChange={(event) => setAgreementChecked(event.target.checked)}>
                <div className={css.trialText}>
                  I accept the{' '}
                  <Link path={'https://www.hpe.com/us/en/about/legal/terms-of-use.html'}>
                    HPE terms
                  </Link>{' '}
                  of use and{' '}
                  <Link path={'https://www.hpe.com/us/en/legal/privacy.html'}>privacy policy</Link>
                </div>
              </Checkbox>
            </Form.Item>
            <div>
              {trialRequestProgress === TrialRequest.Submitted ? (
                <p>Thanks, we&apos;ll be in touch soon!</p>
              ) : (
                <Form.Item>
                  <div className={css.submit}>
                    <Button
                      disabled={
                        !isSubmitEnabled || trialRequestProgress === TrialRequest.Submitting
                      }
                      htmlType="submit"
                      type="primary">
                      Sign Up
                    </Button>
                  </div>
                </Form.Item>
              )}
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default TrialLanding;

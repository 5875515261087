import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Dropdown from 'hew/Dropdown';
import Tooltip from 'hew/Tooltip';
import useConfirm from 'hew/useConfirm';
import React, { useCallback, useMemo, useState } from 'react';

import { useFetchWithRetry } from 'hooks/useFetch';
import * as GlobalApi from 'services/global-bindings';
import { MenuOptionWithOnClick } from 'types';
import handleError from 'utils/error';

import Empty from './Empty';
import css from './InvitedUsers.module.scss';
import { columns } from './InvitedUsers.table';
import { selectedOrg } from './OrgPicker/utils';
import ResponsiveTable from './ResponsiveTable';
import { actionsButton } from './Table';

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

enum Action {
  CancelInvite = 'Cancel',
}

interface Props {
  invitedUsers: GlobalApi.ModelInvitedUser[];
  fetchInvites: () => Promise<void>;
  isLoading: boolean;
  onCancelInvite: (inviteId: string) => Promise<void>;
  className: string;
}

const InvitedUsers: React.FC<Props> = ({
  invitedUsers,
  isLoading,
  onCancelInvite,
  className,
  fetchInvites,
}) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const confirm = useConfirm();
  const onCancelOrgInvite = useCallback(
    async (inviteId: string) => {
      if (!selectedOrg) return;
      await fetchWithRetry(async () => await onCancelInvite(inviteId));
    },
    [fetchWithRetry, onCancelInvite],
  );

  const dropdownMenuItemsForInvitation = useCallback(
    (invitedUser: GlobalApi.ModelInvitedUser): MenuOptionWithOnClick[] => {
      return [
        {
          key: Action.CancelInvite,
          label: Action.CancelInvite,
          onClick: () => {
            confirm({
              content: `Are you sure you want to cancel this invite for ${invitedUser.email}?`,
              onConfirm: async () => {
                await onCancelOrgInvite(invitedUser.id);
                fetchInvites();
              },
              onError: (err) => {
                handleError(err, {
                  level: 'error',
                  publicSubject: 'Failed to cancel invite.',
                  silent: false,
                });
              },
              title: 'Cancel',
            });
          },
        },
      ];
    },
    [fetchInvites, onCancelOrgInvite, confirm],
  );

  const columnRenders = useMemo(() => {
    return columns.map((col) => {
      if (!selectedOrg || !invitedUsers) return col;
      switch (col.key) {
        case 'role': {
          col.render = (_, record) => (
            <div className={css.user}>
              <span>{record.role}</span>
            </div>
          );
          break;
        }
        case 'defaultClusterRole': {
          col.render = (_, record) => (
            <div className={css.user}>
              <span>{record.defaultClusterRole}</span>
            </div>
          );
          break;
        }
        case 'email': {
          col.render = (_, record) => (
            <div className={css.user}>
              <span>{record.email}</span>
            </div>
          );
          break;
        }
        case 'expiry': {
          col.render = (_, record) => (
            <Tooltip
              content={dayjs(record.expiry).calendar(null, { sameElse: 'LLL z' })}
              placement="bottom">
              <span>{dayjs(record.expiry).fromNow()}</span>
            </Tooltip>
          );
          break;
        }
        case 'actions': {
          col.render = (_, record) => (
            <Dropdown menu={dropdownMenuItemsForInvitation(record)}>{actionsButton}</Dropdown>
          );
          break;
        }
        default:
          break;
      }
      return col;
    });
  }, [dropdownMenuItemsForInvitation, invitedUsers]);

  if (!invitedUsers || invitedUsers.length === 0)
    return (
      <div className={css.emptyInvites}>
        <Empty description="No Pending Invites" />
      </div>
    );
  return (
    <ResponsiveTable<GlobalApi.ModelInvitedUser>
      className={className}
      columns={columnRenders}
      dataSource={invitedUsers}
      loading={isLoading}
      pagination={{ hideOnSinglePage: true }}
      rowKey={(record) => record.id}
      scroll={{ x: 1000 }}
      showSorterTooltip={true}
      size="small"
    />
  );
};

export default InvitedUsers;

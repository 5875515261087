import React from 'react';

import { BasicConfigOption } from 'saasTypes';

import css from './NewAwsMldmClusterModal.module.scss';

interface BasicConfigSelectProps {
  onSelect: (option: BasicConfigOption) => void;
  selected: BasicConfigOption;
}

const BasicConfigSelect: React.FC<BasicConfigSelectProps> = ({
  selected,
  onSelect,
}: BasicConfigSelectProps) => {
  const standardClasses = [css.card];
  if (selected === BasicConfigOption.Standard) {
    standardClasses.push(css.selected);
  }

  return (
    <div className={css.cards}>
      <div
        className={standardClasses.join(' ')}
        onClick={() => {
          onSelect(BasicConfigOption.Standard);
        }}>
        <h5>Standard</h5>
        <p>Database</p>
        <p className={css.value}>db.m5.large (2 CPU, 8GB RAM) & 100 GB SSD</p>
        <p>Instance Type</p>
        <p className={css.value}>m5.2xlarge (8 CPU, 32GB RAM)</p>
        <p>Autoscaling</p>
        <p className={css.value}>c5.4xlarge (16 CPU, 32GB RAM)</p>
        <p className={css.value}>1-4 instances</p>
      </div>
    </div>
  );
};

export default BasicConfigSelect;

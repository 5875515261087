import { useCallback, useEffect, useState } from 'react';

import { analyticsEvents, telemetryInstance } from 'analytics';
import { useAuth } from 'contexts/Auth';
import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { throwCreateClusterError } from 'pages/Clusters/cluster-util';
import { paths } from 'routes/utils';
import {
  createCluster,
  fetchOrgQuotas,
  fetchOrgs,
  getLicensingStatus,
  getOrgBackendProviders,
  getSupportMatrix,
  isAuthFailure,
} from 'services/api';
import {
  ModelGetSupportMatrixResponse,
  ModelListOrgsEntry,
  ModelUserLicensingStatusResponse,
} from 'services/global-bindings';
import { ModelCreateClusterRequest } from 'services/regional-bindings';
import { isEqual } from 'utils/data';
import handleError, { DetError } from 'utils/error';
import { routeToReactUrl } from 'utils/routes';
import { getOrganizationIdentifier } from 'utils/saas';

export const useFetchOrgQuotas = (canceler: AbortController): (() => Promise<void>) => {
  const storeDispatch = useStoreDispatch();
  const { selectedOrg } = useStore().orgState;
  const fetchWithRetry = useFetchWithRetry(canceler);

  return useCallback(async () => {
    if (!selectedOrg) return;

    try {
      const orgQuotas = await fetchWithRetry(
        async () => await fetchOrgQuotas({ orgId: selectedOrg.id }, { signal: canceler.signal }),
      );

      storeDispatch({ type: StoreAction.SetOrgQuotas, value: orgQuotas || {} });
    } catch (e) {
      handleError(e, {
        publicSubject: 'Failed to fetch org quotas',
      });
    }
  }, [canceler.signal, fetchWithRetry, selectedOrg, storeDispatch]);
};

export const useFetchOrgBackendProviders = (canceler: AbortController): (() => Promise<void>) => {
  const storeDispatch = useStoreDispatch();
  const { selectedOrg } = useStore().orgState;
  const fetchWithRetry = useFetchWithRetry(canceler);

  return useCallback(async () => {
    if (!selectedOrg) return;

    try {
      const backendProviders = await fetchWithRetry(
        async () =>
          await getOrgBackendProviders({ orgId: selectedOrg.id }, { signal: canceler.signal }),
      );

      storeDispatch({ type: StoreAction.SetOrgBackendProviders, value: backendProviders });
    } catch (e) {
      handleError(e, {
        publicSubject: 'Failed to fetch backend providers',
      });
    }
  }, [canceler.signal, fetchWithRetry, selectedOrg, storeDispatch]);
};

export const useFetchOrgs = (
  canceler: AbortController,
): (() => Promise<ModelListOrgsEntry[] | void>) => {
  const storeDispatch = useStoreDispatch();
  const { roles } = useUser();
  const fetchWithRetry = useFetchWithRetry(canceler);
  const {
    orgState: { selectedOrg },
  } = useStore();

  return useCallback(async () => {
    try {
      const orgsResponse = await fetchWithRetry(
        async () => await fetchOrgs(undefined, { signal: canceler.signal }),
      );

      const orgs = orgsResponse.orgs;
      const orgRolesFromJwt = new Set(Object.keys(roles));
      const orgsFromApi = new Set(orgs.map((o) => o.id));
      if (!isEqual(orgRolesFromJwt, orgsFromApi)) {
        // if the org list between the jwt and api aren't matching up something isn't right
        // log the user back in so the jwt can get in sync
        routeToReactUrl(
          `${paths.login()}?refresh=true${selectedOrg ? `&orgId=${selectedOrg.id}` : ''}`,
        );
      }
      storeDispatch({ type: StoreAction.SetNextOrgGuid, value: orgsResponse.nextOrgGuid });
      storeDispatch({ type: StoreAction.SetOrgs, value: orgs });
      return orgs;
    } catch (e) {
      handleError(e, {
        publicSubject: `Failed to fetch ${getOrganizationIdentifier()}s`,
      });
    }
  }, [fetchWithRetry, roles, storeDispatch, canceler.signal, selectedOrg]);
};

export const useFetchSupportMatrix = (
  canceler: AbortController,
): (() => Promise<ModelGetSupportMatrixResponse | void>) => {
  const fetchWithRetry = useFetchWithRetry(canceler);
  const storeDispatch = useStoreDispatch();

  return useCallback(async () => {
    try {
      const supportMatrix = await fetchWithRetry(
        async () => await getSupportMatrix(undefined, { signal: canceler.signal }),
      );
      storeDispatch({ type: StoreAction.SetSupportMatrix, value: supportMatrix });
      return supportMatrix;
    } catch (e) {
      handleError(e, {
        publicSubject: 'Failed to fetch support matrix',
      });
    }
  }, [canceler.signal, fetchWithRetry, storeDispatch]);
};

export const useFetchLicenseStatus = (): ((
  userId: string,
) => Promise<ModelUserLicensingStatusResponse>) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  return useCallback(
    async (userId: string) => {
      const licensingStatus = await fetchWithRetry(
        async () => await getLicensingStatus({ userId: userId }, { signal: canceler.signal }),
      );
      return licensingStatus;
    },
    [canceler, fetchWithRetry],
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFetchWithRetry = (canceler: AbortController) => {
  const { doRefreshToken } = useAuth();
  return useCallback(
    async <T>(fn: () => Promise<T>) => {
      try {
        return await fn();
      } catch (e) {
        if (isAuthFailure(e)) {
          try {
            await doRefreshToken(canceler);
            return await fn();
          } catch (e) {
            // Redirect to logout if Auth failure detected (auth token is no longer valid).
            if (isAuthFailure(e)) {
              const path = window.location.pathname;
              if (!path.includes(paths.login()) && !path.includes(paths.logout())) {
                routeToReactUrl(paths.logout());
              }
            }

            throw new DetError(undefined, {
              publicMessage: 'Redirecting to user login',
              silent: true,
            });
          }
        } else {
          throw e;
        }
      }
    },
    [canceler, doRefreshToken],
  );
};

export const useMakeClusterCreateCall = (
  canceler: AbortController,
): ((
  clusterRegion: string,
  clusterRequest: ModelCreateClusterRequest,
  onFinish: () => Promise<void>,
) => Promise<void>) => {
  const {
    orgState: { selectedOrg },
  } = useStore();

  const fetchWithRetry = useFetchWithRetry(canceler);

  return useCallback(
    async (clusterRegion, clusterRequest, onFinish) => {
      try {
        telemetryInstance.track(analyticsEvents.clusterCreationInitiated, {
          clusterRegion,
          clusterRequest,
        });
        await fetchWithRetry(
          async () =>
            await createCluster(
              {
                cluster: clusterRequest,
                orgId: selectedOrg?.id ?? '',
                regionId: clusterRegion,
              },
              { signal: canceler.signal },
            ),
        );
      } catch (error) {
        throwCreateClusterError('Failed to create cluster');
      }
      await onFinish();
    },
    [canceler, selectedOrg, fetchWithRetry],
  );
};

import useResize from 'hew/internal/useResize';
import React from 'react';

import { paths } from 'routes/utils';

import Link from './Link';
import css from './NavigationHeader.module.scss';
import { OrgPickerStateful } from './OrgPicker';

interface NavigationHeaderProps {
  titleOnly?: boolean;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({ titleOnly }) => {
  const resize = useResize();
  const minExpandedWidth = 620;
  return (
    <div className={css.base}>
      <h1>
        <Link className={css.title} path={paths.clusters()}>
          {resize.size.width >= minExpandedWidth
            ? 'Machine Learning Development Environment Software'
            : 'MLDES'}
        </Link>
      </h1>
      {!titleOnly ? (
        <div className={css.options}>
          <OrgPickerStateful />
        </div>
      ) : null}
    </div>
  );
};

export default NavigationHeader;

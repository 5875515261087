import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Row } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { FormInstance } from 'antd/lib/form/Form';
import Button from 'hew/Button';
import Form from 'hew/Form';
import Input from 'hew/Input';
import Select, { Option, SelectValue } from 'hew/Select';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import VersionPicker from 'components/VersionPicker';
import { useStore } from 'contexts/Store';
import { useFetchSupportMatrix } from 'hooks/useFetch';
import { ClusterDetails, OverallConfig } from 'saasTypes';
import { byokClusterCreationPrecheck } from 'services/api';
import {
  ModelAuxiliaryResources,
  ModelByokSupportMatrix,
  ModelMasterConfig,
} from 'services/regional-bindings';
import { SemanticVersion } from 'types';
import { stringToVersion, versionToString } from 'utils/string';

import css from './NewByokMldeClusterModal.module.scss';
import { k8sAnnotationAndLabelKeyValidator, k8sLabelValueValidator, KeyValuePair } from './utils';

import { ResourceContext } from '.';

interface AdvancedConfigFormProps {
  onUpdateOverallConfig: React.Dispatch<React.SetStateAction<OverallConfig>>;
  overallConfig: OverallConfig;
  updateMasterConfig: React.Dispatch<React.SetStateAction<ModelMasterConfig>>;
  form: FormInstance;
  supportMatrix: ModelByokSupportMatrix;
  supportedDetVersions: string[];
  onUpdateAuxiliaryConfig: React.Dispatch<React.SetStateAction<ModelAuxiliaryResources>>;
  detMasterSvcAnnotations: KeyValuePair[];
  onUpdateDetMasterSvcAnnotations: React.Dispatch<React.SetStateAction<KeyValuePair[]>>;
  detMasterSvcLabels: KeyValuePair[];
  onUpdateDetMasterSvcLabels: React.Dispatch<React.SetStateAction<KeyValuePair[]>>;
}

const AdvancedConfigForm: React.FC<AdvancedConfigFormProps> = ({
  overallConfig,
  onUpdateOverallConfig,
  updateMasterConfig,
  supportedDetVersions,
  supportMatrix,
  onUpdateAuxiliaryConfig,
  detMasterSvcAnnotations,
  onUpdateDetMasterSvcAnnotations,
  detMasterSvcLabels,
  onUpdateDetMasterSvcLabels,
}) => {
  const [useNodePortForMaster, setUseNodePortForMaster] = useState(false);
  const selectUseNodePortForMaster: CheckboxProps['onChange'] = (e) => {
    setUseNodePortForMaster(e.target.checked);
    onUpdateAuxiliaryConfig((prev) => ({
      ...prev,
      useNodePortForMaster: e.target.checked,
    }));
  };
  const [dbStorageClass, setDbStorageClass] = useState('');

  const updateVersion = useCallback(
    (ver: SemanticVersion) => {
      onUpdateOverallConfig({ ...overallConfig, detVersion: versionToString(ver, false) });
    },
    [onUpdateOverallConfig, overallConfig],
  );

  const selectMasterNode = useCallback(
    (instanceType: string) => {
      onUpdateOverallConfig({
        ...overallConfig,
        ...{ masterInstanceType: instanceType },
      });
    },
    [onUpdateOverallConfig, overallConfig],
  );

  return (
    <>
      <Form.Item label="MLDE Version" name="mldeVersion">
        <VersionPicker
          versions={supportedDetVersions.map((v) => stringToVersion(v))}
          onChange={updateVersion}
        />
      </Form.Item>
      <Form.Item
        label={
          <>
            <span>MLDE Master Node</span>
          </>
        }
        labelCol={{ span: 24 }}
        name="masterInstanceType">
        <SingleByokNodeDropdown
          availableNodes={supportMatrix.availableNodes.map((an) => an.name)}
          buttonLabel={overallConfig.masterInstanceType}
          id="masterInstanceType"
          onSelect={selectMasterNode}
        />
      </Form.Item>
      <Divider />
      <h2 className={css.sectionHeader}>Resource Pools</h2>
      <ByokResourcePools
        availableNodesIn={supportMatrix.availableNodes
          .filter((n) =>
            Object.entries(n.labels).every(
              ([key, val]) => !(key.startsWith('cluster-byok') && val === 'true'),
            ),
          )
          .map((n) => n.name)}
        updateMasterConfig={updateMasterConfig}
      />
      <Divider />
      <h2 className={css.sectionHeader}>Auxiliary Resources</h2>
      <Form.Item label="Database Storage Class" name="dbStorageClass">
        <Input
          value={dbStorageClass}
          onChange={(e) => {
            setDbStorageClass(e.target.value);
            onUpdateAuxiliaryConfig((prev) => ({
              ...prev,
              dbStorageClass: e.target.value,
            }));
          }}
        />
      </Form.Item>
      <Form.Item>
        <Checkbox checked={useNodePortForMaster} onChange={selectUseNodePortForMaster}>
          Use NodePort for Master Deployment
        </Checkbox>
      </Form.Item>
      <Form.Item className={css.annotations}>
        <h3 className={css.header}>
          Annotations
          <Button
            onClick={() => {
              onUpdateDetMasterSvcAnnotations((prev) => [...prev, { key: '', value: '' }]);
            }}>
            Add
          </Button>
        </h3>
        {detMasterSvcAnnotations.map((annotation, idx) => (
          <Row className={css.row} key={idx}>
            <Form.Item
              className={css.keyColumn}
              label="Key"
              name={`annotation-key-${idx}`}
              rules={[
                {
                  message: 'Key required',
                  required: true,
                },
                {
                  validator: (_, key) =>
                    k8sAnnotationAndLabelKeyValidator(key, idx, detMasterSvcAnnotations),
                },
              ]}>
              <Input
                value={annotation['key']}
                onChange={(e) => {
                  const tempAnnotations = detMasterSvcAnnotations.map((item, currIdx) =>
                    currIdx !== idx ? item : { key: e.target.value, value: item['value'] },
                  );
                  onUpdateDetMasterSvcAnnotations(tempAnnotations);
                }}
              />
            </Form.Item>
            <Form.Item className={css.valueColumn} label="Value" name={`annotation-value-${idx}`}>
              <Input
                value={annotation['value']}
                onChange={(e) => {
                  const tempAnnotations = detMasterSvcAnnotations.map((item, currIdx) =>
                    currIdx !== idx ? item : { key: item['key'], value: e.target.value },
                  );
                  onUpdateDetMasterSvcAnnotations(tempAnnotations);
                }}
              />
            </Form.Item>
            <Form.Item className={css.deleteColumn}>
              <Button
                onClick={() => {
                  const tempAnnotations = detMasterSvcAnnotations.filter(
                    (_, currIdx) => currIdx !== idx,
                  );
                  onUpdateDetMasterSvcAnnotations(tempAnnotations);
                }}>
                <CloseOutlined />
              </Button>
            </Form.Item>
          </Row>
        ))}
      </Form.Item>
      <Form.Item className={css.labels}>
        <h3 className={css.header}>
          Labels
          <Button
            onClick={() => {
              onUpdateDetMasterSvcLabels((prev) => [...prev, { key: '', value: '' }]);
            }}>
            Add
          </Button>
        </h3>
        {detMasterSvcLabels.map((label, idx) => (
          <Row className={css.row} key={idx}>
            <Form.Item
              className={css.keyColumn}
              label="Key"
              name={`label-key-${idx}`}
              rules={[
                {
                  message: 'Key required',
                  required: true,
                },
                {
                  validator: (_, key) =>
                    k8sAnnotationAndLabelKeyValidator(key, idx, detMasterSvcLabels),
                },
              ]}>
              <Input
                value={label['key']}
                onChange={(e) => {
                  const tempLabels = detMasterSvcLabels.map((item, currIdx) =>
                    currIdx !== idx ? item : { key: e.target.value, value: item['value'] },
                  );
                  onUpdateDetMasterSvcLabels(tempLabels);
                }}
              />
            </Form.Item>
            <Form.Item
              className={css.valueColumn}
              label="Value"
              name={`label-value-${idx}`}
              rules={[
                {
                  validator: (_, val) => k8sLabelValueValidator(val),
                },
              ]}>
              <Input
                value={label['value']}
                onChange={(e) => {
                  const tempLabels = detMasterSvcLabels.map((item, currIdx) =>
                    currIdx !== idx ? item : { key: item['key'], value: e.target.value },
                  );
                  onUpdateDetMasterSvcLabels(tempLabels);
                }}
              />
            </Form.Item>
            <Form.Item className={css.deleteColumn}>
              <Button
                onClick={() => {
                  const tempLabels = detMasterSvcLabels.filter((_, currIdx) => currIdx !== idx);
                  onUpdateDetMasterSvcLabels(tempLabels);
                }}>
                <CloseOutlined />
              </Button>
            </Form.Item>
          </Row>
        ))}
      </Form.Item>
    </>
  );
};

interface ByokNodeDropdownProps {
  buttonLabel?: string;
  id?: string;
  onSelect: (instanceType: string) => void;
  mode?: 'multiple' | 'tags';
  availableNodes: string[];
}

const SingleByokNodeDropdown: React.FC<ByokNodeDropdownProps> = ({
  buttonLabel,
  id,
  onSelect,
  availableNodes,
}) => {
  const options = useMemo(() => {
    return availableNodes.map((n) => {
      return (
        <Option key={n} value={n}>
          {n}
        </Option>
      );
    });
  }, [availableNodes]);

  return (
    <Select
      attachDropdownToContainer={true}
      id={id}
      value={buttonLabel}
      onChange={(v) => onSelect(v as string)}>
      {options}
    </Select>
  );
};

interface ByokResourcePoolsProps {
  cluster?: ClusterDetails;
  updateMasterConfig: Dispatch<SetStateAction<ModelMasterConfig>>;
  availableNodesIn?: string[];
  checkNodes?: boolean;
}

export const ByokResourcePools: React.FC<ByokResourcePoolsProps> = ({
  cluster,
  updateMasterConfig,
  availableNodesIn,
  checkNodes = false,
}) => {
  const [canceler] = useState(() => new AbortController());
  const [currNodes, setCurrNodes] = useState<string[]>([]);
  const [defaultNodes, setDefaultNodes] = useState<{ compute: string; aux: string }>({
    aux: '',
    compute: '',
  });
  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const [availableNodes, setAvailableNodes] = useState<string[]>(() => availableNodesIn ?? []);

  const resourceInfo = useContext(ResourceContext);

  const nodesHaveCpuCap = currNodes.some((n) => !!resourceInfo?.nodeCapacities[n].cpuCount);
  const nodesHaveGpuCap = currNodes.some((n) => !!resourceInfo?.nodeCapacities[n].gpuCount);

  const displayCpuErr =
    nodesHaveCpuCap && !!resourceInfo?.cpuExceedsQuota && !!supportMatrix?.orgQuotasEnabled;
  const displayGpuErr =
    nodesHaveGpuCap && !!resourceInfo?.gpuExceedsQuota && !!supportMatrix?.orgQuotasEnabled;

  const errMsg = displayCpuErr
    ? resourceInfo?.cpuExceedsQuota
    : displayGpuErr
    ? resourceInfo?.gpuExceedsQuota
    : '';

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [supportMatrix, fetchSupportMatrix]);

  useEffect(() => {
    if (!checkNodes) {
      setAvailableNodes(availableNodesIn ?? []);
    } else {
      const availableNodesPrecheck = async () => {
        if (selectedOrg?.id !== undefined && selectedOrg?.id !== '') {
          try {
            const { supportMatrix: byokSupportMatrix } = await byokClusterCreationPrecheck(
              {
                orgId: selectedOrg?.id || '',
                regionId: 'byok-us-west-2',
              },
              { signal: canceler.signal },
            );
            setAvailableNodes(
              byokSupportMatrix.availableNodes
                .filter((n) =>
                  Object.entries(n.labels).every(
                    ([key, val]) =>
                      !(key.startsWith('cluster-byok') && key !== cluster?.id && val === 'true'),
                  ),
                )
                .map((n) => n.name),
            );
          } catch (e) {
            // tslint:disable:no-empty
          }
        }
      };

      if (checkNodes) {
        availableNodesPrecheck();
      }

      return () => {
        canceler.abort();
      };
    }
  }, [checkNodes, availableNodesIn, setAvailableNodes, selectedOrg?.id, canceler, cluster?.id]);

  const options = useMemo(() => {
    return availableNodes.map((n) => {
      return (
        <Option key={n} value={n}>
          {n}
        </Option>
      );
    });
  }, [availableNodes]);

  const defaultPoolOptions = useMemo(() => {
    return currNodes.map((n) => {
      return (
        <Option key={n} value={n}>
          {n}
        </Option>
      );
    });
  }, [currNodes]);

  const makeSelection = (v: SelectValue) => {
    updateMasterConfig((prev) => ({
      ...prev,
      resource_pools: (v as string[]).map((node) => {
        return {
          max_aux_containers_per_agent: 100,
          pool_name: node,
          provider: {
            cpu_slots_allowed: true,
            instance_type: {
              machine_type: '',
            },
            max_instances: 1,
            type: 'byok',
          },
        };
      }),
    }));
    setCurrNodes(v as string[]);
    const workingNodes = v as string[];
    if (workingNodes.length === 0) {
      selectDefaultAuxNode('');
      selectDefaultComputeNode('');
    } else {
      if (workingNodes.find((n) => n === defaultNodes.aux) === undefined) {
        selectDefaultAuxNode('');
      }
      if (workingNodes.find((n) => n === defaultNodes.compute) === undefined) {
        selectDefaultComputeNode('');
      }
    }
  };

  const selectDefaultComputeNode = (v: SelectValue) => {
    updateMasterConfig((prev) => ({
      ...prev,
      resource_manager: {
        ...prev.resource_manager,
        default_compute_resource_pool: v as string,
      },
    }));
    setDefaultNodes((prev) => ({ ...prev, compute: v as string }));
  };

  const selectDefaultAuxNode = (v: SelectValue) => {
    updateMasterConfig((prev) => ({
      ...prev,
      resource_manager: {
        ...prev.resource_manager,
        default_aux_resource_pool: v as string,
      },
    }));
    setDefaultNodes((prev) => ({ ...prev, aux: v as string }));
  };

  return (
    <>
      <Form.Item
        label={<span>Worker Nodes</span>}
        validateMessage={errMsg}
        validateStatus={errMsg ? 'error' : undefined}>
        <span id="" style={{ position: 'relative' }}>
          <Select
            attachDropdownToContainer={true}
            id="resource-pools"
            mode={'multiple'}
            value={currNodes}
            onChange={makeSelection}>
            {options}
          </Select>
        </span>
      </Form.Item>
      <Form.Item label={<span>Default Compute Node</span>}>
        <Select
          attachDropdownToContainer={true}
          id="compute-resource-pool"
          value={defaultNodes.compute}
          onChange={selectDefaultComputeNode}>
          {defaultPoolOptions}
        </Select>
      </Form.Item>
      <Form.Item label={<span>Default Aux Node</span>}>
        <Select
          attachDropdownToContainer={true}
          id="aux-resource-pool"
          value={defaultNodes.aux}
          onChange={selectDefaultAuxNode}>
          {defaultPoolOptions}
        </Select>
      </Form.Item>
    </>
  );
};

export default AdvancedConfigForm;

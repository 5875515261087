import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Spinner from 'components/Spinner';
import useUI from 'components/UIManager';
import { globalStorage } from 'globalStorage';
import { externalLoginAddress, routeAll } from 'routes/utils';
import { isMspDeployment } from 'utils/saas';
const SignIn: React.FC = () => {
  const { actions: uiActions } = useUI();
  const location = useLocation();

  useEffect(() => {
    globalStorage.currentAuthAttempt += 1;
    const authUrl = `${externalLoginAddress()}${location.search}`;
    routeAll(authUrl);
  }, [location]);

  useEffect(() => {
    uiActions.hideChrome();
    return uiActions.showChrome;
  }, [uiActions]);

  return (
    <Spinner
      center
      style={{ height: 'calc(var(--vh, 1vh) * 100)', width: '100%' }}
      tip={`Welcome to ${
        !isMspDeployment() ? 'HPE ' : ''
      }Machine Learning Development Environment Software. Your access is being verified...`}>
      <div />
    </Spinner>
  );
};

export default SignIn;

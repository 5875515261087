import React, { Dispatch, useContext, useReducer } from 'react';

import { UIManager } from 'components/UIManager';
import { RegionalClusters } from 'experimental/notifications/api';
import { globalStorage } from 'globalStorage';
import * as GlobalApi from 'services/global-bindings';
import { ModelClusterInfo } from 'services/regional-bindings';
import { ApiState } from 'types';
import { clone, isEqual } from 'utils/data';
import handleError from 'utils/error';
import { getOrganizationIdentifier } from 'utils/saas';

interface Props {
  children?: React.ReactNode;
}

export interface State {
  displayTrialBanner: boolean;
  // This probably should be using ApiState, but we're putting that off.
  orgState: {
    backendProviders?: GlobalApi.ModelPartialBackendProviderEntry[];
    clusters?: RegionalClusters;
    orgs: GlobalApi.ModelListOrgsEntry[];
    nextOrgGuid: string;
    selectedOrg?: GlobalApi.ModelListOrgsEntry;
    orgQuotas?: GlobalApi.ModelOrgQuotas;
  } & Pick<ApiState<GlobalApi.ModelListOrgsEntry[]>, 'hasBeenInitialized'>;
  supportMatrix?: GlobalApi.ModelGetSupportMatrixResponse;
}

export enum StoreAction {
  // Org
  SetOrgs = 'SetOrgs',
  /** Set to the selected organization. */
  SetSelectedOrg = 'SetSelectedOrg',
  SetOrgBackendProviders = 'SetOrgBackendProviders',
  SetOrgBackendProviderData = 'SetOrgBackendProviderData',
  SetNextOrgGuid = 'SetNextOrgGuid',

  SetClusters = 'SetClusters',
  SetOrgQuotas = 'SetOrgQuotas',

  /** Set support matrix for selected organization */
  SetSupportMatrix = 'SetSupportMatrix',

  // Trial
  SetDisplayTrialBanner = 'SetDisplayTrialBanner',

  // Backend Provider
  SetNoBackendProvider = 'SetDisplayTrialBanner',
}

export type Action =
  | { type: StoreAction.SetOrgs; value: GlobalApi.ModelListOrgsEntry[] }
  | { type: StoreAction.SetSelectedOrg; value: string }
  | { type: StoreAction.SetNextOrgGuid; value: string }
  | { type: StoreAction.SetOrgQuotas; value: GlobalApi.ModelOrgQuotas }
  | {
      type: StoreAction.SetOrgBackendProviders;
      value: GlobalApi.ModelPartialBackendProviderEntry[];
    }
  | { type: StoreAction.SetClusters; value: RegionalClusters }
  | { type: StoreAction.SetSupportMatrix; value: GlobalApi.ModelGetSupportMatrixResponse }
  | { type: StoreAction.SetDisplayTrialBanner; value: boolean };

const initState: State = {
  displayTrialBanner: globalStorage.displayTrialBanner,
  orgState: {
    clusters: undefined,
    hasBeenInitialized: false,
    nextOrgGuid: '',
    orgQuotas: undefined,
    orgs: [],
    selectedOrg: undefined,
  },
  supportMatrix: undefined,
};

const StateContext = React.createContext<State | undefined>(undefined);
const DispatchContext = React.createContext<Dispatch<Action> | undefined>(undefined);

const setSelectedOrg = (state: State, orgId: string): State => {
  if (orgId === undefined || orgId === '') {
    return { ...state, orgState: { ...state.orgState, selectedOrg: undefined } };
  }
  const selection = state.orgState.orgs.find((org) => org.id === orgId);
  if (!selection) {
    handleError(undefined, {
      publicMessage: `${getOrganizationIdentifier()} ID ${orgId} not found`,
      silent: true,
    });
    return { ...state };
  }
  globalStorage.selectedOrgId = orgId;
  return { ...state, orgState: { ...state.orgState, selectedOrg: selection } };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case StoreAction.SetOrgs:
      if (isEqual(state.orgState.orgs, action.value))
        return {
          ...state,
          orgState: { ...state.orgState, hasBeenInitialized: true },
        };

      // reset selected org when no orgs are available
      if (action.value.length === 0) {
        return {
          ...state,
          orgState: {
            ...clone(initState.orgState),
            hasBeenInitialized: true,
          },
        };
      }

      // if the selectedOrg doesn't exist in the new list, reset it
      if (!action.value.some((org) => isEqual(org, state.orgState.selectedOrg))) {
        let newSelectedId = globalStorage.selectedOrgId;
        if (!action.value.some((org) => org.id === newSelectedId)) {
          newSelectedId = action.value[0].id;
        }
        return setSelectedOrg(
          {
            ...state,
            orgState: {
              ...state.orgState,
              hasBeenInitialized: true,
              orgs: action.value,
            },
          },
          newSelectedId,
        );
      }

      return {
        ...state,
        orgState: {
          ...state.orgState,
          hasBeenInitialized: true,
          orgs: action.value,
        },
      };
    case StoreAction.SetOrgBackendProviders:
      return {
        ...state,
        orgState: {
          ...state.orgState,
          backendProviders: action.value,
        },
      };
    case StoreAction.SetNextOrgGuid:
      return {
        ...state,
        orgState: {
          ...state.orgState,
          nextOrgGuid: action.value,
        },
      };
    case StoreAction.SetSelectedOrg:
      return setSelectedOrg(state, action.value);
    case StoreAction.SetClusters: {
      let activeClusters: RegionalClusters | undefined;
      const clusters = action.value;
      for (const [regionId, clusterInfo] of Object.entries(clusters)) {
        const filteredClusterInfo = clusterInfo.filter(
          (info: ModelClusterInfo) => info.state !== 'deleted',
        );
        if (filteredClusterInfo.length > 0) {
          if (activeClusters === undefined) activeClusters = {};
          activeClusters[regionId] = filteredClusterInfo;
        }
      }
      return {
        ...state,
        orgState: {
          ...state.orgState,
          clusters: activeClusters,
        },
      };
    }
    case StoreAction.SetSupportMatrix:
      return {
        ...state,
        supportMatrix: action.value,
      };
    case StoreAction.SetDisplayTrialBanner:
      globalStorage.displayTrialBanner = action.value;
      return {
        ...state,
        displayTrialBanner: action.value,
      };
    case StoreAction.SetOrgQuotas:
      return {
        ...state,
        orgState: {
          ...state.orgState,
          orgQuotas: action.value,
        },
      };
    default:
      return state;
  }
};

export const useStore = (): State => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};

export const useStoreDispatch = (): Dispatch<Action> => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error('useStoreDispatch must be used within a StoreProvider');
  }
  return context;
};

const StoreProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <UIManager>{children}</UIManager>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export default StoreProvider;

import React from 'react';

import { BasicConfigOption } from 'saasTypes';

import css from './NewAwsMldeClusterModal.module.scss';

interface BasicConfigSelectProps {
  onSelect: (option: BasicConfigOption) => void;
  selected: BasicConfigOption;
}

const BasicConfigSelect: React.FC<BasicConfigSelectProps> = ({
  selected,
  onSelect,
}: BasicConfigSelectProps) => {
  const standardClasses = [css.card];
  const proClasses = [css.card];
  if (selected === BasicConfigOption.Standard) {
    standardClasses.push(css.selected);
  } else if (selected === BasicConfigOption.Pro) {
    proClasses.push(css.selected);
  }

  return (
    <div className={css.cards}>
      <div
        className={standardClasses.join(' ')}
        onClick={() => {
          onSelect(BasicConfigOption.Standard);
        }}>
        <h5>Standard</h5>
        <p>Accelerator(s) per Instance</p>
        <p className={css.value}>1 x NVIDIA T4</p>
        <p>Max Instances</p>
        <p className={css.value}>2</p>
        <p>Estimated Usage Price</p>
        <p className={css.value}>~$0.53 / instance-hour</p>
      </div>
      <div
        className={proClasses.join(' ')}
        onClick={() => {
          onSelect(BasicConfigOption.Pro);
        }}>
        <h5>Pro</h5>
        <p>Accelerator(s) per Instance</p>
        <p className={css.value}>8 x NVIDIA Tesla® V100</p>
        <p>Max Instances</p>
        <p className={css.value}>2</p>
        <p>Estimated Usage Price</p>
        <p className={css.value}>$12.24 / instance-hour</p>
      </div>
    </div>
  );
};

export default BasicConfigSelect;

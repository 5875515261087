import { Modal } from 'hew/Modal';

import handleError from 'utils/error';

import TrialStartInfo from './TrialStartInfo';

interface Props {
  onSubmit: () => Promise<void>;
}
const TrialStartModalComponent: React.FC<Props> = ({ onSubmit }) => {
  return (
    <Modal
      size="large"
      submit={{ handleError: handleError, handler: onSubmit, text: 'Start Now' }}
      title="Start Trial">
      <TrialStartInfo />
    </Modal>
  );
};

export default TrialStartModalComponent;

import { CheckOutlined, DownOutlined, StarOutlined } from '@ant-design/icons';
import Avatar, { Size } from 'hew/Avatar';
import Dropdown, { MenuDivider } from 'hew/Dropdown';
import { DarkLight } from 'hew/internal/types';
import { useModal } from 'hew/Modal';
import React, { useCallback, useEffect, useState } from 'react';

import Link from 'components/Link';
import TrialStartModalComponent from 'components/Trial/TrialStartModal';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchOrgs, useFetchWithRetry } from 'hooks/useFetch';
import { paths } from 'routes/utils';
import { startTrial } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { notification } from 'utils/dialogApi';
import handleError from 'utils/error';
import { routeToReactUrl } from 'utils/routes';
import { getOrganizationIdentifier, isMspDeployment } from 'utils/saas';

import OrgModalComponent from './OrgModal';
import css from './OrgPicker.module.scss';

type Org = GlobalApi.ModelListOrgsEntry;

export interface OrgPickerProps {
  darkLight?: DarkLight;
  /** Denotes whether the picker should be minimal to fit in a small space or not.  */
  isCollapsed?: boolean;
  onSelect: (orgId: string, oldOrgId: string | null) => void;
  orgs: GlobalApi.ModelListOrgsEntry[];
  selectedId: string;
  truncate?: boolean;
}

interface SingleOrgPickerProps {
  darkLight: DarkLight;
  displayId?: boolean;
  isCollapsed?: boolean;
  mini?: boolean;
  org: Org;
  selected?: boolean;
  size?: Size;
  truncate?: boolean;
}

// FIXME: how do we do transitions for navbar items for `mini`?
export const SingleOrg: React.FC<SingleOrgPickerProps> = ({
  displayId = false,
  isCollapsed = false,
  mini,
  org,
  selected,
  size = Size.Small,
  truncate = false,
}) => {
  const baseClasses = [css.singleOrg];
  if (selected) baseClasses.push(css.selected);
  if (mini) baseClasses.push(css.mini);

  // Show a star icon for the super admin org in MSP deployments to improve navigation
  const isMspHeadOrg = isMspDeployment() && org.isSuperAdminOrg;
  return (
    <div className={baseClasses.join(' ')}>
      <Avatar hideTooltip noColor size={size} square text={org.name} />
      <div className={css.text} hidden={isCollapsed}>
        <div className={css.name + (truncate ? ` ${css.truncate}` : '')}>
          {isMspHeadOrg ? (
            <span>
              <StarOutlined />
              &nbsp;
            </span>
          ) : null}
          {org.name}
        </div>
        {displayId && <div className={css.id}>{org.id}</div>}
      </div>
      {selected && <CheckOutlined className="checkmark" />}
    </div>
  );
};

export const OrgPicker: React.FC<OrgPickerProps> = ({
  darkLight = DarkLight.Light,
  isCollapsed = false,
  onSelect,
  orgs,
  selectedId,
  truncate,
}) => {
  const OrgModal = useModal(OrgModalComponent);
  const TrialStartModal = useModal(TrialStartModalComponent);
  const [canceler] = useState(() => new AbortController());
  const fetchOrgs = useFetchOrgs(canceler);
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [selectedOrg, setSelectedOrg] = useState<GlobalApi.ModelListOrgsEntry | undefined>(
    undefined,
  );
  const { supportMatrix, orgState } = useStore();
  const user = useUser();

  useEffect(() => {
    const selection = orgs.find((org) => org.id === selectedId);
    setSelectedOrg(selection);
  }, [orgs, selectedId]);

  const toggleModal = useCallback(() => {
    if (user.userId === orgState.selectedOrg?.ownerId || user.superAdmin) {
      OrgModal.open();
      fetchOrgs();
    } else {
      try {
        if (user.licensing.enabled && !user.licensing.userLicensingStatus.active) {
          TrialStartModal.open();
        } else {
          OrgModal.open();
          fetchOrgs();
        }
      } catch (e) {
        handleError(e, {
          publicSubject: `Failed to check new ${getOrganizationIdentifier()} prerequisites`,
        });
      }
    }
  }, [
    OrgModal,
    TrialStartModal,
    fetchOrgs,
    orgState.selectedOrg?.ownerId,
    user.licensing,
    user.superAdmin,
    user.userId,
  ]);

  const confirmStartTrial = useCallback(async () => {
    if (!user) {
      handleError(undefined, {
        publicSubject: 'Invalid user associated with trial',
      });
      return;
    }
    try {
      await fetchWithRetry(
        async () => await startTrial({ userId: user.userId }, { signal: canceler.signal }),
      );
      await user.refetchLicenseStatus();
    } catch (e) {
      handleError(e, {
        publicSubject: 'Failed to start trial',
      });
      return;
    }
    OrgModal.open();
  }, [canceler.signal, fetchWithRetry, OrgModal, user]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const newOrgText = `New ${getOrganizationIdentifier()}`;
  const Divider: MenuDivider = { type: 'divider' };

  type MenuItem = { key: string; label: JSX.Element } | MenuDivider;
  const menuItems: MenuItem[] = [
    ...orgs.map((org) => {
      return {
        key: org.id,
        label: (
          <Link onClick={() => onSelect(org.id, orgState.selectedOrg?.id || '')}>
            <SingleOrg
              darkLight={darkLight}
              org={org}
              selected={org.id === selectedId}
              truncate={truncate}
            />
          </Link>
        ),
      };
    }),
  ];

  if (supportMatrix?.newOrgs) {
    menuItems.push(Divider, {
      key: 'new-org',
      label: <Link onClick={toggleModal}>{newOrgText}</Link>,
    });
  }

  const onCreate = useCallback(
    (newOrgId: string, newOrgName: string) => {
      onSelect(newOrgId, null);
      routeToReactUrl(paths.clusters());
      notification.success({
        message: `Switched to new ${getOrganizationIdentifier()} ${newOrgName}`,
      });
    },
    [onSelect],
  );
  if (orgs.length === 0) {
    return <p>There are no {getOrganizationIdentifier()}s to list</p>;
  }

  if (orgs.length === 1 && selectedId !== orgs[0].id) {
    onSelect(orgs[0].id, null);
  }

  return (
    <>
      <Dropdown menu={menuItems} placement="bottomRight">
        <div className={css.orgFace}>
          {selectedOrg ? (
            <SingleOrg
              darkLight={darkLight}
              isCollapsed={isCollapsed}
              org={selectedOrg}
              truncate={truncate}
            />
          ) : (
            <p>Select an {getOrganizationIdentifier()}</p>
          )}
          {!isCollapsed && <DownOutlined />}
        </div>
      </Dropdown>
      <TrialStartModal.Component onSubmit={confirmStartTrial} />
      <OrgModal.Component existingOrg={false} onCreate={onCreate} />
    </>
  );
};

export default OrgPicker;

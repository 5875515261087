import { Card, Form, Input, InputNumber, Radio, RadioChangeEvent } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import Button from 'hew/Button';
import { FormInstance } from 'hew/Form';
import Select, { Option } from 'hew/Select';
import Tooltip from 'hew/Tooltip';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import Link from 'components/Link';
import { defaultResourcePoolUse } from 'constants/defaults';
import { useStore } from 'contexts/Store';
import { regionSeriesMappers } from 'pages/Clusters/cluster-util';
import {
  ClusterDetails,
  DefaultResourcePool,
  InstanceUse,
  PoolConfig,
  ResourcePoolUse,
} from 'saasTypes';
import { ModelAgentResourceManagerConfig, ModelMasterConfig } from 'services/regional-bindings';
import { generateGkeMasterConfig } from 'utils/saas';
import { generateUUID } from 'utils/string';

import css from './GkeResourcePools.module.scss';
import { MachineTypeDropdown } from './MachineTypeDropdown';
import {
  IndependentAcceleratorTypesToSupportedCount,
  MachineTypeValues,
} from './MachineTypeDropdown.values';
import { defaultPoolConfigs } from './NewGkeMldeClusterModal/NewGkeMldeClusterModal.settings';
import { clusterDefaults } from './NewGkeMldeClusterModal/utils';

export const getEmptyPoolConfig = (num: number, key?: string): PoolConfig => {
  return {
    cpuSlotsAllowed: clusterDefaults.CPU_SLOTS_ALLOWED,
    instanceType: clusterDefaults.AUX_INSTANCE_TYPE,
    key: key ?? generateUUID(),
    maxInstances: clusterDefaults.MAX_AUX_INSTANCES,
    poolName: `resource-pool-${num}`,
    primaryUse: defaultResourcePoolUse,
  };
};

interface GkePoolConfigCardProps {
  clusterRegion: string;
  onRemove: (key: string) => void;
  onUpdate: (key: string, form: FormInstance) => void;
  onUpdateResourceManager: (keys: DefaultResourcePool[], value?: string) => void;
  poolConfig: PoolConfig;
  resourceManager: ModelAgentResourceManagerConfig;
}

const GkePoolConfigCard: React.FC<GkePoolConfigCardProps> = ({
  clusterRegion,
  onUpdate,
  onRemove,
  onUpdateResourceManager,
  poolConfig,
  resourceManager,
}) => {
  const [form] = Form.useForm();
  const [dropdownHidden, setDropdownHidden] = useState(true);

  const poolName = Form.useWatch('poolName', form);

  useEffect(() => {
    form.validateFields();
  }, [poolConfig, resourceManager, form, poolName]);

  const seriesMapper = useCallback((machineType: string) => {
    return regionSeriesMappers['gcp'](machineType);
  }, []);

  const acceleratorCountItems = useMemo((): DefaultOptionType[] => {
    const seriesInfo = MachineTypeValues[seriesMapper(poolConfig.instanceType)];

    if (seriesInfo === undefined) {
      return [];
    }

    if (!seriesInfo.isLockedToAccelerator) {
      if (poolConfig.acceleratorType === undefined) return [];
      const res: DefaultOptionType[] =
        IndependentAcceleratorTypesToSupportedCount[poolConfig.acceleratorType]?.map((count) => ({
          label: count,
          value: count,
        })) ?? [];
      if (res.filter(({ value }) => poolConfig.acceleratorCount === value).length === 0) {
        res.push({
          disabled: true,
          label: poolConfig.acceleratorCount,
          value: poolConfig.acceleratorCount,
        });
      }
      return res;
    } else {
      return [
        {
          label: seriesInfo.values[poolConfig.instanceType].accelerators,
          value: seriesInfo.values[poolConfig.instanceType].accelerators,
        },
      ];
    }
  }, [poolConfig, seriesMapper]);

  const acceleratorTypes = useMemo((): DefaultOptionType[] => {
    const seriesInfo = MachineTypeValues[seriesMapper(poolConfig.instanceType)];
    if (seriesInfo === undefined) return [];

    if (seriesInfo.isLockedToAccelerator) {
      return [];
    } else {
      const res: DefaultOptionType[] = seriesInfo.supportedAccelerators?.map((acceleratorType) => ({
        label: acceleratorType,
        value: acceleratorType,
      }));
      if (
        poolConfig.acceleratorType !== undefined &&
        res.filter(({ value }) => poolConfig.acceleratorType === value).length === 0
      ) {
        res.push({
          disabled: true,
          label: poolConfig.acceleratorType,
          value: poolConfig.acceleratorType,
        });
      }
      return res;
    }
  }, [poolConfig, seriesMapper]);

  const onPrimaryUseChange = useCallback(
    (e: RadioChangeEvent) => {
      if (e.target.value === ResourcePoolUse.Compute) {
        form.setFieldsValue({
          acceleratorCount: clusterDefaults.COMPUTE_INSTANCE_TYPE.acceleratorCount,
          acceleratorType: clusterDefaults.COMPUTE_INSTANCE_TYPE.acceleratorType,
          instanceType: clusterDefaults.COMPUTE_INSTANCE_TYPE.type,
        });
      } else {
        form.setFieldsValue({
          instanceType: clusterDefaults.AUX_INSTANCE_TYPE,
        });
      }
      onUpdate(poolConfig.key, form);
    },
    [form, onUpdate, poolConfig],
  );

  return (
    <Card
      extra={
        <Button
          onClick={() => {
            if (
              resourceManager.default_aux_resource_pool === poolConfig.poolName &&
              resourceManager.default_compute_resource_pool === poolConfig.poolName
            ) {
              onUpdateResourceManager([DefaultResourcePool.aux, DefaultResourcePool.compute]);
            } else if (resourceManager.default_aux_resource_pool === poolConfig.poolName) {
              onUpdateResourceManager([DefaultResourcePool.aux]);
            } else if (resourceManager.default_compute_resource_pool === poolConfig.poolName) {
              onUpdateResourceManager([DefaultResourcePool.compute]);
            }
            onRemove(poolConfig.key);
          }}>
          Remove
        </Button>
      }
      title={poolConfig.poolName}>
      <Form
        form={form}
        initialValues={poolConfig}
        labelCol={{ span: 24 }}
        onValuesChange={() => onUpdate(poolConfig.key, form)}>
        <Form.Item
          htmlFor={'poolName ' + poolConfig.poolName}
          label="Pool Name"
          name="poolName"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (!value) return Promise.reject('Pool name required');
                if (!value.match(/(?:[a-z](?:[-a-z0-9]{0,38}[a-z0-9])?)/)) {
                  return Promise.reject('Pool name must be alphanumeric with dashes');
                }
                return Promise.resolve();
              },
            },
          ]}>
          <Input id={'poolName ' + poolConfig.poolName} />
        </Form.Item>
        <Form.Item
          htmlFor={'instanceCategory ' + poolConfig.poolName}
          label="Instance Category"
          name="instanceCategory">
          <Radio.Group
            name={'instanceCategory ' + poolConfig.poolName}
            options={[
              {
                label: (
                  <Tooltip content="Select to see instance types for tasks that do not require dedicated compute resources.">
                    Auxiliary Tasks
                  </Tooltip>
                ),
                value: ResourcePoolUse.Aux,
              },
              {
                label: (
                  <Tooltip content="Select to see instance types that support compute tasks, i.e. GPUs or dedicated CPUs.">
                    Compute
                  </Tooltip>
                ),
                value: ResourcePoolUse.Compute,
              },
            ]}
            optionType="button"
            onChange={onPrimaryUseChange}
          />
        </Form.Item>
        <Form.Item
          className={css.formItemWithLink}
          htmlFor={'instanceType ' + poolConfig.poolName}
          label={
            <>
              <span>Instance Type</span>
              <Link
                external={true}
                path={`https://cloud.google.com/compute/docs/${
                  poolConfig.primaryUse === ResourcePoolUse.Aux
                    ? 'general-purpose-machines'
                    : 'accelerator-optimized-machines'
                }`}
                popout={true}>
                Resource Guide
              </Link>
            </>
          }>
          <MachineTypeDropdown
            buttonLabel={poolConfig.instanceType}
            clusterRegion={clusterRegion}
            hide={dropdownHidden}
            id={'instanceType ' + poolConfig.poolName}
            showArrow={false}
            use={
              poolConfig.primaryUse === ResourcePoolUse.Aux ? InstanceUse.Aux : InstanceUse.Compute
            }
            onSelect={(instanceType) => {
              const series = MachineTypeValues[seriesMapper(instanceType)];

              if (series?.isLockedToAccelerator) {
                form.setFieldsValue({
                  acceleratorCount: series.values[instanceType]?.accelerators ?? 1,
                  acceleratorType: series.acceleratorType,
                  instanceType,
                });
              } else {
                form.setFieldsValue({ instanceType });
              }

              onUpdate(poolConfig.key, form);
              setDropdownHidden(true);
            }}
            onVisibleChange={() => setDropdownHidden(false)}
          />
        </Form.Item>
        {poolConfig.primaryUse === ResourcePoolUse.Compute ? (
          poolConfig.instanceType &&
          MachineTypeValues[seriesMapper(poolConfig.instanceType)] && (
            <div className={css.valueDropdownButton}>
              <Form.Item
                className={css.smallSelector}
                label="GPU type"
                name="acceleratorType"
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        poolConfig.instanceType === undefined ||
                        MachineTypeValues[seriesMapper(poolConfig.instanceType)] === undefined
                      )
                        return;
                      if (value === undefined) return Promise.reject('Please choose a gpu type');

                      const series = MachineTypeValues[seriesMapper(poolConfig.instanceType)];

                      if (
                        !series.isLockedToAccelerator &&
                        !series.supportedAccelerators.includes(value)
                      ) {
                        return Promise.reject('Please choose another GPU type');
                      }

                      return Promise.resolve();
                    },
                  },
                ]}>
                <Select
                  disabled={
                    MachineTypeValues[seriesMapper(poolConfig.instanceType)].isLockedToAccelerator
                  }
                  options={acceleratorTypes}
                  value={poolConfig.acceleratorType}
                />
              </Form.Item>
              <Form.Item
                className={css.smallSelector}
                label="GPU Count"
                name="acceleratorCount"
                rules={[
                  {
                    validateTrigger: ['acceleratorType'],
                    validator: (_, value) => {
                      if (
                        poolConfig.acceleratorType === undefined ||
                        IndependentAcceleratorTypesToSupportedCount[poolConfig.acceleratorType] ===
                          undefined
                      )
                        return;
                      if (value === undefined) return Promise.reject('Please choose a gpu type');

                      const counts =
                        IndependentAcceleratorTypesToSupportedCount[poolConfig.acceleratorType];

                      if (!counts.includes(value)) {
                        return Promise.reject('Please choose valid GPU count');
                      }

                      return Promise.resolve();
                    },
                  },
                ]}>
                <Select
                  disabled={
                    MachineTypeValues[seriesMapper(poolConfig.instanceType)].isLockedToAccelerator
                  }
                  options={acceleratorCountItems}
                />
              </Form.Item>
            </div>
          )
        ) : (
          <></>
        )}
        <Form.Item
          htmlFor={'maxInstances ' + poolConfig.poolName}
          label="Max Instances"
          name="maxInstances"
          rules={[{ required: true, type: 'number' }]}>
          <InputNumber id={'maxInstances ' + poolConfig.poolName} min="1" />
        </Form.Item>
      </Form>
    </Card>
  );
};

const defaultState = {
  pools: defaultPoolConfigs,
  resourceManager: {
    default_aux_resource_pool: clusterDefaults.CPU_POOL_NAME,
    default_compute_resource_pool: clusterDefaults.GPU_POOL_NAME,
  },
};

interface GkeResourcePoolsProps {
  cluster?: ClusterDetails;
  updateMasterConfig: Dispatch<SetStateAction<ModelMasterConfig>>;
}

export const GkeResourcePools: React.FC<GkeResourcePoolsProps> = ({
  updateMasterConfig,
  cluster,
}) => {
  const [pools, setPools] = useState<PoolConfig[]>(
    () => cluster?.masterConfig.resource_pools ?? defaultState.pools,
  );

  const [resourceManager, setResourceManager] = useState<ModelAgentResourceManagerConfig>(
    cluster?.masterConfig.resource_manager ?? defaultState.resourceManager,
  );
  const { supportMatrix } = useStore();

  const location = useMemo(
    () => supportMatrix?.supportedLocations.find((region) => region.startsWith('gcp')) ?? '',
    [supportMatrix?.supportedLocations],
  );

  const [form] = Form.useForm();

  useEffect(() => {
    form.validateFields();
  }, [pools, resourceManager, form]);

  useEffect(() => {
    try {
      updateMasterConfig(generateGkeMasterConfig(pools, resourceManager));
    } catch (e) {
      updateMasterConfig({
        resource_manager: { default_aux_resource_pool: '', default_compute_resource_pool: '' },
        resource_pools: [],
      });
    }
  }, [pools, resourceManager, updateMasterConfig]);

  const updateResourceManager = useCallback((keys: DefaultResourcePool[], value?: string) => {
    if (!value) return;

    setResourceManager((prev: ModelAgentResourceManagerConfig) => ({
      default_aux_resource_pool: keys.includes(DefaultResourcePool.aux)
        ? value
        : prev.default_aux_resource_pool,
      default_compute_resource_pool: keys.includes(DefaultResourcePool.compute)
        ? value
        : prev.default_compute_resource_pool,
    }));
  }, []);

  const addPool = useCallback(() => {
    const newKey = generateUUID();
    setPools((prev) => [...prev, getEmptyPoolConfig(prev.length + 1, newKey)]);
  }, []);

  const updatePools = useCallback(
    (key: string, poolForm: FormInstance) => {
      if (!pools) return;
      setPools((prev) => {
        const poolsCopy = [...prev];
        const idx = poolsCopy.findIndex((a) => a.key === key);
        poolsCopy[idx] = { ...poolsCopy[idx], ...poolForm.getFieldsValue(true) };
        return poolsCopy;
      });
    },
    [pools],
  );

  const removePool = useCallback(
    (key: string) => {
      if (!pools) return;
      setPools((prev) => {
        return prev.filter((a) => a.key !== key);
      });
    },
    [pools],
  );

  return (
    <>
      <Form form={form} labelCol={{ span: 24 }}>
        <h2 className={css.sectionHeader}>
          Resource Pools
          <Button onClick={addPool}>Add</Button>
        </h2>
        <Form.Item
          initialValue={resourceManager.default_aux_resource_pool}
          label="Default Aux Pool"
          name="defaultAuxPool"
          required
          rules={[
            {
              message: 'Valid Default Aux Pool Required',
              required: true,
              validator: (_, val) => {
                if (pools === undefined) return;
                if (!pools.find((p) => p.poolName === val)) {
                  return Promise.reject('Default Aux Pool must be found in resource pools');
                }
                return Promise.resolve();
              },
            },
          ]}>
          <Select
            onChange={(value) => {
              updateResourceManager([DefaultResourcePool.aux], String(value));
            }}>
            {pools?.map((p) => (
              <Option key={p.key} value={p.poolName}>
                {p.poolName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={resourceManager.default_compute_resource_pool}
          label="Default Compute Pool"
          name="defaultComputePool"
          required
          rules={[
            {
              message: 'Valid Default Compute Pool Required',
              required: true,
              validator: (_, val) => {
                if (pools === undefined) return;
                if (!pools.find((p) => p.poolName === val)) {
                  return Promise.reject('Default Compute Pool must be found in resource pools');
                }
                return Promise.resolve();
              },
            },
          ]}>
          <Select
            onChange={(value) => {
              updateResourceManager([DefaultResourcePool.compute], String(value));
            }}>
            {pools?.map((p) => (
              <Option key={p.key} value={p.poolName}>
                {p.poolName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <div className={css.pools}>
        {pools?.map((poolConfig: PoolConfig) => {
          return (
            <GkePoolConfigCard
              clusterRegion={location}
              key={poolConfig.key}
              poolConfig={poolConfig}
              resourceManager={resourceManager}
              onRemove={removePool}
              onUpdate={updatePools}
              onUpdateResourceManager={updateResourceManager}
            />
          );
        })}
      </div>
    </>
  );
};

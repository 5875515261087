import React, { useCallback } from 'react';

import { analyticsEvents, telemetryInstance } from 'analytics';
import useUI from 'components/UIManager';
import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';

import OrgPicker from './OrgPicker';

export const OrgPickerStateful: React.FC<{
  isCollapsed?: boolean;
}> = ({ isCollapsed }) => {
  const { orgState } = useStore();
  const storeDispatch = useStoreDispatch();
  const { ui } = useUI();

  // set up a callback to handle selecting an organization by id
  const handleSelect = useCallback(
    (orgId: string, oldOrgId: string | null) => {
      telemetryInstance.track(analyticsEvents.orgChange, {
        from: oldOrgId,
        to: orgId,
      });
      telemetryInstance.page();
      storeDispatch({ type: StoreAction.SetSelectedOrg, value: orgId });
    },
    [storeDispatch],
  );

  return (
    <OrgPicker
      darkLight={ui.darkLight}
      isCollapsed={isCollapsed}
      orgs={orgState.orgs}
      selectedId={orgState.selectedOrg?.id || ''}
      truncate
      onSelect={handleSelect}
    />
  );
};

import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import Button from 'hew/Button';
import Form from 'hew/Form';
import Input from 'hew/Input';
import Toggle from 'hew/Toggle';
import { useCallback, useEffect, useState } from 'react';

import { analyticsEvents, telemetryInstance } from 'analytics';
import { useAuth } from 'contexts/Auth';
import { useStore } from 'contexts/Store';
import { useFetchOrgQuotas, useFetchWithRetry } from 'hooks/useFetch';
import { updateOrgQuotas } from 'services/api';
import { ModelOrgQuotas } from 'services/global-bindings';
import { notification } from 'utils/dialogApi';
import handleError, { ErrorLevel } from 'utils/error';
import { getOrganizationIdentifier } from 'utils/saas';

import css from './OrganizationQuotas.module.scss';

interface OrganizationQuotasValues {
  gpu: number;
  cpu: number;
}

const OrganizationQuotasForm: React.FC = () => {
  const {
    orgState: { selectedOrg, orgQuotas },
  } = useStore();
  const {
    jwtInfo: { superAdmin },
  } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canceler] = useState(() => new AbortController());
  const fetchOrgQuotas = useFetchOrgQuotas(canceler);
  const fetchWithRetry = useFetchWithRetry(canceler);

  const [quotasForm] = Form.useForm<OrganizationQuotasValues>();
  const maxGPU = Form.useWatch('gpu', quotasForm);
  const maxCPU = Form.useWatch('cpu', quotasForm);

  useEffect(() => {
    quotasForm.setFieldValue(
      'gpu',
      orgQuotas?.gpuQuota === null || orgQuotas?.gpuQuota === undefined ? -1 : orgQuotas.gpuQuota,
    );
    quotasForm.setFieldValue(
      'cpu',
      orgQuotas?.cpuQuota === null || orgQuotas?.cpuQuota === undefined ? -1 : orgQuotas.cpuQuota,
    );
  }, [orgQuotas, quotasForm]);

  const quotaInputValidator = useCallback((_: RuleObject, value: StoreValue) => {
    if (isNaN(parseInt(value))) {
      return Promise.reject();
    } else if (value >= -1) {
      return Promise.resolve();
    } else {
      return Promise.reject('Limit cannot be less than -1');
    }
  }, []);

  const onQuotaToggleChange = useCallback(
    (quotaFieldName: string, quotaType: keyof ModelOrgQuotas, checked: boolean) => {
      let targetValue: number;

      if (checked) {
        // try to restore the quota value from global state, when user toggles it back ON within a form-save session
        if (orgQuotas && orgQuotas[quotaType] !== -1) {
          targetValue = orgQuotas[quotaType] || 1;
        } else {
          targetValue = 1;
        }
      } else {
        targetValue = -1;
      }

      quotasForm.setFieldValue(quotaFieldName, targetValue);
      quotasForm.validateFields();
    },
    [orgQuotas, quotasForm],
  );

  const onOrganizationQuotasSubmit = useCallback(async () => {
    if (selectedOrg) {
      setIsSubmitting(true);
      try {
        const body = {
          orgId: selectedOrg?.id,
          orgQuotas: { quotas: { cpuQuota: +maxCPU, gpuQuota: +maxGPU } },
        };
        await fetchWithRetry(async () => await updateOrgQuotas(body));
      } catch (error) {
        handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: 'Failed to update org quotas',
          silent: false,
        });
        return;
      } finally {
        setIsSubmitting(false);
      }

      notification.info({
        message: `${getOrganizationIdentifier()} quotas updated`,
      });

      telemetryInstance.track(analyticsEvents.orgQuotaUpdate, {
        from: orgQuotas,
        orgId: selectedOrg.id,
        to: { cpuQuota: maxCPU, gpuQuota: maxGPU },
      });

      await fetchOrgQuotas();
    }
  }, [fetchOrgQuotas, orgQuotas, maxGPU, maxCPU, selectedOrg, fetchWithRetry]);

  return (
    <>
      <Form form={quotasForm} layout="inline" onFinish={onOrganizationQuotasSubmit}>
        <Form.Item className={css['form-item']}>
          <Toggle
            checked={+maxCPU !== -1}
            disabled={!superAdmin}
            label="CPU Quota"
            onChange={(checked) => onQuotaToggleChange('cpu', 'cpuQuota', checked)}
          />
          <Form.Item
            className={css['form-item-input']}
            name="cpu"
            rules={[{ validator: quotaInputValidator }]}>
            <Input
              className={css['input-number']}
              disabled={!superAdmin || +maxCPU === -1}
              min={-1}
              type="number"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item className={css['form-item']}>
          <Toggle
            checked={+maxGPU !== -1}
            disabled={!superAdmin}
            label="GPU Quota"
            onChange={(checked) => onQuotaToggleChange('gpu', 'gpuQuota', checked)}
          />
          <Form.Item
            className={css['form-item-input']}
            name="gpu"
            rules={[{ validator: quotaInputValidator }]}>
            <Input
              className={css['input-number']}
              disabled={!superAdmin || +maxGPU === -1}
              min={-1}
              type="number"
            />
          </Form.Item>
        </Form.Item>
        <p className={css.description}>
          Resource limits shared by all clusters in the organization
        </p>
        <Form.Item className={css.submit}>
          <Button
            disabled={!superAdmin || isSubmitting}
            htmlType="submit"
            loading={isSubmitting}
            type="primary">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OrganizationQuotasForm;

import React from 'react';

import { CommonProps } from 'types';

import css from './PageHeader.module.scss';

export interface Props extends CommonProps {
  docTitle?: string;
  options?: React.ReactNode;
  sticky?: boolean;
  subTitle?: React.ReactNode;
  title?: string;
}

const PageHeader: React.FC<Props> = (props: Props) => {
  const classes = [css.base, props.className];

  const showHeader = props.title || props.subTitle || props.options;

  if (props.sticky) classes.push(css.sticky);

  return (
    <div className={classes.join(' ')}>
      {showHeader && (
        <div className={css.header}>
          <div className={css.title}>{props.title}</div>
          <div className={css.subTitle}>{props.subTitle}</div>
          <div className={css.options}>{props.options}</div>
        </div>
      )}
    </div>
  );
};

export default PageHeader;

import Button from 'hew/Button';
import React from 'react';

import PageMessage from 'components/PageMessage';
import { paths } from 'routes/utils';
import { routeToReactUrl } from 'utils/routes';

const SignedOut: React.FC = () => {
  return (
    <div style={{ height: 'calc(var(--vh, 1vh) * 100)', width: '100%' }}>
      <PageMessage title="Signed Out">
        <p>{'You have been successfully signed out'}</p>
        <Button onClick={() => routeToReactUrl(paths.login())}>Sign in again</Button>
      </PageMessage>
    </div>
  );
};

export default SignedOut;

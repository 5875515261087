import { globalServerAddress, regionalServerAddress } from 'routes/utils';
import * as GlobalApi from 'services/global-bindings';
import * as RegionalApi from 'services/regional-bindings';

import { RegionID } from './types';

/**
 * available regional apis.
 */
const regions: RegionID[] = ['aws-us-west-2', 'gcp-us-west1', 'byok-us-west-2'];

export const globalVersion = '/v0';
export const regionalVersion = '/v0';

interface DetRegionApi {
  Cluster: RegionalApi.ClustersApi;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  LogStream: any;
}

/**
 * @param regions list of regions to set up api configs for.
 * @returns api configs for given regions.
 */
const regionalApis = (regions: Set<RegionID> | RegionID[]) => {
  const regionApi = (regionId: RegionID): DetRegionApi => {
    const config = new RegionalApi.Configuration({
      basePath: regionalServerAddress(regionId, regionalVersion),
    });
    return {
      Cluster: new RegionalApi.ClustersApi(config),
      LogStream: RegionalApi.ClustersApiFetchParamCreator(config),
    };
  };
  return Array.from(regions).reduce((acc, cur) => {
    acc[cur] = regionApi(cur);
    return acc;
  }, {} as Record<RegionID, DetRegionApi>);
};

const globalApi = (apiConfig?: GlobalApi.ConfigurationParameters) => {
  const globalApiConfig = new GlobalApi.Configuration({
    basePath: globalServerAddress(globalVersion),
    ...apiConfig,
  });
  return {
    Auth: new GlobalApi.AuthzApi(globalApiConfig),
    Cluster: new GlobalApi.ClustersApi(globalApiConfig),
    Invites: new GlobalApi.InvitesApi(globalApiConfig),
    Licensing: new GlobalApi.LicensingApi(globalApiConfig),
    OrgQuotas: new GlobalApi.OrgQuotasApi(globalApiConfig),
    Orgs: new GlobalApi.OrgsApi(globalApiConfig),
    SupportMatrix: new GlobalApi.SupportMatrixApi(globalApiConfig),
  };
};

export const detApi = {
  Global: globalApi(),
  Regional: regionalApis(regions),
};

// Update references to generated API code with new configuration.
export const updateDetApi = (apiConfig?: GlobalApi.ConfigurationParameters): void => {
  detApi.Global = globalApi(apiConfig);
  detApi.Regional = regionalApis(regions);
};

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Button from 'hew/Button';
import { formatDatetime } from 'hew/internal/functions';
import Spinner from 'hew/Spinner';
import { useState } from 'react';

import { useFetchWithRetry } from 'hooks/useFetch';
import { claimUserInvite } from 'services/api';
import { ModelUserInvite } from 'services/global-bindings';
import { refreshPage } from 'utils/browser';
import handleError from 'utils/error';
import { getOrganizationIdentifier } from 'utils/saas';

import NavigationHeader from './NavigationHeader';
import css from './OnboardingInvites.module.scss';

interface ModelUserInviteWithButton extends ModelUserInvite {
  acceptButton: React.ReactNode | null;
}

type OnboardingInvitesComponentProps = {
  invites: ModelUserInvite[];
  userId: string;
};

const columns: ColumnsType<ModelUserInviteWithButton> = [
  {
    dataIndex: 'acceptButton',
    key: 'action',
    title: 'Action',
  },
  {
    dataIndex: 'orgName',
    key: 'org',
    title: getOrganizationIdentifier(),
  },
  {
    dataIndex: 'orgRole',
    key: 'role',
    title: 'Role',
  },
  {
    dataIndex: 'defaultClusterRole',
    key: 'defaultClusterRole',
    title: 'Default Cluster Role',
  },
  {
    dataIndex: 'expiry',
    key: 'expiry',
    title: 'Expiration',
  },
];

const OnboardingInvitesComponent = ({
  invites,
  userId,
}: OnboardingInvitesComponentProps): JSX.Element => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);

  const claimAndRedirect = async (userId: string, inviteId: string) => {
    try {
      await fetchWithRetry(async () => await claimUserInvite({ inviteId, userId }));
      refreshPage();
    } catch (err) {
      handleError(err, {
        level: 'error',
        publicSubject: 'Failed to claim invite. Please try again soon.',
      });
    }
  };
  const invitesWithButtons: ModelUserInviteWithButton[] = [];

  if (invites.length === 0) return <Spinner />;
  invites.forEach((invite, index) => {
    const inviteWithButton: ModelUserInviteWithButton = { ...invite, acceptButton: null };
    inviteWithButton.acceptButton = (
      <span key={index}>
        <Button type="primary" onClick={() => claimAndRedirect(userId, invite.inviteId)}>
          Claim
        </Button>
      </span>
    );
    inviteWithButton.expiry = formatDatetime(inviteWithButton.expiry);
    invitesWithButtons.push(inviteWithButton);
  });

  return (
    <div className={css.container}>
      <NavigationHeader titleOnly={true} />
      <h2>Welcome! You have pending invitations.</h2>
      <Table
        className={css.table}
        columns={columns}
        dataSource={invitesWithButtons}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default OnboardingInvitesComponent;

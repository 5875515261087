import Dropdown from 'hew/Dropdown';
import { useModal } from 'hew/Modal';
import Tooltip from 'hew/Tooltip';
import useConfirm from 'hew/useConfirm';
import React, { useEffect, useMemo, useState } from 'react';

import { actionsButton } from 'components/Table';
import { useStore } from 'contexts/Store';
import { useFetchWithRetry } from 'hooks/useFetch';
import { deleteOrgUser } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import handleError from 'utils/error';

import { _UserClustersModal } from './UserClustersModal';

interface Props {
  user: GlobalApi.ModelOrgUser;
  fetchUsers: () => Promise<void>;
  orgId: string;
}

enum Action {
  Remove = 'Remove',
  ManageAccess = 'Manage Access',
}
const MembersActionDropdown: React.FC<Props> = ({ user, fetchUsers, orgId }) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const {
    orgState: { selectedOrg },
  } = useStore();
  const confirm = useConfirm();
  const UserClustersModal = useModal(_UserClustersModal);
  const orgOwner = selectedOrg?.ownerId === user.id ? true : false;
  const dropdownMenuItems = useMemo(() => {
    return [
      {
        key: Action.ManageAccess,
        label: Action.ManageAccess,
        onClick: UserClustersModal.open,
      },
      {
        danger: true,
        disabled: orgOwner,
        key: Action.Remove,
        label: orgOwner ? (
          <Tooltip content={'Cannot remove org owner'} placement={'bottom'} trigger={'hover'}>
            {Action.Remove}
          </Tooltip>
        ) : (
          Action.Remove
        ),
        onClick: () => {
          confirm({
            content: 'Are you sure you want to delete the user?',
            danger: true,
            onConfirm: async () => {
              if (!selectedOrg) return;
              await fetchWithRetry(
                async () =>
                  await deleteOrgUser(
                    {
                      orgId: selectedOrg.id,
                      userId: user.id,
                    },
                    { signal: canceler.signal },
                  ),
              );
              fetchUsers();
            },
            onError: (e) => {
              handleError(e, {
                publicSubject: 'Failed to delete user',
              });
            },
            title: 'Delete User',
          });
        },
      },
    ];
  }, [
    UserClustersModal.open,
    orgOwner,
    confirm,
    selectedOrg,
    fetchUsers,
    fetchWithRetry,
    user.id,
    canceler.signal,
  ]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return (
    <>
      <UserClustersModal.Component orgId={orgId} user={user} />
      <Dropdown menu={dropdownMenuItems}>{actionsButton}</Dropdown>
    </>
  );
};

export default MembersActionDropdown;

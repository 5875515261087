import { Table } from 'antd';
import Select from 'antd/es/select';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import Pivot from 'hew/Pivot';
import { TableLayout } from 'rc-table/lib/interface';
import React, { useCallback, useMemo, useState } from 'react';

import { defaultRowClassName } from 'components/Table';
import { useStore } from 'contexts/Store';
import { regionSeriesMappers } from 'pages/Clusters/cluster-util';
import { InstanceUse } from 'saasTypes';

import css from './MachineTypeDropdown.module.scss';
import {
  auxSeries,
  computeSeries,
  directorSeries,
  KEY_COLUMN_NAME,
  MachineCombinedType,
  MachineTypeInfoAccelerator,
  MachineTypeInfoBasic,
  MachineTypeInfoGeneral,
  MachineTypeValues,
} from './MachineTypeDropdown.values';

interface TableProps {
  onSelect: (machineType: string) => void;
  series: MachineDropdownTabData;
}

const MachineSeriesTable = ({ onSelect, series }: TableProps) => {
  const commonConfig = useMemo(() => {
    return {
      pagination: false as const,
      rowClassName: defaultRowClassName([], { clickable: true }),
      rowKey: KEY_COLUMN_NAME,
      size: 'small' as SizeType,

      style: { marginBottom: 20 },
      tableLayout: 'auto' as TableLayout,
    };
  }, []);

  const onTableRow = useCallback(
    (record: MachineTypeInfoBasic) => {
      return {
        onClick: () => {
          onSelect(record[KEY_COLUMN_NAME]);
        },
      };
    },
    [onSelect],
  );

  // render options for each individual use category
  if (series.isLockedToAccelerator) {
    return (
      <Table<MachineTypeInfoAccelerator>
        className={css.dropdownTable}
        columns={series.columns as ColumnsType<MachineTypeInfoAccelerator>}
        dataSource={series.rows as MachineTypeInfoAccelerator[]}
        scroll={{ x: 650 }}
        onRow={onTableRow}
        {...commonConfig}
      />
    );
  } else {
    return (
      <Table<MachineTypeInfoGeneral>
        className={css.dropdownTable}
        columns={series.columns as ColumnsType<MachineTypeInfoGeneral>}
        dataSource={series.rows as MachineTypeInfoAccelerator[]}
        onRow={onTableRow}
        {...commonConfig}
      />
    );
  }
};

interface MachineDropdownProps {
  buttonLabel?: string;
  clusterRegion: string;
  hide: boolean;
  id?: string;
  onSelect: (machineType: string) => void;
  onVisibleChange: () => void;
  showArrow: boolean;
  use: InstanceUse;
}

interface MachineDropdownTabData {
  columns:
    | ColumnsType<MachineTypeInfoAccelerator>
    | ColumnsType<MachineTypeInfoGeneral>
    | ColumnsType<MachineTypeInfoBasic>;
  description: string;
  isLockedToAccelerator: boolean;
  rows: MachineCombinedType[];
  seriesName: string;
}

const getTableData = (supportedSeries: string[]) => {
  interface DropdownMap {
    [seriesName: string]: MachineDropdownTabData;
  }

  return supportedSeries.reduce((acc: DropdownMap, t: string) => {
    // TODO: don't hardcode this if the structs are uninoned in the
    // future
    const seriesString = regionSeriesMappers['gcp'](t);

    const res1 = {
      ...acc,
      [seriesString]: {
        columns: MachineTypeValues[seriesString].columns || [
          { dataIndex: KEY_COLUMN_NAME, title: KEY_COLUMN_NAME },
        ],
        description: MachineTypeValues[seriesString].description,
        isLockedToAccelerator: MachineTypeValues[seriesString].isLockedToAccelerator,
        rows: [
          ...(acc[seriesString]?.rows ?? []),
          MachineTypeValues[seriesString].values[t] || { [KEY_COLUMN_NAME]: t },
        ],
        seriesName: seriesString,
      },
    };

    return res1;
  }, {} as DropdownMap);
};

const isUnknownSeries = (series: string) => {
  return !(
    directorSeries.includes(series) ||
    auxSeries.includes(series) ||
    computeSeries.includes(series)
  );
};

export const MachineTypeDropdown: React.FC<MachineDropdownProps> = ({
  id,
  use,
  clusterRegion,
  onSelect,
  buttonLabel,
}) => {
  const { supportMatrix } = useStore();

  const tableData = useMemo(() => {
    if (!supportMatrix) return {};

    let targets: string[] = [];
    const seriesMapper = regionSeriesMappers['gcp'];
    const machineFilterPredicate = (machineType: string, seriesTargets: string[]): boolean => {
      const series = seriesMapper(machineType);
      return seriesTargets.includes(series) || isUnknownSeries(series);
    };

    switch (use) {
      case InstanceUse.Master:
        targets = supportMatrix.gkeSupportMatrix.supportedDirectorTypes[clusterRegion];
        break;
      case InstanceUse.Aux:
        targets = Object.values(supportMatrix.gkeSupportMatrix.supportedMachineTypes[clusterRegion])
          .flat()
          .filter((machineType) => machineFilterPredicate(machineType, auxSeries));
        break;
      case InstanceUse.Compute:
        targets = Object.values(supportMatrix.gkeSupportMatrix.supportedMachineTypes[clusterRegion])
          .flat()
          .filter((machineType) => machineFilterPredicate(machineType, computeSeries));
        break;
      default:
        return {};
    }

    return getTableData(targets);
  }, [supportMatrix, clusterRegion, use]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownVisibleChange = useCallback((open: boolean) => {
    setDropdownOpen(open);
  }, []);

  const tabItems = useMemo(() => {
    const selectResource = (e: string) => {
      handleDropdownVisibleChange(false);
      onSelect(e);
    };

    return Object.values(tableData).map((category) => {
      return {
        children: (
          <div>
            {category.description && <span>{category.description}</span>}
            <div className={css.tableWrapper}>
              <MachineSeriesTable series={category} onSelect={selectResource} />
            </div>
          </div>
        ),
        key: category.seriesName,
        label: category.seriesName,
      };
    });
  }, [handleDropdownVisibleChange, onSelect, tableData]);

  return (
    <Select
      dropdownRender={() => <Pivot items={tabItems} type="secondary" />}
      id={id}
      open={dropdownOpen}
      value={buttonLabel}
      onDropdownVisibleChange={handleDropdownVisibleChange}
    />
  );
};

import { LoadingOutlined } from '@ant-design/icons';
import { Button as ButtonWithShape, Card, Popover, Spin, Table } from 'antd';
import Button from 'hew/Button';
import Icon from 'hew/Icon';
import Tooltip from 'hew/Tooltip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineRedo } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { handlePath, paths } from 'routes/utils';
import { ClusterRole, ClusterState, failedClusterState, transitionalClusterState } from 'saasTypes';
import { retryClusterAction } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { antdMountContainer } from 'utils/dom';
import { AnyMouseEvent } from 'utils/routes';
import { disableClusterLinkByState, getClusterRole } from 'utils/saas';
import { capitalizeWord } from 'utils/string';

import ClusterActionDropdown from './ClusterActionDropdown';
import css from './ClusterCard.module.scss';

const { Meta } = Card;

interface Props {
  cluster: GlobalApi.ModelClusterInfo;
  orgId: string;
}

const StateAvatar: React.FC<Props> = ({ cluster, orgId }) => {
  const [isRetrying, setIsRetrying] = useState(false);
  useEffect(() => {
    setIsRetrying(false);
  }, [cluster]);

  if (transitionalClusterState.has(cluster.state as ClusterState)) {
    return <Spin indicator={<LoadingOutlined className={css.spinner} spin />} />;
  } else if (cluster.state === ClusterState.Running) {
    return <Icon name="cluster" size="huge" title={ClusterState.Running} />;
  } else if (cluster.state === ClusterState.Paused) {
    return <Icon name="pause" size="huge" title={ClusterState.Paused} />;
  } else if (cluster.state === ClusterState.Deleted) {
    return <Icon name="close" size="huge" title={ClusterState.Deleted} />;
  } else {
    //catch all for failed state
    return (
      <div className={css.failedRetry}>
        <Popover
          content={
            <ul>
              {[...new Set(cluster.errors)].map((err, index) => (
                <li key={index}>Error: {err}</li>
              ))}
            </ul>
          }
          getPopupContainer={antdMountContainer}>
          <i className={`icon-warning-large ${css.stateFailed} ${css.huge}`} />
        </Popover>
        {!isRetrying ? (
          <div className={css.retryButton}>
            <Tooltip content="Retry action">
              <ButtonWithShape
                icon={<AiOutlineRedo />}
                shape="circle"
                onClick={() => {
                  setIsRetrying(true);
                  retryClusterAction({
                    clusterId: cluster.id,
                    orgId: orgId,
                    regionId: cluster.location,
                  });
                }}
              />
            </Tooltip>
          </div>
        ) : (
          <div className={css.retryLoading}>
            <Spin indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
          </div>
        )}
      </div>
    );
  }
};

const getDescription = (cluster: GlobalApi.ModelClusterInfo) => {
  let descriptionState = <span>{capitalizeWord(cluster.state)}</span>;
  if (cluster.state === ClusterState.Running) {
    descriptionState = <span className={css.stateRunning}>Running</span>;
  } else if (failedClusterState.has(cluster.state as ClusterState)) {
    descriptionState = (
      <Popover
        content={
          <ul>
            {[...new Set(cluster.errors)].map((err, index) => (
              <li key={index}>Error: {err}</li>
            ))}
          </ul>
        }
        getPopupContainer={antdMountContainer}>
        <span className={css.stateFailed}>{descriptionState}</span>
      </Popover>
    );
  }
  return descriptionState;
};

interface ClusterCardProps {
  cluster: GlobalApi.ModelClusterInfo;
  loadClusters: () => Promise<void>;
  orgId: string;
  isBYOK?: boolean;
}

type GenericResourcePool = {
  poolName: string;
  instanceType: string;
  tooltip?: string;
};

const ClusterCard: React.FC<ClusterCardProps> = ({
  cluster,
  loadClusters,
  isBYOK,
  orgId,
}: ClusterCardProps) => {
  const { supportMatrix } = useStore();
  const { roles } = useUser();

  const [clusterRole, setClusterRole] = useState(ClusterRole.None);

  const setRole = useCallback(() => {
    if (roles) {
      const clusterRole = getClusterRole(roles, orgId, cluster.id);
      setClusterRole(clusterRole);
    }
  }, [cluster, orgId, roles]);

  useEffect(() => {
    setRole();
  }, [setRole]);

  const navigate = useNavigate();
  const logPath = paths.logs(cluster.id);

  const resourcePools: GenericResourcePool[] = useMemo(() => {
    let pools: GenericResourcePool[] = [];
    if (cluster.resourcePools) {
      pools = cluster.resourcePools.map((p) => {
        const instance = p.provider.instance_type;
        const tooltip =
          instance.gpu_type && instance.gpu_num
            ? `${instance.gpu_num} x ${instance.gpu_type}`
            : undefined;

        return {
          instanceType: instance.machine_type,
          poolName: p.pool_name,
          tooltip,
        };
      });
    }
    return pools;
  }, [cluster.resourcePools]);

  const dropdown = (
    <ClusterActionDropdown
      cluster={cluster}
      key={cluster.id}
      loadClusters={loadClusters}
      orgId={orgId}
      supportedDetVersions={supportMatrix?.supportedDetVersions ?? []}
    />
  );

  const [meta, setMeta] = useState(
    <Meta
      avatar={<StateAvatar {...{ cluster, orgId }} />}
      className={css.cardMeta}
      description={getDescription(cluster)}
      title={cluster.name}
    />,
  );

  // check if cluster name has overflowed, if so, render it with a tooltip
  useEffect(() => {
    const clusterDiv = document.getElementById(cluster.id);
    const titleElement = clusterDiv?.querySelectorAll('.ant-card-meta-title');
    const overflowedTitle = titleElement
      ? titleElement[0].scrollWidth > titleElement[0].clientWidth
      : false;
    if (overflowedTitle) {
      setMeta(
        <Tooltip content={cluster.name}>
          <Meta
            avatar={<StateAvatar {...{ cluster, orgId }} />}
            className={css.cardMeta}
            description={getDescription(cluster)}
            title={cluster.name}
          />
        </Tooltip>,
      );
    } else {
      setMeta(
        <Meta
          avatar={<StateAvatar {...{ cluster, orgId }} />}
          className={css.cardMeta}
          description={getDescription(cluster)}
          title={<div id={cluster.name + 'title'}> {cluster.name}</div>}
        />,
      );
    }
  }, [cluster, orgId]);

  const infoTableData = useMemo(() => {
    const fields = [
      { field: 'Cluster ID', value: cluster.id },
      { field: 'Location', value: isBYOK ? 'K8S' : cluster.location },
    ];

    if (cluster.detVersion) {
      fields.push({ field: 'Application Type', value: 'MLDE' });
      fields.push({ field: 'Application Version', value: cluster.detVersion });
      fields.push({
        field: isBYOK ? 'Master Node' : 'Master Instance Type',
        value: cluster.masterInstanceType,
      });
    } else if (cluster.appVersions.mldm) {
      fields.push({ field: 'Application Type', value: 'MLDM' });
      fields.push({ field: 'Version', value: cluster.appVersions.mldm });
      fields.push({ field: 'Master Node Type', value: cluster.masterInstanceType });
      fields.push({ field: 'Database', value: 'db.m5.large + 100 GB SSD' });
    }
    const infoColumns = [
      {
        dataIndex: 'field',
        key: 'field',
        title: 'Field',
      },
      {
        dataIndex: 'value',
        key: 'value',
        title: 'Value',
      },
    ];

    const infoSource = fields.map((field) => {
      return {
        field: field.field,
        value: field.value,
      };
    });
    return { infoColumns, infoSource };
  }, [cluster, isBYOK]);

  const resourcePoolTable = useMemo(() => {
    if (!resourcePools.length) return null;

    const resourcePoolColumns = [
      {
        dataIndex: 'name',
        key: 'name',
        title: 'Name',
      },
      {
        dataIndex: 'instanceType',
        key: 'instanceType',
        title: 'Instance Type',
      },
    ];

    const resourcePoolDataSource = resourcePools.map((field) => {
      return {
        instanceType:
          // Instance type may be empty for BYOK
          field.instanceType === '' ? (
            ''
          ) : (
            <div>
              <div>
                {field.instanceType}{' '}
                <Icon
                  name="info"
                  showTooltip
                  size="tiny"
                  title={field.tooltip ?? field.instanceType}
                />
              </div>
            </div>
          ),
        name: field.poolName,
        toolTip: field.tooltip,
      };
    });

    if (cluster.detVersion) {
      return (
        <>
          <div className={css.resourcePoolsHeader}>Resource Pools</div>
          <div className={css.resourcePoolsContent}>
            <Table
              columns={resourcePoolColumns}
              dataSource={resourcePoolDataSource}
              pagination={false}
              showHeader={false}
            />
          </div>
        </>
      );
    } else if (cluster.appVersions.mldm) {
      return (
        <>
          <div className={css.resourcePoolsHeader}>Autoscaling (Max 4)</div>
          <div className={css.resourcePoolsContent}>
            <Table
              columns={resourcePoolColumns}
              dataSource={resourcePoolDataSource.slice(0, 1)} // We only use one type for autoscaling
              pagination={false}
              showHeader={false}
            />
          </div>
        </>
      );
    }
  }, [cluster.detVersion, resourcePools, cluster.appVersions?.mldm]);

  return (
    <Card className={css.container} extra={dropdown} size={'small'} title={meta}>
      <div className={css.content}>
        <Table
          columns={infoTableData.infoColumns}
          dataSource={infoTableData.infoSource}
          pagination={false}
          showHeader={false}
        />
        <div className={css.resourcePools}>{resourcePoolTable}</div>
      </div>
      <div className={css.buttonsWrapper}>
        {cluster.state.includes('fail') && (
          <Button
            onClick={() => {
              navigate(logPath);
            }}>
            Show Logs
          </Button>
        )}
        <Button
          //TODO: disable button when retry spinner is active
          disabled={
            disableClusterLinkByState(cluster.state as ClusterState) ||
            clusterRole === ClusterRole.None
          }
          key="open"
          type="primary"
          onClick={(event: AnyMouseEvent) => {
            handlePath(event, {
              external: true,
              onClick: undefined,
              path: cluster.appVersions?.mldm
                ? `https://${cluster.endpoint}`
                : `https://${cluster.endpoint}/det/login?version=${cluster.appVersions.mlde}`,
              popout: true,
            });
          }}>
          Open
        </Button>
      </div>
    </Card>
  );
};

export default ClusterCard;

import { ColumnsType } from 'antd/es/table';

export const auxCategories = ['c5'];
export const computeCategories = ['p2', 'p3', 'p4d', 'g4dn', 'g5'];
export const masterCategories = ['m5'];

export const KEY_COLUMN_NAME = 'instance';

interface InstanceTypeInfoBasic {
  [KEY_COLUMN_NAME]: string;
}
export interface InstanceTypeInfoCpu extends InstanceTypeInfoBasic {
  mem: number;
  network: string | number;
  storage: number | string;
  vcpu: number;
}
type InstanceTypeInfoCommon = Omit<InstanceTypeInfoCpu, 'storage'> &
  Partial<Pick<InstanceTypeInfoCpu, 'storage'>>;
interface InstanceTypeInfoGpu extends InstanceTypeInfoCommon {
  gpu: number;
  gpuMem: number;
  gpuP2p?: string;
}
export type InstanceTypeInfo = InstanceTypeInfoBasic | InstanceTypeInfoCpu | InstanceTypeInfoGpu;

export interface InstanceWithCategoryReference {
  [category: keyof InstanceTypeValuesByCategory]: {
    reference: string;
  };
}

interface InstanceTypeValuesByCategory {
  [categoryKey: string]: {
    columns: ColumnsType<InstanceTypeInfo>;
    description: string;
    values: {
      [size: string]: InstanceTypeInfo;
    };
  };
}

export const InstanceTypeReferences: InstanceWithCategoryReference = {
  p3dn: {
    reference: 'p3',
  },
  p4d: {
    reference: 'p4',
  },
};

export const InstanceTypeValues: InstanceTypeValuesByCategory = {
  c5: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Mem (GiB)',
      },
      {
        dataIndex: 'storage',
        title: 'Storage (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
    ],
    description: 'Optimized for compute-intensive workloads',
    values: {
      'c5.2xlarge': {
        instance: 'c5.2xlarge',
        mem: 16,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 8,
      },
      'c5.4xlarge': {
        instance: 'c5.4xlarge',
        mem: 32,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 16,
      },
      'c5.9xlarge': {
        instance: 'c5.9xlarge',
        mem: 72,
        network: '10',
        storage: 'EBS-Only',
        vcpu: 36,
      },
      'c5.12xlarge': {
        instance: 'c5.12xlarge',
        mem: 96,
        network: '12',
        storage: 'EBS-Only',
        vcpu: 48,
      },
      'c5.18xlarge': {
        instance: 'c5.18xlarge',
        mem: 144,
        network: '25',
        storage: 'EBS-Only',
        vcpu: 72,
      },
      'c5.24xlarge': {
        instance: 'c5.24xlarge',
        mem: 192,
        network: '25',
        storage: 'EBS-Only',
        vcpu: 96,
      },
      'c5.large': {
        instance: 'c5.large',
        mem: 4,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 2,
      },
      'c5.xlarge': {
        instance: 'c5.xlarge',
        mem: 8,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 4,
      },
    },
  },
  g4dn: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPUs',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Memory (GiB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'storage',
        title: 'Instance Storage (GB)',
      },
    ],
    description: 'Offering NVIDIA T4s, the most cost-effective cloud GPU option',
    values: {
      'g4dn.2xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: 'g4dn.2xlarge',
        mem: 32,
        storage: '1 x 225 NVMe SSD',
        vcpu: 8,
      },
      'g4dn.4xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: 'g4dn.4xlarge',
        mem: 64,
        storage: '1 x 225 NVMe SSD',
        vcpu: 16,
      },
      'g4dn.8xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: 'g4dn.8xlarge',
        mem: 128,
        storage: '1 x 900 NVMe SSD',
        vcpu: 32,
      },
      'g4dn.12xlarge': {
        gpu: 4,
        gpuMem: 64,
        instance: 'g4dn.12xlarge',
        mem: 192,
        storage: '1 x 900 NVMe SSD',
        vcpu: 48,
      },
      'g4dn.16xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: 'g4dn.16xlarge',
        mem: 256,
        storage: '1 x 900 NVMe SSD',
        vcpu: 64,
      },
      'g4dn.metal': {
        gpu: 8,
        gpuMem: 128,
        instance: 'g4dn.metal',
        mem: 384,
        storage: '2 x 900 NVMe SSD',
        vcpu: 96,
      },
      'g4dn.xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: 'g4dn.xlarge',
        mem: 16,
        storage: '1 x 125 NVMe SSD',
        vcpu: 4,
      },
    },
  },
  g5: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPUs',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'mem',
        title: 'Memory (GiB)',
      },
      {
        dataIndex: 'storage',
        title: 'Instance Storage (GB)',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPUs',
      },
    ],
    description:
      'Offering NVIDIA G5s, designed especially for machine learning inference workloads',
    values: {
      'g5.2xlarge': {
        gpu: 1,
        gpuMem: 24,
        instance: 'g5.2xlarge',
        mem: 32,
        storage: '1 x 450 NVMe SSD',
        vcpu: 8,
      },
      'g5.4xlarge': {
        gpu: 1,
        gpuMem: 24,
        instance: 'g5.4xlarge',
        mem: 64,
        storage: '1 x 600 NVMe SSD',
        vcpu: 16,
      },
      'g5.8xlarge': {
        gpu: 1,
        gpuMem: 24,
        instance: 'g5.8xlarge',
        mem: 128,
        storage: '1 x 900 NVMe SSD',
        vcpu: 32,
      },
      'g5.12xlarge': {
        gpu: 4,
        gpuMem: 96,
        instance: 'g5.12xlarge',
        mem: 192,
        storage: '1 x 3800 NVMe SSD',
        vcpu: 48,
      },
      'g5.16xlarge': {
        gpu: 1,
        gpuMem: 24,
        instance: 'g5.16xlarge',
        mem: 256,
        storage: '1 x 1900 NVMe SSD',
        vcpu: 64,
      },
      'g5.24xlarge': {
        gpu: 4,
        gpuMem: 96,
        instance: 'g5.24xlarge',
        mem: 384,
        storage: '1 x 3800 NVMe SSD',
        vcpu: 96,
      },
      'g5.48xlarge': {
        gpu: 8,
        gpuMem: 192,
        instance: 'g5.48xlarge',
        mem: 768,
        storage: '2 x 3800 NVMe SSD',
        vcpu: 192,
      },
    },
  },
  m5: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Mem (GiB)',
      },
      {
        dataIndex: 'storage',
        title: 'Storage (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
    ],
    description: 'Provides a balance of compute, memory, and network resources',
    values: {
      'm5.2xlarge': {
        instance: 'm5.2xlarge',
        mem: 32,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 8,
      },
      'm5.4xlarge': {
        instance: 'm5.4xlarge',
        mem: 64,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 16,
      },
      'm5.8xlarge': {
        instance: 'm5.8xlarge',
        mem: 128,
        network: 10,
        storage: 'EBS-Only',
        vcpu: 32,
      },
      'm5.12xlarge': {
        instance: 'm5.12xlarge',
        mem: 192,
        network: 12,
        storage: 'EBS-Only',
        vcpu: 48,
      },
      'm5.16xlarge': {
        instance: 'm5.16xlarge',
        mem: 256,
        network: 20,
        storage: 'EBS-Only',
        vcpu: 64,
      },
      'm5.24xlarge': {
        instance: 'm5.24xlarge',
        mem: 384,
        network: 25,
        storage: 'EBS-Only',
        vcpu: 96,
      },
      'm5.large': {
        instance: 'm5.large',
        mem: 8,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 2,
      },
      'm5.xlarge': {
        instance: 'm5.xlarge',
        mem: 16,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 4,
      },
    },
  },
  p2: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPU',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Mem (GiB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
    ],
    description: 'Offering NVIDIA K80s, the lowest cost-per-hour GPU option',
    values: {
      'p2.8xlarge': {
        gpu: 8,
        gpuMem: 96,
        instance: 'p2.8xlarge',
        mem: 488,
        network: 10,
        vcpu: 32,
      },
      'p2.16xlarge': {
        gpu: 16,
        gpuMem: 192,
        instance: 'p2.16xlarge',
        mem: 732,
        network: 25,
        vcpu: 64,
      },
      'p2.xlarge': {
        gpu: 1,
        gpuMem: 12,
        instance: 'p2.xlarge',
        mem: 61,
        network: 'High',
        vcpu: 4,
      },
    },
  },
  p3: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPU',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Mem (GiB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'gpuP2p',
        title: 'GPU P2P',
      },
      {
        dataIndex: 'storage',
        title: 'Storage (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
    ],
    description: 'Offering NVIDIA V100s, a higher performance GPU option',
    values: {
      'p3.2xlarge': {
        gpu: 1,
        gpuMem: 16,
        gpuP2p: '-',
        instance: 'p3.2xlarge',
        mem: 61,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 8,
      },
      'p3.8xlarge': {
        gpu: 4,
        gpuMem: 64,
        gpuP2p: 'NVLink',
        instance: 'p3.8xlarge',
        mem: 244,
        network: 10,
        storage: 'EBS-Only',
        vcpu: 32,
      },
      'p3.16xlarge': {
        gpu: 8,
        gpuMem: 128,
        gpuP2p: 'NVLink',
        instance: 'p3.16xlarge',
        mem: 488,
        network: 25,
        storage: 'EBS-Only',
        vcpu: 64,
      },
      'p3dn.24xlarge': {
        gpu: 8,
        gpuMem: 256,
        gpuP2p: 'NVLink',
        instance: 'p3dn.24xlarge',
        mem: 768,
        network: 100,
        storage: 'E2 x 900 NVMe SSD',
        vcpu: 96,
      },
    },
  },
  p4: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPUs',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPUs',
      },
      {
        dataIndex: 'mem',
        title: 'Instance Memory (GiB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'gpuP2p',
        title: 'GPU Peer to Peer',
      },
      {
        dataIndex: 'storage',
        title: 'Instance Storage (GB)',
      },
    ],
    description: 'Offering NVIDIA A100s, the highest-performance GPU option',
    values: {
      'p4d.24xlarge': {
        gpu: 8,
        gpuMem: 320,
        gpuP2p: '600 GB/s NVSwitch',
        instance: 'p4d.24xlarge',
        mem: 1152,
        storage: '8 x 1000 NVMe SSD',
        vcpu: 96,
      },
    },
  },
};

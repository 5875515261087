import Link from 'hew/Link';

import { useStore } from 'contexts/Store';
import { getOrganizationIdentifier } from 'utils/saas';

interface Props {
  nextDisabled: boolean;
}
export const Welcome: React.FC<Props> = ({ nextDisabled }) => {
  const {
    orgState: { selectedOrg },
  } = useStore();

  return (
    <div>
      {!nextDisabled ? (
        <div>
          Before you begin deploying new MLDE clusters, please take a moment to set up your{' '}
          {getOrganizationIdentifier()}. Click next to learn more about configuring your provider.
          For questions and support contact us at{' '}
          <Link external href={'mailto:ai-support@hpe.com'}>
            ai-support@hpe.com
          </Link>
        </div>
      ) : (
        <>
          It appears you do not have the necessary permissions to continue. Please contact an
          administrator of the {selectedOrg?.name} {getOrganizationIdentifier()} to continue or
          contact us at{' '}
          <Link external href={'mailto:ai-support@hpe.com'}>
            ai-support@hpe.com
          </Link>{' '}
          if this is unexpected.
        </>
      )}
    </div>
  );
};

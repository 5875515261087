import { throwCreateClusterError } from 'pages/Clusters/cluster-util';
import { ModelCreateClusterRequest } from 'services/regional-bindings';

export type KeyValuePair = { key: string; value: string };

export const validateClusterRequestObject = (
  clusterRequest: ModelCreateClusterRequest | undefined,
): void => {
  if (!clusterRequest) {
    throwCreateClusterError('Failed to create cluster.');
  } else {
    if (!clusterRequest.name) {
      throwCreateClusterError('Cluster name required');
    }
    if (!clusterRequest.detVersion && !clusterRequest.appVersions) {
      throwCreateClusterError('Version required');
    }
  }
};

// The validators below are based on k8s requirements:
// 1) https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
// 2) https://kubernetes.io/docs/concepts/overview/working-with-objects/annotations/#syntax-and-character-set

export const k8sAnnotationAndLabelKeyValidator = (
  key: string,
  currentIdx: number,
  allKeys: KeyValuePair[],
): Promise<string | void> => {
  if (!key) return Promise.reject();

  const keyExists = allKeys.some((it, index: number) => it.key === key && index !== currentIdx);
  if (keyExists) {
    return Promise.reject('Key already exists');
  }

  const prefixPattern = /^[a-z0-9]([-a-z0-9.]{0,251}[a-z0-9])?$/;
  const namePattern = /^[a-z0-9A-Z]([a-z0-9A-Z-_.]{0,61}[a-z0-9A-Z])?$/;
  const invalidKeyMessage = 'Key is not valid';

  const parts = key.split('/');
  if (parts.length > 2) return Promise.reject(invalidKeyMessage);

  if (parts.length === 2) {
    const prefix = parts[0];

    if (/^(.*\.)?(kubernetes\.io|k8s\.io)$/.test(prefix)) {
      return Promise.reject('Key is reserved');
    }

    const name = parts[1];

    if (prefixPattern.test(prefix) && namePattern.test(name)) {
      return Promise.resolve();
    }

    return Promise.reject(invalidKeyMessage);
  }

  if (namePattern.test(parts[0])) {
    return Promise.resolve();
  }

  return Promise.reject(invalidKeyMessage);
};

export const k8sLabelValueValidator = (value: string): Promise<string | void> => {
  const valuePattern = /^([a-z0-9A-Z]([a-z0-9A-Z-_.]{0,61}[a-z0-9A-Z])?)?$/;
  if (valuePattern.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Value is not valid');
};

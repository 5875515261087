import Avatar, { Props } from 'hew/Avatar';
import React from 'react';

import css from './AvatarCard.module.scss';

export interface AvatarCardProps extends Props {
  className?: string; // Replace 'string' with the actual type of the property
}

const AvatarCard: React.FC<AvatarCardProps> = ({
  className,
  text,
  ...avatarProps
}: AvatarCardProps) => {
  return (
    <div className={`${css.base} ${className || ''}`}>
      <Avatar {...avatarProps} hideTooltip text={text} />
      <span>{text}</span>
    </div>
  );
};

export default AvatarCard;

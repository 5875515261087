/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * HPE Machine Learning Development Environment Software - Global Management API
 * This service is
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://global.dev.det-cloud.net:4930/v0".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ModelAwsSupportMatrix
 */
export interface ModelAwsSupportMatrix {
    /**
     * 
     * @type {string}
     * @memberof ModelAwsSupportMatrix
     */
    defaultIpRange: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelAwsSupportMatrix
     */
    defaultSubnetRanges: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: ModelInstanceSpecs; }; }}
     * @memberof ModelAwsSupportMatrix
     */
    supportedAgentTypes: { [key: string]: { [key: string]: ModelInstanceSpecs; }; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelAwsSupportMatrix
     */
    supportedMasterTypes: { [key: string]: Array<string>; };
}

/**
 * 
 * @export
 * @interface ModelBackendProvider
 */
export interface ModelBackendProvider {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelBackendProvider
     */
    connectionInfo: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ModelBackendProvider
     */
    type: string;
}

/**
 * 
 * @export
 * @interface ModelChangeOrgOwnerRequest
 */
export interface ModelChangeOrgOwnerRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelChangeOrgOwnerRequest
     */
    ownerId?: string;
}

/**
 * 
 * @export
 * @interface ModelClusterAccessInfo
 */
export interface ModelClusterAccessInfo {
    /**
     * 
     * @type {boolean}
     * @memberof ModelClusterAccessInfo
     */
    active: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelClusterAccessInfo
     */
    appVersions: { [key: string]: string; };
    /**
     * TODO: drop detVersion from API
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    detVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    endpoint: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelClusterAccessInfo
     */
    errors: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    masterInstanceType: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    orgRole: string;
    /**
     * 
     * @type {Array<ModelResourcePoolConfig>}
     * @memberof ModelClusterAccessInfo
     */
    resourcePools: Array<ModelResourcePoolConfig>;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    state: string;
}

/**
 * 
 * @export
 * @interface ModelClusterInfo
 */
export interface ModelClusterInfo {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelClusterInfo
     */
    appVersions: { [key: string]: string; };
    /**
     * TODO: drop detVersion from API
     * @type {string}
     * @memberof ModelClusterInfo
     */
    detVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    endpoint: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelClusterInfo
     */
    errors: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    masterInstanceType: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    name: string;
    /**
     * 
     * @type {Array<ModelResourcePoolConfig>}
     * @memberof ModelClusterInfo
     */
    resourcePools: Array<ModelResourcePoolConfig>;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    state: string;
}

/**
 * 
 * @export
 * @interface ModelClusterUser
 */
export interface ModelClusterUser {
    /**
     * 
     * @type {boolean}
     * @memberof ModelClusterUser
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    orgRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelCreateBackendProviderResponse
 */
export interface ModelCreateBackendProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelCreateBackendProviderResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateBackendProviderResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateBackendProviderResponse
     */
    requestId: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelCreateBackendProviderResponse
     */
    warnings: { [key: string]: Array<string>; };
}

/**
 * 
 * @export
 * @interface ModelCreateOrgRequest
 */
export interface ModelCreateOrgRequest {
    /**
     * 
     * @type {ModelBackendProvider}
     * @memberof ModelCreateOrgRequest
     */
    backendProvider: ModelBackendProvider;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgRequest
     */
    nextOrgGuid: string;
}

/**
 * 
 * @export
 * @interface ModelCreateOrgResponse
 */
export interface ModelCreateOrgResponse {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelCreateOrgResponse
     */
    backendProviderWarnings: { [key: string]: Array<string>; };
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelErrorRESTResponse
 */
export interface ModelErrorRESTResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    error: string;
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelFullBackendProviderEntry
 */
export interface ModelFullBackendProviderEntry {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelFullBackendProviderEntry
     */
    connectionInfo: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ModelFullBackendProviderEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFullBackendProviderEntry
     */
    type: string;
}

/**
 * 
 * @export
 * @interface ModelGetClusterOverridesResponse
 */
export interface ModelGetClusterOverridesResponse {
    /**
     * 
     * @type {{ [key: string]: Array<ModelClusterUser>; }}
     * @memberof ModelGetClusterOverridesResponse
     */
    clusters: { [key: string]: Array<ModelClusterUser>; };
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterOverridesResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterOverridesResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetClusterUsersResponse
 */
export interface ModelGetClusterUsersResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterUsersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterUsersResponse
     */
    requestId: string;
    /**
     * 
     * @type {Array<ModelClusterUser>}
     * @memberof ModelGetClusterUsersResponse
     */
    users: Array<ModelClusterUser>;
}

/**
 * 
 * @export
 * @interface ModelGetInvalidationResponse
 */
export interface ModelGetInvalidationResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelGetInvalidationResponse
     */
    defaultTime?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof ModelGetInvalidationResponse
     */
    invalidationTimes?: { [key: string]: { [key: string]: string; }; };
    /**
     * 
     * @type {string}
     * @memberof ModelGetInvalidationResponse
     */
    lastUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetInvalidationResponse
     */
    location: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelGetInvalidationResponse
     */
    overrides?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ModelGetInvalidationResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetInvitedUsersResponse
 */
export interface ModelGetInvitedUsersResponse {
    /**
     * 
     * @type {Array<ModelInvitedUser>}
     * @memberof ModelGetInvitedUsersResponse
     */
    invitedUsers: Array<ModelInvitedUser>;
    /**
     * 
     * @type {string}
     * @memberof ModelGetInvitedUsersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetInvitedUsersResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetOrgBackendProviderDataResponse
 */
export interface ModelGetOrgBackendProviderDataResponse {
    /**
     * 
     * @type {Array<ModelFullBackendProviderEntry>}
     * @memberof ModelGetOrgBackendProviderDataResponse
     */
    backendProviders: Array<ModelFullBackendProviderEntry>;
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgBackendProviderDataResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgBackendProviderDataResponse
     */
    orgGuid: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgBackendProviderDataResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetOrgBackendProvidersResponse
 */
export interface ModelGetOrgBackendProvidersResponse {
    /**
     * 
     * @type {Array<ModelPartialBackendProviderEntry>}
     * @memberof ModelGetOrgBackendProvidersResponse
     */
    backendProviders: Array<ModelPartialBackendProviderEntry>;
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgBackendProvidersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgBackendProvidersResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetOrgUsersResponse
 */
export interface ModelGetOrgUsersResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgUsersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgUsersResponse
     */
    requestId: string;
    /**
     * 
     * @type {Array<ModelOrgUser>}
     * @memberof ModelGetOrgUsersResponse
     */
    users: Array<ModelOrgUser>;
}

/**
 * 
 * @export
 * @interface ModelGetSupportMatrixResponse
 */
export interface ModelGetSupportMatrixResponse {
    /**
     * 
     * @type {ModelAwsSupportMatrix}
     * @memberof ModelGetSupportMatrixResponse
     */
    awsSupportMatrix: ModelAwsSupportMatrix;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGetSupportMatrixResponse
     */
    byokEnabled: boolean;
    /**
     * 
     * @type {ModelGkeSupportMatrix}
     * @memberof ModelGetSupportMatrixResponse
     */
    gkeSupportMatrix: ModelGkeSupportMatrix;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGetSupportMatrixResponse
     */
    glcpEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelGetSupportMatrixResponse
     */
    location: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGetSupportMatrixResponse
     */
    newOrgs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGetSupportMatrixResponse
     */
    orgQuotasEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelGetSupportMatrixResponse
     */
    requestId: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelGetSupportMatrixResponse
     */
    supportedAppVersions: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelGetSupportMatrixResponse
     */
    supportedDetVersions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelGetSupportMatrixResponse
     */
    supportedLocations: Array<string>;
}

/**
 * 
 * @export
 * @interface ModelGetUserClustersResponse
 */
export interface ModelGetUserClustersResponse {
    /**
     * 
     * @type {Array<ModelClusterAccessInfo>}
     * @memberof ModelGetUserClustersResponse
     */
    clusters: Array<ModelClusterAccessInfo>;
    /**
     * 
     * @type {string}
     * @memberof ModelGetUserClustersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetUserClustersResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGkeMachineDependentAcceleratorSpec
 */
export interface ModelGkeMachineDependentAcceleratorSpec {
    /**
     * 
     * @type {number}
     * @memberof ModelGkeMachineDependentAcceleratorSpec
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof ModelGkeMachineDependentAcceleratorSpec
     */
    machineType: string;
}

/**
 * 
 * @export
 * @interface ModelGkeMachineSeriesSpec
 */
export interface ModelGkeMachineSeriesSpec {
    /**
     * 
     * @type {boolean}
     * @memberof ModelGkeMachineSeriesSpec
     */
    machineDependent: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelGkeMachineSeriesSpec
     */
    series: string;
}

/**
 * 
 * @export
 * @interface ModelGkeSupportMatrix
 */
export interface ModelGkeSupportMatrix {
    /**
     * 
     * @type {string}
     * @memberof ModelGkeSupportMatrix
     */
    defaultIpRange: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelGkeSupportMatrix
     */
    defaultSubnetRanges: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: Array<ModelGkeMachineDependentAcceleratorSpec>; }}
     * @memberof ModelGkeSupportMatrix
     */
    machineDependentAcceleratorCounts: { [key: string]: Array<ModelGkeMachineDependentAcceleratorSpec>; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof ModelGkeSupportMatrix
     */
    machineIndependentAcceleratorCounts: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelGkeSupportMatrix
     */
    supportedAcceleratorTypes: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelGkeSupportMatrix
     */
    supportedDirectorTypes: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: Array<ModelGkeMachineSeriesSpec>; }}
     * @memberof ModelGkeSupportMatrix
     */
    supportedMachineTypeSeries: { [key: string]: Array<ModelGkeMachineSeriesSpec>; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: Array<string>; }; }}
     * @memberof ModelGkeSupportMatrix
     */
    supportedMachineTypes: { [key: string]: { [key: string]: Array<string>; }; };
}

/**
 * 
 * @export
 * @interface ModelGlobalHealthCheckResponse
 */
export interface ModelGlobalHealthCheckResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelGlobalHealthCheckResponse
     */
    location: string;
    /**
     * 
     * @type {number}
     * @memberof ModelGlobalHealthCheckResponse
     */
    numKanbanOrgs: number;
    /**
     * 
     * @type {number}
     * @memberof ModelGlobalHealthCheckResponse
     */
    orgKanbanSize: number;
    /**
     * 
     * @type {string}
     * @memberof ModelGlobalHealthCheckResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelInstanceSpecs
 */
export interface ModelInstanceSpecs {
    /**
     * 
     * @type {number}
     * @memberof ModelInstanceSpecs
     */
    gpus: number;
    /**
     * 
     * @type {number}
     * @memberof ModelInstanceSpecs
     */
    vcpus: number;
}

/**
 * 
 * @export
 * @interface ModelInstanceType
 */
export interface ModelInstanceType {
    /**
     * 
     * @type {number}
     * @memberof ModelInstanceType
     */
    gpu_num?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInstanceType
     */
    gpu_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInstanceType
     */
    machine_type: string;
}

/**
 * 
 * @export
 * @interface ModelInviteOrgUserRequest
 */
export interface ModelInviteOrgUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelInviteOrgUserRequest
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInviteOrgUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInviteOrgUserRequest
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelInvitedUser
 */
export interface ModelInvitedUser {
    /**
     * 
     * @type {string}
     * @memberof ModelInvitedUser
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInvitedUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInvitedUser
     */
    expiry: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInvitedUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInvitedUser
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelJWT
 */
export interface ModelJWT {
    /**
     * 
     * @type {string}
     * @memberof ModelJWT
     */
    aud?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelJWT
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof ModelJWT
     */
    exp?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelJWT
     */
    iat?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelJWT
     */
    iss?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelJWT
     */
    jti?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelJWT
     */
    mgmt: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelJWT
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ModelJWT
     */
    nbf?: number;
    /**
     * 
     * @type {{ [key: string]: ModelOrgRoleClaims; }}
     * @memberof ModelJWT
     */
    orgRoles: { [key: string]: ModelOrgRoleClaims; };
    /**
     * 
     * @type {string}
     * @memberof ModelJWT
     */
    sub?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelJWT
     */
    superAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelJWT
     */
    userId: string;
}

/**
 * 
 * @export
 * @interface ModelListClustersResponse
 */
export interface ModelListClustersResponse {
    /**
     * 
     * @type {Array<ModelClusterInfo>}
     * @memberof ModelListClustersResponse
     */
    clusters: Array<ModelClusterInfo>;
    /**
     * 
     * @type {string}
     * @memberof ModelListClustersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListClustersResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelListOrgsEntry
 */
export interface ModelListOrgsEntry {
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsEntry
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsEntry
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelListOrgsEntry
     */
    isSuperAdminOrg: boolean;
    /**
     * 
     * @type {ModelUserLicensingStatus}
     * @memberof ModelListOrgsEntry
     */
    licensingStatus: ModelUserLicensingStatus;
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsEntry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsEntry
     */
    ownerId: string;
    /**
     * 
     * @type {ModelOrgQuotas}
     * @memberof ModelListOrgsEntry
     */
    quotas: ModelOrgQuotas;
}

/**
 * 
 * @export
 * @interface ModelListOrgsResponse
 */
export interface ModelListOrgsResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsResponse
     */
    nextOrgGuid: string;
    /**
     * 
     * @type {Array<ModelListOrgsEntry>}
     * @memberof ModelListOrgsResponse
     */
    orgs: Array<ModelListOrgsEntry>;
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelListUserInvitesResponse
 */
export interface ModelListUserInvitesResponse {
    /**
     * 
     * @type {Array<ModelUserInvite>}
     * @memberof ModelListUserInvitesResponse
     */
    invites: Array<ModelUserInvite>;
    /**
     * 
     * @type {string}
     * @memberof ModelListUserInvitesResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListUserInvitesResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelOrgInfoResponse
 */
export interface ModelOrgInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelOrgInfoResponse
     */
    location: string;
    /**
     * 
     * @type {ModelListOrgsEntry}
     * @memberof ModelOrgInfoResponse
     */
    org: ModelListOrgsEntry;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgInfoResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelOrgQuotas
 */
export interface ModelOrgQuotas {
    /**
     * 
     * @type {number}
     * @memberof ModelOrgQuotas
     */
    cpuQuota?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelOrgQuotas
     */
    gpuQuota?: number;
}

/**
 * 
 * @export
 * @interface ModelOrgQuotasResponse
 */
export interface ModelOrgQuotasResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelOrgQuotasResponse
     */
    location: string;
    /**
     * 
     * @type {ModelOrgQuotas}
     * @memberof ModelOrgQuotasResponse
     */
    quotas: ModelOrgQuotas;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgQuotasResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelOrgRoleClaims
 */
export interface ModelOrgRoleClaims {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ModelOrgRoleClaims
     */
    clusterActivations: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelOrgRoleClaims
     */
    clusterRoles: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ModelOrgRoleClaims
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgRoleClaims
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelOrgUser
 */
export interface ModelOrgUser {
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelPartialBackendProviderEntry
 */
export interface ModelPartialBackendProviderEntry {
    /**
     * 
     * @type {string}
     * @memberof ModelPartialBackendProviderEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPartialBackendProviderEntry
     */
    type: string;
}

/**
 * 
 * @export
 * @interface ModelProviderConfig
 */
export interface ModelProviderConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ModelProviderConfig
     */
    cpu_slots_allowed: boolean;
    /**
     * 
     * @type {ModelInstanceType}
     * @memberof ModelProviderConfig
     */
    instance_type: ModelInstanceType;
    /**
     * 
     * @type {string}
     * @memberof ModelProviderConfig
     */
    max_idle_agent_period?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelProviderConfig
     */
    max_instances: number;
    /**
     * defaults to 0 if not set in request
     * @type {number}
     * @memberof ModelProviderConfig
     */
    min_instances?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelProviderConfig
     */
    type: string;
}

/**
 * 
 * @export
 * @interface ModelRESTResponse
 */
export interface ModelRESTResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelRESTResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRESTResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelResourcePoolConfig
 */
export interface ModelResourcePoolConfig {
    /**
     * optional, specific to byok8s
     * @type {number}
     * @memberof ModelResourcePoolConfig
     */
    gpu_total?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelResourcePoolConfig
     */
    max_aux_containers_per_agent: number;
    /**
     * 
     * @type {string}
     * @memberof ModelResourcePoolConfig
     */
    pool_name: string;
    /**
     * 
     * @type {ModelProviderConfig}
     * @memberof ModelResourcePoolConfig
     */
    provider: ModelProviderConfig;
}

/**
 * 
 * @export
 * @interface ModelTrialSignUpRequest
 */
export interface ModelTrialSignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelTrialSignUpRequest
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTrialSignUpRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTrialSignUpRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTrialSignUpRequest
     */
    lastName: string;
}

/**
 * 
 * @export
 * @interface ModelUpdateBackendProviderResponse
 */
export interface ModelUpdateBackendProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateBackendProviderResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateBackendProviderResponse
     */
    requestId: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelUpdateBackendProviderResponse
     */
    warnings: { [key: string]: Array<string>; };
}

/**
 * 
 * @export
 * @interface ModelUpdateClusterUserRequest
 */
export interface ModelUpdateClusterUserRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ModelUpdateClusterUserRequest
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateClusterUserRequest
     */
    role?: string;
}

/**
 * 
 * @export
 * @interface ModelUpdateLicenseRequest
 */
export interface ModelUpdateLicenseRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateLicenseRequest
     */
    expirationDate: string;
}

/**
 * 
 * @export
 * @interface ModelUpdateOrgQuotasRequest
 */
export interface ModelUpdateOrgQuotasRequest {
    /**
     * 
     * @type {ModelOrgQuotas}
     * @memberof ModelUpdateOrgQuotasRequest
     */
    quotas: ModelOrgQuotas;
}

/**
 * 
 * @export
 * @interface ModelUpdateOrgRequest
 */
export interface ModelUpdateOrgRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateOrgRequest
     */
    name: string;
}

/**
 * 
 * @export
 * @interface ModelUpdateOrgUserRequest
 */
export interface ModelUpdateOrgUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateOrgUserRequest
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateOrgUserRequest
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelUserInvite
 */
export interface ModelUserInvite {
    /**
     * 
     * @type {string}
     * @memberof ModelUserInvite
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUserInvite
     */
    expiry: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUserInvite
     */
    inviteId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUserInvite
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUserInvite
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUserInvite
     */
    orgRole: string;
}

/**
 * 
 * @export
 * @interface ModelUserLicensingStatus
 */
export interface ModelUserLicensingStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ModelUserLicensingStatus
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelUserLicensingStatus
     */
    licenseExpiresOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUserLicensingStatus
     */
    trialExpiresOn?: string;
}

/**
 * 
 * @export
 * @interface ModelUserLicensingStatusResponse
 */
export interface ModelUserLicensingStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ModelUserLicensingStatusResponse
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelUserLicensingStatusResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUserLicensingStatusResponse
     */
    requestId: string;
    /**
     * 
     * @type {ModelUserLicensingStatus}
     * @memberof ModelUserLicensingStatusResponse
     */
    userLicensingStatus: ModelUserLicensingStatus;
}


/**
 * AuthzApi - fetch parameter creator
 * @export
 */
export const AuthzApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * cancel an existing organization invite
         * @summary Cancel an existing organization invite
         * @param {string} orgId organization ID
         * @param {string} inviteId invite id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrgInvite(orgId: string, inviteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling cancelOrgInvite.');
            }
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling cancelOrgInvite.');
            }
            const localVarPath = `/orgs/{orgId}/invites/{inviteId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new backend provider entry for requested organization
         * @summary Create a new backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {ModelBackendProvider} backendProvider Backend provider specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackendProvider(orgId: string, backendProvider: ModelBackendProvider, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling createBackendProvider.');
            }
            // verify required parameter 'backendProvider' is not null or undefined
            if (backendProvider === null || backendProvider === undefined) {
                throw new RequiredError('backendProvider','Required parameter backendProvider was null or undefined when calling createBackendProvider.');
            }
            const localVarPath = `/orgs/{orgId}/backend-providers`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelBackendProvider" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(backendProvider || {}) : (backendProvider || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a backend provider entry for requested organization
         * @summary Deletes a backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {string} backendProviderId backend provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackendProvider(orgId: string, backendProviderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteBackendProvider.');
            }
            // verify required parameter 'backendProviderId' is not null or undefined
            if (backendProviderId === null || backendProviderId === undefined) {
                throw new RequiredError('backendProviderId','Required parameter backendProviderId was null or undefined when calling deleteBackendProvider.');
            }
            const localVarPath = `/orgs/{orgId}/backend-providers/{backendProviderId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"backendProviderId"}}`, encodeURIComponent(String(backendProviderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes a cluster-level role setting for a user
         * @summary Delete a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterOverride(orgId: string, clusterId: string, userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteClusterOverride.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling deleteClusterOverride.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteClusterOverride.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/users/{userId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a user from an organization
         * @summary Delete a user from an organization
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrgUser(orgId: string, userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteOrgUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteOrgUser.');
            }
            const localVarPath = `/orgs/{orgId}/users/{userId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns all of the explicitly set cluster roles in an organization
         * @summary List all cluster access overrides
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterOverrides(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getClusterOverrides.');
            }
            const localVarPath = `/orgs/{orgId}/cluster-authz`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the effective list of users who can access a cluster after overrides AND defaults are considered
         * @summary Get the list of users with access to a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterUsers(orgId: string, clusterId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getClusterUsers.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling getClusterUsers.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/users`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List time before which token should be treated as invalid for each user.
         * @summary List invalidation data for specified users.
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidation(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getInvalidation.');
            }
            const localVarPath = `/orgs/{orgId}/invalidation`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List time before which token should be treated as invalid for each user.
         * @summary List invalidation data for all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidationAll(options: any = {}): FetchArgs {
            const localVarPath = `/invalidation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns full backend provider entries for the requested organization
         * @summary Returns full backend provider entries for the requested organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgBackendProviderData(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrgBackendProviderData.');
            }
            const localVarPath = `/orgs/{orgId}/backend-provider-data`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns partial backend provider entries for requested organization
         * @summary Returns partial backend provider entries for requested organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgBackendProviders(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrgBackendProviders.');
            }
            const localVarPath = `/orgs/{orgId}/backend-providers`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * cancel an existing organization invite
         * @summary Cancel an existing organization invite
         * @param {string} orgId organization id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgInvites(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrgInvites.');
            }
            const localVarPath = `/orgs/{orgId}/invites`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list users and their access levels in the organization
         * @summary List users and their access levels in the organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgUsers(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrgUsers.');
            }
            const localVarPath = `/orgs/{orgId}/users`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the effective list of clusters a user can access after overrides AND defaults are considered
         * @summary Get the list of clusters a user can access
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClusters(orgId: string, userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getUserClusters.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserClusters.');
            }
            const localVarPath = `/orgs/{orgId}/users/{userId}/cluster-authz`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * invites a user to join an organization
         * @summary Invite a user to join an organization
         * @param {string} orgId organization ID
         * @param {ModelInviteOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteOrgUser(orgId: string, orgUser: ModelInviteOrgUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling inviteOrgUser.');
            }
            // verify required parameter 'orgUser' is not null or undefined
            if (orgUser === null || orgUser === undefined) {
                throw new RequiredError('orgUser','Required parameter orgUser was null or undefined when calling inviteOrgUser.');
            }
            const localVarPath = `/orgs/{orgId}/invites`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelInviteOrgUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(orgUser || {}) : (orgUser || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get an updated JWT
         * @summary Get an updated JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJWT(options: any = {}): FetchArgs {
            const localVarPath = `/jwt`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a backend provider entry for requested organization
         * @summary Updates a backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {string} backendProviderId backend provider ID
         * @param {ModelBackendProvider} backendProvider backend provider specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBackendProvider(orgId: string, backendProviderId: string, backendProvider: ModelBackendProvider, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling updateBackendProvider.');
            }
            // verify required parameter 'backendProviderId' is not null or undefined
            if (backendProviderId === null || backendProviderId === undefined) {
                throw new RequiredError('backendProviderId','Required parameter backendProviderId was null or undefined when calling updateBackendProvider.');
            }
            // verify required parameter 'backendProvider' is not null or undefined
            if (backendProvider === null || backendProvider === undefined) {
                throw new RequiredError('backendProvider','Required parameter backendProvider was null or undefined when calling updateBackendProvider.');
            }
            const localVarPath = `/orgs/{orgId}/backend-providers/{backendProviderId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"backendProviderId"}}`, encodeURIComponent(String(backendProviderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelBackendProvider" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(backendProvider || {}) : (backendProvider || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sets a specific cluster-level role for a user
         * @summary Set a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {ModelUpdateClusterUserRequest} role user-level settings for cluster access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterUser(orgId: string, clusterId: string, userId: string, role: ModelUpdateClusterUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling updateClusterUser.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling updateClusterUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateClusterUser.');
            }
            // verify required parameter 'role' is not null or undefined
            if (role === null || role === undefined) {
                throw new RequiredError('role','Required parameter role was null or undefined when calling updateClusterUser.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/users/{userId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelUpdateClusterUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(role || {}) : (role || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update organization-level access settings for user
         * @summary Update organization-level access settings for user
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {ModelUpdateOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgUser(orgId: string, userId: string, orgUser: ModelUpdateOrgUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling updateOrgUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateOrgUser.');
            }
            // verify required parameter 'orgUser' is not null or undefined
            if (orgUser === null || orgUser === undefined) {
                throw new RequiredError('orgUser','Required parameter orgUser was null or undefined when calling updateOrgUser.');
            }
            const localVarPath = `/orgs/{orgId}/users/{userId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelUpdateOrgUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(orgUser || {}) : (orgUser || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets user JWT info for the UI to consume
         * @summary Gets user JWT info for the UI to consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfo(options: any = {}): FetchArgs {
            const localVarPath = `/userinfo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthzApi - functional programming interface
 * @export
 */
export const AuthzApiFp = function(configuration?: Configuration) {
    return {
        /**
         * cancel an existing organization invite
         * @summary Cancel an existing organization invite
         * @param {string} orgId organization ID
         * @param {string} inviteId invite id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrgInvite(orgId: string, inviteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).cancelOrgInvite(orgId, inviteId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * create a new backend provider entry for requested organization
         * @summary Create a new backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {ModelBackendProvider} backendProvider Backend provider specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackendProvider(orgId: string, backendProvider: ModelBackendProvider, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelCreateBackendProviderResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).createBackendProvider(orgId, backendProvider, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Deletes a backend provider entry for requested organization
         * @summary Deletes a backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {string} backendProviderId backend provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackendProvider(orgId: string, backendProviderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).deleteBackendProvider(orgId, backendProviderId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * deletes a cluster-level role setting for a user
         * @summary Delete a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterOverride(orgId: string, clusterId: string, userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).deleteClusterOverride(orgId, clusterId, userId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * delete a user from an organization
         * @summary Delete a user from an organization
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrgUser(orgId: string, userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).deleteOrgUser(orgId, userId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns all of the explicitly set cluster roles in an organization
         * @summary List all cluster access overrides
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterOverrides(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetClusterOverridesResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getClusterOverrides(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns the effective list of users who can access a cluster after overrides AND defaults are considered
         * @summary Get the list of users with access to a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterUsers(orgId: string, clusterId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetClusterUsersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getClusterUsers(orgId, clusterId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * List time before which token should be treated as invalid for each user.
         * @summary List invalidation data for specified users.
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidation(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetInvalidationResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getInvalidation(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * List time before which token should be treated as invalid for each user.
         * @summary List invalidation data for all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidationAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetInvalidationResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getInvalidationAll(options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns full backend provider entries for the requested organization
         * @summary Returns full backend provider entries for the requested organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgBackendProviderData(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetOrgBackendProviderDataResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getOrgBackendProviderData(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns partial backend provider entries for requested organization
         * @summary Returns partial backend provider entries for requested organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgBackendProviders(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetOrgBackendProvidersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getOrgBackendProviders(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * cancel an existing organization invite
         * @summary Cancel an existing organization invite
         * @param {string} orgId organization id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgInvites(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetInvitedUsersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getOrgInvites(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * list users and their access levels in the organization
         * @summary List users and their access levels in the organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgUsers(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetOrgUsersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getOrgUsers(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns the effective list of clusters a user can access after overrides AND defaults are considered
         * @summary Get the list of clusters a user can access
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClusters(orgId: string, userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetUserClustersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getUserClusters(orgId, userId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * invites a user to join an organization
         * @summary Invite a user to join an organization
         * @param {string} orgId organization ID
         * @param {ModelInviteOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteOrgUser(orgId: string, orgUser: ModelInviteOrgUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListClustersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).inviteOrgUser(orgId, orgUser, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get an updated JWT
         * @summary Get an updated JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJWT(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).refreshJWT(options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Updates a backend provider entry for requested organization
         * @summary Updates a backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {string} backendProviderId backend provider ID
         * @param {ModelBackendProvider} backendProvider backend provider specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBackendProvider(orgId: string, backendProviderId: string, backendProvider: ModelBackendProvider, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelUpdateBackendProviderResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).updateBackendProvider(orgId, backendProviderId, backendProvider, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * sets a specific cluster-level role for a user
         * @summary Set a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {ModelUpdateClusterUserRequest} role user-level settings for cluster access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterUser(orgId: string, clusterId: string, userId: string, role: ModelUpdateClusterUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).updateClusterUser(orgId, clusterId, userId, role, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * update organization-level access settings for user
         * @summary Update organization-level access settings for user
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {ModelUpdateOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgUser(orgId: string, userId: string, orgUser: ModelUpdateOrgUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListClustersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).updateOrgUser(orgId, userId, orgUser, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets user JWT info for the UI to consume
         * @summary Gets user JWT info for the UI to consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelJWT> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).userInfo(options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthzApi - factory interface
 * @export
 */
export const AuthzApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * cancel an existing organization invite
         * @summary Cancel an existing organization invite
         * @param {string} orgId organization ID
         * @param {string} inviteId invite id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrgInvite(orgId: string, inviteId: string, options?: any) {
            return AuthzApiFp(configuration).cancelOrgInvite(orgId, inviteId, options)(fetch, basePath);
        },
        /**
         * create a new backend provider entry for requested organization
         * @summary Create a new backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {ModelBackendProvider} backendProvider Backend provider specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackendProvider(orgId: string, backendProvider: ModelBackendProvider, options?: any) {
            return AuthzApiFp(configuration).createBackendProvider(orgId, backendProvider, options)(fetch, basePath);
        },
        /**
         * Deletes a backend provider entry for requested organization
         * @summary Deletes a backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {string} backendProviderId backend provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackendProvider(orgId: string, backendProviderId: string, options?: any) {
            return AuthzApiFp(configuration).deleteBackendProvider(orgId, backendProviderId, options)(fetch, basePath);
        },
        /**
         * deletes a cluster-level role setting for a user
         * @summary Delete a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterOverride(orgId: string, clusterId: string, userId: string, options?: any) {
            return AuthzApiFp(configuration).deleteClusterOverride(orgId, clusterId, userId, options)(fetch, basePath);
        },
        /**
         * delete a user from an organization
         * @summary Delete a user from an organization
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrgUser(orgId: string, userId: string, options?: any) {
            return AuthzApiFp(configuration).deleteOrgUser(orgId, userId, options)(fetch, basePath);
        },
        /**
         * returns all of the explicitly set cluster roles in an organization
         * @summary List all cluster access overrides
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterOverrides(orgId: string, options?: any) {
            return AuthzApiFp(configuration).getClusterOverrides(orgId, options)(fetch, basePath);
        },
        /**
         * returns the effective list of users who can access a cluster after overrides AND defaults are considered
         * @summary Get the list of users with access to a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterUsers(orgId: string, clusterId: string, options?: any) {
            return AuthzApiFp(configuration).getClusterUsers(orgId, clusterId, options)(fetch, basePath);
        },
        /**
         * List time before which token should be treated as invalid for each user.
         * @summary List invalidation data for specified users.
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidation(orgId: string, options?: any) {
            return AuthzApiFp(configuration).getInvalidation(orgId, options)(fetch, basePath);
        },
        /**
         * List time before which token should be treated as invalid for each user.
         * @summary List invalidation data for all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidationAll(options?: any) {
            return AuthzApiFp(configuration).getInvalidationAll(options)(fetch, basePath);
        },
        /**
         * Returns full backend provider entries for the requested organization
         * @summary Returns full backend provider entries for the requested organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgBackendProviderData(orgId: string, options?: any) {
            return AuthzApiFp(configuration).getOrgBackendProviderData(orgId, options)(fetch, basePath);
        },
        /**
         * Returns partial backend provider entries for requested organization
         * @summary Returns partial backend provider entries for requested organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgBackendProviders(orgId: string, options?: any) {
            return AuthzApiFp(configuration).getOrgBackendProviders(orgId, options)(fetch, basePath);
        },
        /**
         * cancel an existing organization invite
         * @summary Cancel an existing organization invite
         * @param {string} orgId organization id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgInvites(orgId: string, options?: any) {
            return AuthzApiFp(configuration).getOrgInvites(orgId, options)(fetch, basePath);
        },
        /**
         * list users and their access levels in the organization
         * @summary List users and their access levels in the organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgUsers(orgId: string, options?: any) {
            return AuthzApiFp(configuration).getOrgUsers(orgId, options)(fetch, basePath);
        },
        /**
         * returns the effective list of clusters a user can access after overrides AND defaults are considered
         * @summary Get the list of clusters a user can access
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClusters(orgId: string, userId: string, options?: any) {
            return AuthzApiFp(configuration).getUserClusters(orgId, userId, options)(fetch, basePath);
        },
        /**
         * invites a user to join an organization
         * @summary Invite a user to join an organization
         * @param {string} orgId organization ID
         * @param {ModelInviteOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteOrgUser(orgId: string, orgUser: ModelInviteOrgUserRequest, options?: any) {
            return AuthzApiFp(configuration).inviteOrgUser(orgId, orgUser, options)(fetch, basePath);
        },
        /**
         * get an updated JWT
         * @summary Get an updated JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJWT(options?: any) {
            return AuthzApiFp(configuration).refreshJWT(options)(fetch, basePath);
        },
        /**
         * Updates a backend provider entry for requested organization
         * @summary Updates a backend provider entry for requested organization
         * @param {string} orgId organization ID
         * @param {string} backendProviderId backend provider ID
         * @param {ModelBackendProvider} backendProvider backend provider specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBackendProvider(orgId: string, backendProviderId: string, backendProvider: ModelBackendProvider, options?: any) {
            return AuthzApiFp(configuration).updateBackendProvider(orgId, backendProviderId, backendProvider, options)(fetch, basePath);
        },
        /**
         * sets a specific cluster-level role for a user
         * @summary Set a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {ModelUpdateClusterUserRequest} role user-level settings for cluster access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterUser(orgId: string, clusterId: string, userId: string, role: ModelUpdateClusterUserRequest, options?: any) {
            return AuthzApiFp(configuration).updateClusterUser(orgId, clusterId, userId, role, options)(fetch, basePath);
        },
        /**
         * update organization-level access settings for user
         * @summary Update organization-level access settings for user
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {ModelUpdateOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgUser(orgId: string, userId: string, orgUser: ModelUpdateOrgUserRequest, options?: any) {
            return AuthzApiFp(configuration).updateOrgUser(orgId, userId, orgUser, options)(fetch, basePath);
        },
        /**
         * Gets user JWT info for the UI to consume
         * @summary Gets user JWT info for the UI to consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfo(options?: any) {
            return AuthzApiFp(configuration).userInfo(options)(fetch, basePath);
        },
    };
};

/**
 * AuthzApi - object-oriented interface
 * @export
 * @class AuthzApi
 * @extends {BaseAPI}
 */
export class AuthzApi extends BaseAPI {
    /**
     * cancel an existing organization invite
     * @summary Cancel an existing organization invite
     * @param {string} orgId organization ID
     * @param {string} inviteId invite id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public cancelOrgInvite(orgId: string, inviteId: string, options?: any) {
        return AuthzApiFp(this.configuration).cancelOrgInvite(orgId, inviteId, options)(this.fetch, this.basePath);
    }

    /**
     * create a new backend provider entry for requested organization
     * @summary Create a new backend provider entry for requested organization
     * @param {string} orgId organization ID
     * @param {ModelBackendProvider} backendProvider Backend provider specification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public createBackendProvider(orgId: string, backendProvider: ModelBackendProvider, options?: any) {
        return AuthzApiFp(this.configuration).createBackendProvider(orgId, backendProvider, options)(this.fetch, this.basePath);
    }

    /**
     * Deletes a backend provider entry for requested organization
     * @summary Deletes a backend provider entry for requested organization
     * @param {string} orgId organization ID
     * @param {string} backendProviderId backend provider ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public deleteBackendProvider(orgId: string, backendProviderId: string, options?: any) {
        return AuthzApiFp(this.configuration).deleteBackendProvider(orgId, backendProviderId, options)(this.fetch, this.basePath);
    }

    /**
     * deletes a cluster-level role setting for a user
     * @summary Delete a cluster access override
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {string} userId user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public deleteClusterOverride(orgId: string, clusterId: string, userId: string, options?: any) {
        return AuthzApiFp(this.configuration).deleteClusterOverride(orgId, clusterId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * delete a user from an organization
     * @summary Delete a user from an organization
     * @param {string} orgId organization ID
     * @param {string} userId user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public deleteOrgUser(orgId: string, userId: string, options?: any) {
        return AuthzApiFp(this.configuration).deleteOrgUser(orgId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * returns all of the explicitly set cluster roles in an organization
     * @summary List all cluster access overrides
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getClusterOverrides(orgId: string, options?: any) {
        return AuthzApiFp(this.configuration).getClusterOverrides(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * returns the effective list of users who can access a cluster after overrides AND defaults are considered
     * @summary Get the list of users with access to a cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getClusterUsers(orgId: string, clusterId: string, options?: any) {
        return AuthzApiFp(this.configuration).getClusterUsers(orgId, clusterId, options)(this.fetch, this.basePath);
    }

    /**
     * List time before which token should be treated as invalid for each user.
     * @summary List invalidation data for specified users.
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getInvalidation(orgId: string, options?: any) {
        return AuthzApiFp(this.configuration).getInvalidation(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * List time before which token should be treated as invalid for each user.
     * @summary List invalidation data for all users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getInvalidationAll(options?: any) {
        return AuthzApiFp(this.configuration).getInvalidationAll(options)(this.fetch, this.basePath);
    }

    /**
     * Returns full backend provider entries for the requested organization
     * @summary Returns full backend provider entries for the requested organization
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getOrgBackendProviderData(orgId: string, options?: any) {
        return AuthzApiFp(this.configuration).getOrgBackendProviderData(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * Returns partial backend provider entries for requested organization
     * @summary Returns partial backend provider entries for requested organization
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getOrgBackendProviders(orgId: string, options?: any) {
        return AuthzApiFp(this.configuration).getOrgBackendProviders(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * cancel an existing organization invite
     * @summary Cancel an existing organization invite
     * @param {string} orgId organization id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getOrgInvites(orgId: string, options?: any) {
        return AuthzApiFp(this.configuration).getOrgInvites(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * list users and their access levels in the organization
     * @summary List users and their access levels in the organization
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getOrgUsers(orgId: string, options?: any) {
        return AuthzApiFp(this.configuration).getOrgUsers(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * returns the effective list of clusters a user can access after overrides AND defaults are considered
     * @summary Get the list of clusters a user can access
     * @param {string} orgId organization ID
     * @param {string} userId user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getUserClusters(orgId: string, userId: string, options?: any) {
        return AuthzApiFp(this.configuration).getUserClusters(orgId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * invites a user to join an organization
     * @summary Invite a user to join an organization
     * @param {string} orgId organization ID
     * @param {ModelInviteOrgUserRequest} orgUser user-level settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public inviteOrgUser(orgId: string, orgUser: ModelInviteOrgUserRequest, options?: any) {
        return AuthzApiFp(this.configuration).inviteOrgUser(orgId, orgUser, options)(this.fetch, this.basePath);
    }

    /**
     * get an updated JWT
     * @summary Get an updated JWT
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public refreshJWT(options?: any) {
        return AuthzApiFp(this.configuration).refreshJWT(options)(this.fetch, this.basePath);
    }

    /**
     * Updates a backend provider entry for requested organization
     * @summary Updates a backend provider entry for requested organization
     * @param {string} orgId organization ID
     * @param {string} backendProviderId backend provider ID
     * @param {ModelBackendProvider} backendProvider backend provider specification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public updateBackendProvider(orgId: string, backendProviderId: string, backendProvider: ModelBackendProvider, options?: any) {
        return AuthzApiFp(this.configuration).updateBackendProvider(orgId, backendProviderId, backendProvider, options)(this.fetch, this.basePath);
    }

    /**
     * sets a specific cluster-level role for a user
     * @summary Set a cluster access override
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {string} userId user ID
     * @param {ModelUpdateClusterUserRequest} role user-level settings for cluster access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public updateClusterUser(orgId: string, clusterId: string, userId: string, role: ModelUpdateClusterUserRequest, options?: any) {
        return AuthzApiFp(this.configuration).updateClusterUser(orgId, clusterId, userId, role, options)(this.fetch, this.basePath);
    }

    /**
     * update organization-level access settings for user
     * @summary Update organization-level access settings for user
     * @param {string} orgId organization ID
     * @param {string} userId user ID
     * @param {ModelUpdateOrgUserRequest} orgUser user-level settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public updateOrgUser(orgId: string, userId: string, orgUser: ModelUpdateOrgUserRequest, options?: any) {
        return AuthzApiFp(this.configuration).updateOrgUser(orgId, userId, orgUser, options)(this.fetch, this.basePath);
    }

    /**
     * Gets user JWT info for the UI to consume
     * @summary Gets user JWT info for the UI to consume
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public userInfo(options?: any) {
        return AuthzApiFp(this.configuration).userInfo(options)(this.fetch, this.basePath);
    }

}

/**
 * ClustersApi - fetch parameter creator
 * @export
 */
export const ClustersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * list clusters; the regional service that corresponds to a cluster is the source of truth, while the global service provides an index for convenience that may be slightly out of date
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling listClusters.');
            }
            const localVarPath = `/orgs/{orgId}/clusters`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * list clusters; the regional service that corresponds to a cluster is the source of truth, while the global service provides an index for convenience that may be slightly out of date
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListClustersResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).listClusters(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * list clusters; the regional service that corresponds to a cluster is the source of truth, while the global service provides an index for convenience that may be slightly out of date
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options?: any) {
            return ClustersApiFp(configuration).listClusters(orgId, options)(fetch, basePath);
        },
    };
};

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
    /**
     * list clusters; the regional service that corresponds to a cluster is the source of truth, while the global service provides an index for convenience that may be slightly out of date
     * @summary List clusters
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public listClusters(orgId: string, options?: any) {
        return ClustersApiFp(this.configuration).listClusters(orgId, options)(this.fetch, this.basePath);
    }

}

/**
 * GlcpApi - fetch parameter creator
 * @export
 */
export const GlcpApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Broker endpoint that GLCP requires to register. It is unclear what is required here.
         * @summary Broker endpoint that GLCP requires to register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gLCPBroker(options: any = {}): FetchArgs {
            const localVarPath = `/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlcpApi - functional programming interface
 * @export
 */
export const GlcpApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Broker endpoint that GLCP requires to register. It is unclear what is required here.
         * @summary Broker endpoint that GLCP requires to register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gLCPBroker(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GlcpApiFetchParamCreator(configuration).gLCPBroker(options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GlcpApi - factory interface
 * @export
 */
export const GlcpApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Broker endpoint that GLCP requires to register. It is unclear what is required here.
         * @summary Broker endpoint that GLCP requires to register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gLCPBroker(options?: any) {
            return GlcpApiFp(configuration).gLCPBroker(options)(fetch, basePath);
        },
    };
};

/**
 * GlcpApi - object-oriented interface
 * @export
 * @class GlcpApi
 * @extends {BaseAPI}
 */
export class GlcpApi extends BaseAPI {
    /**
     * Broker endpoint that GLCP requires to register. It is unclear what is required here.
     * @summary Broker endpoint that GLCP requires to register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlcpApi
     */
    public gLCPBroker(options?: any) {
        return GlcpApiFp(this.configuration).gLCPBroker(options)(this.fetch, this.basePath);
    }

}

/**
 * HealthCheckApi - fetch parameter creator
 * @export
 */
export const HealthCheckApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return health-check related info
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options: any = {}): FetchArgs {
            const localVarPath = `/health-check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Return health-check related info
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGlobalHealthCheckResponse> {
            const localVarFetchArgs = HealthCheckApiFetchParamCreator(configuration).healthCheck(options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Return health-check related info
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any) {
            return HealthCheckApiFp(configuration).healthCheck(options)(fetch, basePath);
        },
    };
};

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI {
    /**
     * Return health-check related info
     * @summary Health-check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public healthCheck(options?: any) {
        return HealthCheckApiFp(this.configuration).healthCheck(options)(this.fetch, this.basePath);
    }

}

/**
 * InvitesApi - fetch parameter creator
 * @export
 */
export const InvitesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * claim an outstanding invite to an org for the current user and refreshes the token cookie
         * @summary Claim an outstanding invite to an org for the current user
         * @param {string} userId UserID
         * @param {string} inviteId InviteID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimInvite(userId: string, inviteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling claimInvite.');
            }
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling claimInvite.');
            }
            const localVarPath = `/users/{userId}/invites/{inviteId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * lists outstanding invites sent to the email address verified for this user
         * @summary List outstanding invites for the current user
         * @param {string} userId UserID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserInvites(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling listUserInvites.');
            }
            const localVarPath = `/users/{userId}/invites`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitesApi - functional programming interface
 * @export
 */
export const InvitesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * claim an outstanding invite to an org for the current user and refreshes the token cookie
         * @summary Claim an outstanding invite to an org for the current user
         * @param {string} userId UserID
         * @param {string} inviteId InviteID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimInvite(userId: string, inviteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = InvitesApiFetchParamCreator(configuration).claimInvite(userId, inviteId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * lists outstanding invites sent to the email address verified for this user
         * @summary List outstanding invites for the current user
         * @param {string} userId UserID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserInvites(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListUserInvitesResponse> {
            const localVarFetchArgs = InvitesApiFetchParamCreator(configuration).listUserInvites(userId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InvitesApi - factory interface
 * @export
 */
export const InvitesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * claim an outstanding invite to an org for the current user and refreshes the token cookie
         * @summary Claim an outstanding invite to an org for the current user
         * @param {string} userId UserID
         * @param {string} inviteId InviteID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimInvite(userId: string, inviteId: string, options?: any) {
            return InvitesApiFp(configuration).claimInvite(userId, inviteId, options)(fetch, basePath);
        },
        /**
         * lists outstanding invites sent to the email address verified for this user
         * @summary List outstanding invites for the current user
         * @param {string} userId UserID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserInvites(userId: string, options?: any) {
            return InvitesApiFp(configuration).listUserInvites(userId, options)(fetch, basePath);
        },
    };
};

/**
 * InvitesApi - object-oriented interface
 * @export
 * @class InvitesApi
 * @extends {BaseAPI}
 */
export class InvitesApi extends BaseAPI {
    /**
     * claim an outstanding invite to an org for the current user and refreshes the token cookie
     * @summary Claim an outstanding invite to an org for the current user
     * @param {string} userId UserID
     * @param {string} inviteId InviteID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public claimInvite(userId: string, inviteId: string, options?: any) {
        return InvitesApiFp(this.configuration).claimInvite(userId, inviteId, options)(this.fetch, this.basePath);
    }

    /**
     * lists outstanding invites sent to the email address verified for this user
     * @summary List outstanding invites for the current user
     * @param {string} userId UserID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public listUserInvites(userId: string, options?: any) {
        return InvitesApiFp(this.configuration).listUserInvites(userId, options)(this.fetch, this.basePath);
    }

}

/**
 * LicensingApi - fetch parameter creator
 * @export
 */
export const LicensingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Change the owner of an organization
         * @summary Change the owner of an organization
         * @param {string} orgId OrgID
         * @param {ModelChangeOrgOwnerRequest} org New Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrgOwner(orgId: string, org: ModelChangeOrgOwnerRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling changeOrgOwner.');
            }
            // verify required parameter 'org' is not null or undefined
            if (org === null || org === undefined) {
                throw new RequiredError('org','Required parameter org was null or undefined when calling changeOrgOwner.');
            }
            const localVarPath = `/orgs/{orgId}/update-owner`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelChangeOrgOwnerRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(org || {}) : (org || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns trial and license expiration dates
         * @summary Get Current Trial/License Status for User
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensingStatus(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getLicensingStatus.');
            }
            const localVarPath = `/users/{userId}/licensing-status`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the trial expiration date to be now() + the trial duration
         * @summary Starts a Trial for the current user
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTrial(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling startTrial.');
            }
            const localVarPath = `/users/{userId}/start-trial`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts a new user trial signup request and sends an email to our shared trial signup request inbox
         * @summary Accepts a new user trial signup request
         * @param {ModelTrialSignUpRequest} signUp Trial sign up request payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialRequest(signUp: ModelTrialSignUpRequest, options: any = {}): FetchArgs {
            // verify required parameter 'signUp' is not null or undefined
            if (signUp === null || signUp === undefined) {
                throw new RequiredError('signUp','Required parameter signUp was null or undefined when calling trialRequest.');
            }
            const localVarPath = `/trial-request`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelTrialSignUpRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(signUp || {}) : (signUp || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Superadmin-only API to reflect license purchase
         * @summary Updates license expiration time for user
         * @param {string} userId User ID
         * @param {ModelUpdateLicenseRequest} licenseExp License expiration for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense(userId: string, licenseExp: ModelUpdateLicenseRequest, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateLicense.');
            }
            // verify required parameter 'licenseExp' is not null or undefined
            if (licenseExp === null || licenseExp === undefined) {
                throw new RequiredError('licenseExp','Required parameter licenseExp was null or undefined when calling updateLicense.');
            }
            const localVarPath = `/users/{userId}/update-license`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelUpdateLicenseRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(licenseExp || {}) : (licenseExp || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicensingApi - functional programming interface
 * @export
 */
export const LicensingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Change the owner of an organization
         * @summary Change the owner of an organization
         * @param {string} orgId OrgID
         * @param {ModelChangeOrgOwnerRequest} org New Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrgOwner(orgId: string, org: ModelChangeOrgOwnerRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = LicensingApiFetchParamCreator(configuration).changeOrgOwner(orgId, org, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns trial and license expiration dates
         * @summary Get Current Trial/License Status for User
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensingStatus(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelUserLicensingStatusResponse> {
            const localVarFetchArgs = LicensingApiFetchParamCreator(configuration).getLicensingStatus(userId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sets the trial expiration date to be now() + the trial duration
         * @summary Starts a Trial for the current user
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTrial(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelUserLicensingStatusResponse> {
            const localVarFetchArgs = LicensingApiFetchParamCreator(configuration).startTrial(userId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Accepts a new user trial signup request and sends an email to our shared trial signup request inbox
         * @summary Accepts a new user trial signup request
         * @param {ModelTrialSignUpRequest} signUp Trial sign up request payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialRequest(signUp: ModelTrialSignUpRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = LicensingApiFetchParamCreator(configuration).trialRequest(signUp, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Superadmin-only API to reflect license purchase
         * @summary Updates license expiration time for user
         * @param {string} userId User ID
         * @param {ModelUpdateLicenseRequest} licenseExp License expiration for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense(userId: string, licenseExp: ModelUpdateLicenseRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelUserLicensingStatusResponse> {
            const localVarFetchArgs = LicensingApiFetchParamCreator(configuration).updateLicense(userId, licenseExp, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LicensingApi - factory interface
 * @export
 */
export const LicensingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Change the owner of an organization
         * @summary Change the owner of an organization
         * @param {string} orgId OrgID
         * @param {ModelChangeOrgOwnerRequest} org New Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrgOwner(orgId: string, org: ModelChangeOrgOwnerRequest, options?: any) {
            return LicensingApiFp(configuration).changeOrgOwner(orgId, org, options)(fetch, basePath);
        },
        /**
         * Returns trial and license expiration dates
         * @summary Get Current Trial/License Status for User
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensingStatus(userId: string, options?: any) {
            return LicensingApiFp(configuration).getLicensingStatus(userId, options)(fetch, basePath);
        },
        /**
         * Sets the trial expiration date to be now() + the trial duration
         * @summary Starts a Trial for the current user
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTrial(userId: string, options?: any) {
            return LicensingApiFp(configuration).startTrial(userId, options)(fetch, basePath);
        },
        /**
         * Accepts a new user trial signup request and sends an email to our shared trial signup request inbox
         * @summary Accepts a new user trial signup request
         * @param {ModelTrialSignUpRequest} signUp Trial sign up request payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialRequest(signUp: ModelTrialSignUpRequest, options?: any) {
            return LicensingApiFp(configuration).trialRequest(signUp, options)(fetch, basePath);
        },
        /**
         * Superadmin-only API to reflect license purchase
         * @summary Updates license expiration time for user
         * @param {string} userId User ID
         * @param {ModelUpdateLicenseRequest} licenseExp License expiration for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense(userId: string, licenseExp: ModelUpdateLicenseRequest, options?: any) {
            return LicensingApiFp(configuration).updateLicense(userId, licenseExp, options)(fetch, basePath);
        },
    };
};

/**
 * LicensingApi - object-oriented interface
 * @export
 * @class LicensingApi
 * @extends {BaseAPI}
 */
export class LicensingApi extends BaseAPI {
    /**
     * Change the owner of an organization
     * @summary Change the owner of an organization
     * @param {string} orgId OrgID
     * @param {ModelChangeOrgOwnerRequest} org New Owner ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensingApi
     */
    public changeOrgOwner(orgId: string, org: ModelChangeOrgOwnerRequest, options?: any) {
        return LicensingApiFp(this.configuration).changeOrgOwner(orgId, org, options)(this.fetch, this.basePath);
    }

    /**
     * Returns trial and license expiration dates
     * @summary Get Current Trial/License Status for User
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensingApi
     */
    public getLicensingStatus(userId: string, options?: any) {
        return LicensingApiFp(this.configuration).getLicensingStatus(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Sets the trial expiration date to be now() + the trial duration
     * @summary Starts a Trial for the current user
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensingApi
     */
    public startTrial(userId: string, options?: any) {
        return LicensingApiFp(this.configuration).startTrial(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Accepts a new user trial signup request and sends an email to our shared trial signup request inbox
     * @summary Accepts a new user trial signup request
     * @param {ModelTrialSignUpRequest} signUp Trial sign up request payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensingApi
     */
    public trialRequest(signUp: ModelTrialSignUpRequest, options?: any) {
        return LicensingApiFp(this.configuration).trialRequest(signUp, options)(this.fetch, this.basePath);
    }

    /**
     * Superadmin-only API to reflect license purchase
     * @summary Updates license expiration time for user
     * @param {string} userId User ID
     * @param {ModelUpdateLicenseRequest} licenseExp License expiration for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensingApi
     */
    public updateLicense(userId: string, licenseExp: ModelUpdateLicenseRequest, options?: any) {
        return LicensingApiFp(this.configuration).updateLicense(userId, licenseExp, options)(this.fetch, this.basePath);
    }

}

/**
 * OrgQuotasApi - fetch parameter creator
 * @export
 */
export const OrgQuotasApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get quota values for the specified org, given an org ID
         * @summary Get quota values for the specified org
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgQuotas(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrgQuotas.');
            }
            const localVarPath = `/orgs/{orgId}/quotas`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update quota values for the specified org, given an org ID
         * @summary Update quota values for the specified org
         * @param {string} orgId organization ID
         * @param {ModelUpdateOrgQuotasRequest} req Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgQuotas(orgId: string, req: ModelUpdateOrgQuotasRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling updateOrgQuotas.');
            }
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling updateOrgQuotas.');
            }
            const localVarPath = `/orgs/{orgId}/quotas`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelUpdateOrgQuotasRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(req || {}) : (req || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgQuotasApi - functional programming interface
 * @export
 */
export const OrgQuotasApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get quota values for the specified org, given an org ID
         * @summary Get quota values for the specified org
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgQuotas(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelOrgQuotasResponse> {
            const localVarFetchArgs = OrgQuotasApiFetchParamCreator(configuration).getOrgQuotas(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update quota values for the specified org, given an org ID
         * @summary Update quota values for the specified org
         * @param {string} orgId organization ID
         * @param {ModelUpdateOrgQuotasRequest} req Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgQuotas(orgId: string, req: ModelUpdateOrgQuotasRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelOrgQuotasResponse> {
            const localVarFetchArgs = OrgQuotasApiFetchParamCreator(configuration).updateOrgQuotas(orgId, req, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrgQuotasApi - factory interface
 * @export
 */
export const OrgQuotasApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get quota values for the specified org, given an org ID
         * @summary Get quota values for the specified org
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgQuotas(orgId: string, options?: any) {
            return OrgQuotasApiFp(configuration).getOrgQuotas(orgId, options)(fetch, basePath);
        },
        /**
         * Update quota values for the specified org, given an org ID
         * @summary Update quota values for the specified org
         * @param {string} orgId organization ID
         * @param {ModelUpdateOrgQuotasRequest} req Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgQuotas(orgId: string, req: ModelUpdateOrgQuotasRequest, options?: any) {
            return OrgQuotasApiFp(configuration).updateOrgQuotas(orgId, req, options)(fetch, basePath);
        },
    };
};

/**
 * OrgQuotasApi - object-oriented interface
 * @export
 * @class OrgQuotasApi
 * @extends {BaseAPI}
 */
export class OrgQuotasApi extends BaseAPI {
    /**
     * Get quota values for the specified org, given an org ID
     * @summary Get quota values for the specified org
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgQuotasApi
     */
    public getOrgQuotas(orgId: string, options?: any) {
        return OrgQuotasApiFp(this.configuration).getOrgQuotas(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * Update quota values for the specified org, given an org ID
     * @summary Update quota values for the specified org
     * @param {string} orgId organization ID
     * @param {ModelUpdateOrgQuotasRequest} req Organization specification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgQuotasApi
     */
    public updateOrgQuotas(orgId: string, req: ModelUpdateOrgQuotasRequest, options?: any) {
        return OrgQuotasApiFp(this.configuration).updateOrgQuotas(orgId, req, options)(this.fetch, this.basePath);
    }

}

/**
 * OrgsApi - fetch parameter creator
 * @export
 */
export const OrgsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get an EKS CloudFormation template for new org setup
         * @summary Get an EKS CloudFormation template for new org setup
         * @param {string} backendType Backend type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudFormationTemplate(backendType: string, options: any = {}): FetchArgs {
            // verify required parameter 'backendType' is not null or undefined
            if (backendType === null || backendType === undefined) {
                throw new RequiredError('backendType','Required parameter backendType was null or undefined when calling cloudFormationTemplate.');
            }
            const localVarPath = `/{backendType}/cloudformation`
                .replace(`{${"backendType"}}`, encodeURIComponent(String(backendType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new organization
         * @summary Create a new organization
         * @param {ModelCreateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrg(org: ModelCreateOrgRequest, options: any = {}): FetchArgs {
            // verify required parameter 'org' is not null or undefined
            if (org === null || org === undefined) {
                throw new RequiredError('org','Required parameter org was null or undefined when calling createOrg.');
            }
            const localVarPath = `/orgs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelCreateOrgRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(org || {}) : (org || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deactivate an organization
         * @summary Deactivate an organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOrg(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deactivateOrg.');
            }
            const localVarPath = `/orgs/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get organization metadata
         * @summary Get organization metadata
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgInfo(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrgInfo.');
            }
            const localVarPath = `/orgs/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all organizations
         * @summary List all organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrgsInDeployment(options: any = {}): FetchArgs {
            const localVarPath = `/orgs/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list organizations the user is a part of
         * @summary List organizations the user is a part of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgs(options: any = {}): FetchArgs {
            const localVarPath = `/orgs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an organization
         * @summary Update an organization
         * @param {string} orgId organization ID
         * @param {ModelUpdateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrg(orgId: string, org: ModelUpdateOrgRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling updateOrg.');
            }
            // verify required parameter 'org' is not null or undefined
            if (org === null || org === undefined) {
                throw new RequiredError('org','Required parameter org was null or undefined when calling updateOrg.');
            }
            const localVarPath = `/orgs/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelUpdateOrgRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(org || {}) : (org || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgsApi - functional programming interface
 * @export
 */
export const OrgsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get an EKS CloudFormation template for new org setup
         * @summary Get an EKS CloudFormation template for new org setup
         * @param {string} backendType Backend type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudFormationTemplate(backendType: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).cloudFormationTemplate(backendType, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * create a new organization
         * @summary Create a new organization
         * @param {ModelCreateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrg(org: ModelCreateOrgRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelCreateOrgResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).createOrg(org, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * deactivate an organization
         * @summary Deactivate an organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOrg(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).deactivateOrg(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get organization metadata
         * @summary Get organization metadata
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgInfo(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelOrgInfoResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).getOrgInfo(orgId, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * list all organizations
         * @summary List all organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrgsInDeployment(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListOrgsResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).listAllOrgsInDeployment(options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * list organizations the user is a part of
         * @summary List organizations the user is a part of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgs(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListOrgsResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).listOrgs(options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update an organization
         * @summary Update an organization
         * @param {string} orgId organization ID
         * @param {ModelUpdateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrg(orgId: string, org: ModelUpdateOrgRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).updateOrg(orgId, org, options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrgsApi - factory interface
 * @export
 */
export const OrgsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * get an EKS CloudFormation template for new org setup
         * @summary Get an EKS CloudFormation template for new org setup
         * @param {string} backendType Backend type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudFormationTemplate(backendType: string, options?: any) {
            return OrgsApiFp(configuration).cloudFormationTemplate(backendType, options)(fetch, basePath);
        },
        /**
         * create a new organization
         * @summary Create a new organization
         * @param {ModelCreateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrg(org: ModelCreateOrgRequest, options?: any) {
            return OrgsApiFp(configuration).createOrg(org, options)(fetch, basePath);
        },
        /**
         * deactivate an organization
         * @summary Deactivate an organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOrg(orgId: string, options?: any) {
            return OrgsApiFp(configuration).deactivateOrg(orgId, options)(fetch, basePath);
        },
        /**
         * Get organization metadata
         * @summary Get organization metadata
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgInfo(orgId: string, options?: any) {
            return OrgsApiFp(configuration).getOrgInfo(orgId, options)(fetch, basePath);
        },
        /**
         * list all organizations
         * @summary List all organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrgsInDeployment(options?: any) {
            return OrgsApiFp(configuration).listAllOrgsInDeployment(options)(fetch, basePath);
        },
        /**
         * list organizations the user is a part of
         * @summary List organizations the user is a part of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgs(options?: any) {
            return OrgsApiFp(configuration).listOrgs(options)(fetch, basePath);
        },
        /**
         * Update an organization
         * @summary Update an organization
         * @param {string} orgId organization ID
         * @param {ModelUpdateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrg(orgId: string, org: ModelUpdateOrgRequest, options?: any) {
            return OrgsApiFp(configuration).updateOrg(orgId, org, options)(fetch, basePath);
        },
    };
};

/**
 * OrgsApi - object-oriented interface
 * @export
 * @class OrgsApi
 * @extends {BaseAPI}
 */
export class OrgsApi extends BaseAPI {
    /**
     * get an EKS CloudFormation template for new org setup
     * @summary Get an EKS CloudFormation template for new org setup
     * @param {string} backendType Backend type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public cloudFormationTemplate(backendType: string, options?: any) {
        return OrgsApiFp(this.configuration).cloudFormationTemplate(backendType, options)(this.fetch, this.basePath);
    }

    /**
     * create a new organization
     * @summary Create a new organization
     * @param {ModelCreateOrgRequest} org Organization specification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public createOrg(org: ModelCreateOrgRequest, options?: any) {
        return OrgsApiFp(this.configuration).createOrg(org, options)(this.fetch, this.basePath);
    }

    /**
     * deactivate an organization
     * @summary Deactivate an organization
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public deactivateOrg(orgId: string, options?: any) {
        return OrgsApiFp(this.configuration).deactivateOrg(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * Get organization metadata
     * @summary Get organization metadata
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public getOrgInfo(orgId: string, options?: any) {
        return OrgsApiFp(this.configuration).getOrgInfo(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * list all organizations
     * @summary List all organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public listAllOrgsInDeployment(options?: any) {
        return OrgsApiFp(this.configuration).listAllOrgsInDeployment(options)(this.fetch, this.basePath);
    }

    /**
     * list organizations the user is a part of
     * @summary List organizations the user is a part of
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public listOrgs(options?: any) {
        return OrgsApiFp(this.configuration).listOrgs(options)(this.fetch, this.basePath);
    }

    /**
     * Update an organization
     * @summary Update an organization
     * @param {string} orgId organization ID
     * @param {ModelUpdateOrgRequest} org Organization specification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public updateOrg(orgId: string, org: ModelUpdateOrgRequest, options?: any) {
        return OrgsApiFp(this.configuration).updateOrg(orgId, org, options)(this.fetch, this.basePath);
    }

}

/**
 * SupportMatrixApi - fetch parameter creator
 * @export
 */
export const SupportMatrixApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return the support matrix
         * @summary Return the support matrix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportMatrix(options: any = {}): FetchArgs {
            const localVarPath = `/support-matrix`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportMatrixApi - functional programming interface
 * @export
 */
export const SupportMatrixApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Return the support matrix
         * @summary Return the support matrix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportMatrix(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetSupportMatrixResponse> {
            const localVarFetchArgs = SupportMatrixApiFetchParamCreator(configuration).getSupportMatrix(options);
            localVarFetchArgs.options.credentials = "include";
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SupportMatrixApi - factory interface
 * @export
 */
export const SupportMatrixApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Return the support matrix
         * @summary Return the support matrix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportMatrix(options?: any) {
            return SupportMatrixApiFp(configuration).getSupportMatrix(options)(fetch, basePath);
        },
    };
};

/**
 * SupportMatrixApi - object-oriented interface
 * @export
 * @class SupportMatrixApi
 * @extends {BaseAPI}
 */
export class SupportMatrixApi extends BaseAPI {
    /**
     * Return the support matrix
     * @summary Return the support matrix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportMatrixApi
     */
    public getSupportMatrix(options?: any) {
        return SupportMatrixApiFp(this.configuration).getSupportMatrix(options)(this.fetch, this.basePath);
    }

}


import { Rule } from 'antd/es/form';
import Form from 'hew/Form';
import Input from 'hew/Input';
import React from 'react';

import Link from 'components/Link';
import { ModelListOrgsEntry } from 'services/global-bindings';
import {
  AWSBackendProviderFields,
  BYOK8SBackendProviderFields,
  GKEBackendProviderFields,
} from 'types';
import { getOrganizationIdentifier } from 'utils/saas';
import { validateOrgName } from 'utils/validate';

import css from './OrgFormItems.module.scss';

interface FormItemProps {
  disabled?: boolean;
  rules?: Rule[] | undefined;
  currentOrgs?: string[];
  setFile?: React.Dispatch<React.SetStateAction<string>>;
  selectedOrg?: ModelListOrgsEntry;
}

export const AwsCrossAccountRoleItem: React.FC<FormItemProps> = ({ rules }) => {
  return (
    <>
      <Form.Item
        extra={
          <div>
            Specify the <b>ARN</b> of the cross-account role for your {getOrganizationIdentifier()}
            &apos;s AWS account. For more information, see the&nbsp;
            <Link path="/docs/cloud-provider.html" popout={true}>
              User Manual.
            </Link>
          </div>
        }
        label="Cross-Account Role"
        required={true}>
        <div className={css.entry}>
          <Form.Item name={AWSBackendProviderFields.XAcctRole} noStyle rules={rules}>
            <Input autoComplete="off" min={1} type="text" />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
};

export const AwsMasterInstanceProfile: React.FC<FormItemProps> = ({ rules }) => {
  return (
    <>
      <Form.Item
        extra={
          <div>
            Specify the <b>ARN</b> of the instance profile for the master node. For more
            information, see the&nbsp;
            <Link path="/docs/cloud-provider.html" popout={true}>
              User Manual.
            </Link>
          </div>
        }
        label="Master Instance Profile"
        required={true}>
        <div className={css.entry}>
          <Form.Item name={AWSBackendProviderFields.MasterInstanceProfile} noStyle rules={rules}>
            <Input autoComplete="off" min={1} type="text" />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
};

export const AwsAgentInstanceProfile: React.FC<FormItemProps> = ({ rules }) => {
  return (
    <>
      <Form.Item
        extra={
          <div>
            Specify the <b>ARN</b> of the instance profile for the agent nodes. For more
            information, see the&nbsp;
            <Link path="/docs/cloud-provider.html" popout={true}>
              User Manual.
            </Link>
          </div>
        }
        label="Agent Instance Profile"
        required={true}>
        <div className={css.entry}>
          <Form.Item name={AWSBackendProviderFields.AgentInstanceProfile} noStyle rules={rules}>
            <Input autoComplete="off" min={1} type="text" />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
};

export const GcpProjectId: React.FC = () => {
  return (
    <>
      <Form.Item
        extra={
          <div>
            Specify the GCP Project ID you have granted HPE MLDES access to. For more information on
            Project ID requirements, see the&nbsp;
            <Link path="/docs/cloud-provider.html#gcp" popout={true}>
              Cloud Provider Documentation.
            </Link>
          </div>
        }
        label="GCP Project"
        name={GKEBackendProviderFields.ProjectId}
        rules={[
          { message: 'Project ID must be at least 6 characters', min: 6 },
          { max: 30, message: 'Project ID must be at most 30 characters' },
          { message: 'Field required', required: true },
        ]}>
        <Input autoComplete="off" min={1} type="text" />
      </Form.Item>
    </>
  );
};

const FILE_SIZE_LIMIT = 1;

export const ByokKubeConfig: React.FC<FormItemProps> = ({ setFile }) => {
  const [fileSecure, setFileSecure] = React.useState(false);
  const checkYAML = () => {
    if (fileSecure) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Uploaded file is insecure.'));
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && setFile) {
      // this line checks the content type of the file being uploaded and rejects files larger than 1MB
      if (
        (file.type === 'application/x-yaml' || file.type === 'text/yaml') &&
        file.size / 1024 / 1024 < FILE_SIZE_LIMIT
      ) {
        setFileSecure(true);
      } else {
        setFileSecure(false);
        return;
      }
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.result && typeof fileReader.result === 'string') {
          setFile(fileReader.result);
        }
      };
      fileReader.readAsText(file);
    }
  };
  return (
    <>
      <Form.Item
        extra={
          <div>
            Provide the Kubernetes configuration file that contains the cluster configuration. For
            more information, see the&nbsp;
            {/*TODO: link is broken until related PR is merged in https://github.com/determined-ai/saas/pull/1162*/}
            <Link path="docs/cloud-provider.html#kubernetes-byo-k8s" popout={true}>
              Cloud Provider Documentation.
            </Link>
          </div>
        }
        label="Kubeconfig"
        name={BYOK8SBackendProviderFields.Kubeconfig}
        rules={[
          { message: 'Field required', required: true },
          {
            message: 'Uploaded file must be a YAML file and <' + FILE_SIZE_LIMIT + 'MB',
            validator: checkYAML,
          },
        ]}>
        <Input
          autoComplete="off"
          id={BYOK8SBackendProviderFields.Kubeconfig}
          min={1}
          type="file"
          onChange={handleFileChange}
        />
      </Form.Item>
    </>
  );
};

export const OrgName: React.FC<FormItemProps> = ({ currentOrgs, disabled }) => {
  return (
    <>
      <Form.Item
        extra={`Human-readable ${getOrganizationIdentifier()} name`}
        label="Name"
        name="name"
        rules={[
          { message: `${getOrganizationIdentifier()} name required`, required: true },
          {
            validator: (_, value) => {
              if (disabled) return Promise.resolve();
              if (currentOrgs?.includes(value)) {
                return Promise.reject(
                  `An ${getOrganizationIdentifier()} with the same name already exists!`,
                );
              } else if (validateOrgName(value) || value.length === 0) {
                return Promise.resolve();
              } else {
                return Promise.reject(`Invalid ${getOrganizationIdentifier()} name`);
              }
            },
          },
        ]}>
        <Input autoComplete="off" disabled={disabled} min={1} type="text" />
      </Form.Item>
    </>
  );
};

import { validate } from '@aws-sdk/util-arn-parser';
import Form from 'hew/Form';
import { Modal } from 'hew/Modal';
import Pivot, { TabItem } from 'hew/Pivot';
import Select, { Option } from 'hew/Select';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaAws } from 'react-icons/fa';
import { SiCheckmarx, SiGooglecloud, SiKubernetes } from 'react-icons/si';

import Link from 'components/Link';
import OrgGuid from 'components/OrgGuid';
import { useAuth } from 'contexts/Auth';
import { useStore } from 'contexts/Store';
import useConfig, { Config } from 'hooks/useConfig';
import { useFetchOrgs, useFetchWithRetry } from 'hooks/useFetch';
import { globalServerAddress } from 'routes/utils';
import { createBackendProvider, createOrg } from 'services/api';
import { globalVersion } from 'services/apiConfig';
import { ModelCreateOrgRequest, ModelCreateOrgResponse } from 'services/global-bindings';
import { Backend, Eventually, Location } from 'types';
import handleError, { ErrorLevel, wrapPublicMessage } from 'utils/error';
import { getOrganizationIdentifier, isMspDeployment } from 'utils/saas';

import {
  AwsAgentInstanceProfile,
  AwsCrossAccountRoleItem,
  AwsMasterInstanceProfile,
  ByokKubeConfig,
  GcpProjectId,
  OrgName,
} from './OrgFormItems';

interface Props {
  existingOrg: boolean;
  fetchActiveOrgBackendProviders?: () => Promise<void>;
  onClose?: (cancel: boolean) => Eventually<void>;
  onCreate?: (newOrgId: string, newOrgName: string) => Eventually<void>;
}

interface FormValues {
  agentInstanceProfile: string;
  masterInstanceProfile: string;
  name: string;
  projectId: string;
  type: string;
  xAcctRole: string;
  kubeconfig: string;
  nextOrgGuid: string;
}

interface AWSPaneProps {
  config?: Config;
  formValues: FormValues;
}

const AWSPaneContent: React.FC<AWSPaneProps> = ({ config, formValues }) => {
  const checkArn = (_: object, arn: string) => {
    if (validate(arn)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('ARN syntax is invalid'));
  };

  const selectItems = useMemo(() => {
    return (
      <>
        <Option key={Backend.EC2} value={Backend.EC2}>
          {Backend.EC2.toUpperCase()}
        </Option>
        {config?.mldmEnabled && (
          <Option key={Backend.EKS} value={Backend.EKS}>
            {Backend.EKS.toUpperCase()}
          </Option>
        )}
      </>
    );
  }, [config]);

  if (!config) return null;

  return (
    <div>
      <span>
        A{' '}
        <Link
          path={globalServerAddress(`${globalVersion}/${formValues?.type ?? 'ec2'}/cloudformation`)}
          popout={true}>
          CloudFormation template
        </Link>{' '}
        is available for creating the resources for this particular organization. Upload it to{' '}
        <Link
          path={
            'https://us-west-2.console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/create'
          }
          popout={true}>
          AWS CloudFormation
        </Link>{' '}
        to create the resources required here.
      </span>
      <br />
      <br />
      <div style={{ display: !config.mldmEnabled ? 'none' : undefined }}>
        <Form.Item
          extra={<div>Choose between EC2 or EKS for your infrastructure.</div>}
          label="Backend Type"
          name="type"
          required={true}>
          <Select disabled={!config.mldmEnabled}>{selectItems}</Select>
        </Form.Item>
      </div>
      <AwsCrossAccountRoleItem rules={[{ required: true, validator: checkArn }]} />
      {formValues && formValues.type === Backend.EC2 && (
        <>
          <AwsMasterInstanceProfile rules={[{ required: true, validator: checkArn }]} />
          <AwsAgentInstanceProfile rules={[{ required: true, validator: checkArn }]} />
        </>
      )}
    </div>
  );
};

const defaultTab = Location.AWS;

/**
 * A modal that gives user option to create a new
 * organization by providing a name through a form
 * and confirming.
 */
const OrgModal: React.FC<Props> = ({
  existingOrg,
  fetchActiveOrgBackendProviders,
  onClose,
  onCreate,
}) => {
  const [canceler] = useState(() => new AbortController());
  const fetchOrgs = useFetchOrgs(canceler);
  const { supportMatrix } = useStore();
  const [form] = Form.useForm<FormValues>();
  const formValues = Form.useWatch<FormValues>([], form);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [currentTab, setTab] = useState<Location>(defaultTab);
  const [file, setFile] = useState<string>('');
  const fetchWithRetry = useFetchWithRetry(canceler);
  const { doRefreshToken } = useAuth();
  const {
    orgState: { orgs, selectedOrg, nextOrgGuid },
  } = useStore();
  const config = useConfig(canceler);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsSubmitEnabled(true))
      .catch(() => {
        setIsSubmitEnabled(false);
      });
  }, [form, formValues, currentTab]);

  const handleSubmit = useCallback(async () => {
    const inputs = form.getFieldsValue();
    inputs.kubeconfig = file;

    let createOrgRequest = {} as ModelCreateOrgRequest;
    if (currentTab === Location.AWS) {
      createOrgRequest = {
        backendProvider: {
          connectionInfo: {
            agentInstanceProfile: inputs.agentInstanceProfile,
            masterInstanceProfile: inputs.masterInstanceProfile,
            xAcctRole: inputs.xAcctRole,
          },
          type: inputs.type,
        },
        name: inputs.name,
        nextOrgGuid: nextOrgGuid,
      };
    } else if (currentTab === Location.GCP) {
      createOrgRequest = {
        backendProvider: { connectionInfo: { projectId: inputs.projectId }, type: Backend.GKE },
        name: inputs.name,
        nextOrgGuid: nextOrgGuid,
      };
    } else if (currentTab === Location.BYOK8S) {
      createOrgRequest = {
        backendProvider: {
          connectionInfo: { kubeconfig: inputs.kubeconfig },
          type: Backend.BYOK8S,
        },
        name: inputs.name,
        nextOrgGuid: nextOrgGuid,
      };
    }
    let newOrg: ModelCreateOrgResponse | null = null;

    try {
      if (!existingOrg) {
        newOrg = await fetchWithRetry(
          async () => await createOrg(createOrgRequest, { signal: canceler.signal }),
        );
      } else {
        if (!selectedOrg) return;
        await fetchWithRetry(
          async () =>
            await createBackendProvider(
              { backendProvider: createOrgRequest.backendProvider, orgId: selectedOrg.id },
              {
                signal: canceler.signal,
              },
            ),
        );
      }
      await doRefreshToken(canceler);
      setTab(defaultTab);
      if (fetchActiveOrgBackendProviders) await fetchActiveOrgBackendProviders();
      await onClose?.(false);
    } catch (error) {
      handleError(error, {
        leaveOpen: true,
        level: ErrorLevel.Error,
        publicMessage: wrapPublicMessage(error, `Failed to create ${getOrganizationIdentifier()}`),
        silent: false,
      });
      throw error;
    }

    try {
      await fetchOrgs();
      newOrg && onCreate?.(newOrg.id, createOrgRequest.name);
    } catch (error) {
      handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: `Failed to create ${getOrganizationIdentifier()}`,
        silent: false,
      });
    }
  }, [
    form,
    currentTab,
    file,
    existingOrg,
    doRefreshToken,
    canceler,
    fetchActiveOrgBackendProviders,
    onClose,
    fetchWithRetry,
    selectedOrg,
    nextOrgGuid,
    fetchOrgs,
    onCreate,
  ]);

  const tabItems = useMemo(() => {
    const items: TabItem[] = [];

    // Support AWS backend regardless
    items.push({
      children: currentTab === Location.AWS && (
        <div>
          <br />
          <AWSPaneContent config={config} formValues={formValues} />
          <OrgGuid formItemName="nextOrgGuid" guid={nextOrgGuid} />
        </div>
      ),
      key: Location.AWS,
      label:
        currentTab === Location.AWS ? (
          <span>
            <FaAws /> AWS <SiCheckmarx />
          </span>
        ) : (
          <span>
            <FaAws /> AWS
          </span>
        ),
    });

    // Support GCP backend for all non-MSP deployments
    if (!isMspDeployment()) {
      items.push({
        children: currentTab === Location.GCP && (
          <div>
            <br />
            <GcpProjectId />
            <OrgGuid formItemName="nextOrgGuid" guid={nextOrgGuid} />
          </div>
        ),
        key: Location.GCP,
        label:
          currentTab === Location.GCP ? (
            <span>
              <SiGooglecloud /> GCP <SiCheckmarx />
            </span>
          ) : (
            <span>
              <SiGooglecloud /> GCP
            </span>
          ),
      });
    }

    // Support BYOK backend as long as it's enabled in the support matrix
    if (supportMatrix?.byokEnabled) {
      items.push({
        children: currentTab === Location.BYOK8S && (
          <div>
            <br />
            <ByokKubeConfig setFile={setFile} />
          </div>
        ),
        key: Location.BYOK8S,
        label:
          currentTab === Location.BYOK8S ? (
            <span>
              <SiKubernetes /> K8S <SiCheckmarx />
            </span>
          ) : (
            <span>
              <SiKubernetes /> K8S
            </span>
          ),
      });
    }

    return items;
  }, [config, currentTab, formValues, nextOrgGuid, supportMatrix?.byokEnabled]);

  return (
    <Modal
      size="large"
      submit={{
        disabled: !isSubmitEnabled,
        handleError: handleError,
        handler: handleSubmit,
        text: existingOrg ? 'Update' : 'Create',
      }}
      title={
        existingOrg
          ? `Add ${getOrganizationIdentifier()} Backend Provider`
          : `Create New ${getOrganizationIdentifier()}`
      }
      onClose={async () => {
        form.resetFields();
        setIsSubmitEnabled(false);
        setTab(defaultTab);
        await onClose?.(true);
      }}>
      <div>
        <Form
          form={form}
          initialValues={{ name: existingOrg ? selectedOrg?.name : '', type: Backend.EC2 }}
          labelCol={{ span: 6 }}
          onFinish={handleSubmit}>
          <OrgName currentOrgs={orgs?.map((org) => org.name)} disabled={existingOrg} />
          <b>Backend Provider </b>
          <Pivot
            activeKey={currentTab}
            defaultActiveKey={defaultTab}
            destroyInactiveTabPane={true}
            items={tabItems}
            onChange={(e: string) => {
              setTab(e as Location);
            }}
          />
        </Form>
      </div>
    </Modal>
  );
};

export default OrgModal;

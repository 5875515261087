import React, { useCallback, useEffect, useRef, useState } from 'react';

import ActionCard from 'components/ActionCard';
import OnboardingInvitesComponent from 'components/OnboardingInvites';
import OrgForm, { OrgFormHandles } from 'components/OrgPicker/OrgForm';
import PageMessage from 'components/PageMessage';
import Spinner from 'components/Spinner';
import TrialStart from 'components/Trial/TrialStartInfo';
import useUI from 'components/UIManager';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchSupportMatrix, useFetchWithRetry } from 'hooks/useFetch';
import Error from 'pages/Error/Error';
import { fetchUsersInvites, startTrial } from 'services/api';
import { ModelUserInvite } from 'services/global-bindings';
import handleError from 'utils/error';
import { routeToReactUrl } from 'utils/routes';
import { getOrganizationIdentifier, isOrgAdmin } from 'utils/saas';

import css from './Onboarding.module.scss';
import { Welcome } from './Welcome';

export enum OnboardingState {
  Init = 1,
  Welcome = 2,
  PendingInvites = 3,
  TrialCheck = 4,
  TrialSignUp = 5,
  OrgCreation = 6,
}

interface Props {
  fetchOrgBackendProviders: () => Promise<void>;
}

const Onboarding: React.FC<Props> = ({ fetchOrgBackendProviders }) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [error, setError] = useState<string>();
  const [currentStep, setCurrentStep] = useState<OnboardingState>(OnboardingState.Init);
  const [isSelectedOrgAdmin, setIsSelectedOrgAdmin] = useState<boolean>(true);
  const {
    supportMatrix,
    orgState: { selectedOrg },
  } = useStore();
  const user = useUser();
  const { actions: uiActions } = useUI();
  const ref = useRef<OrgFormHandles>(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);

  const [invites, setInvites] = useState<ModelUserInvite[]>([]);

  const fetchSupportMatrix = useFetchSupportMatrix(canceler);

  useEffect(() => {
    uiActions.hideSpinner();
    uiActions.hideChrome();
    return uiActions.showChrome;
  }, [uiActions]);

  useEffect(() => {
    const invitesCheck = async () => {
      if (user && currentStep === OnboardingState.Init) {
        try {
          const { invites } = await fetchUsersInvites(user.userId);
          if (invites?.length) {
            setInvites(invites);
            setCurrentStep(OnboardingState.PendingInvites);
            return;
          }
        } catch (e) {
          handleError(e, {
            publicSubject: 'Failed to fetch org invites',
            silent: true,
          });
        }
        setCurrentStep(OnboardingState.Welcome);
      }
    };
    invitesCheck();
  }, [currentStep, user]);

  useEffect(() => {
    if (currentStep === OnboardingState.TrialCheck) {
      if (
        user.licensing.enabled &&
        !user.licensing.userLicensingStatus.active &&
        !user.superAdmin
      ) {
        setCurrentStep(OnboardingState.TrialSignUp);
      } else {
        setCurrentStep(OnboardingState.OrgCreation);
      }
    }
  }, [
    currentStep,
    invites,
    user.licensing.enabled,
    user.licensing.userLicensingStatus.active,
    user.superAdmin,
  ]);

  useEffect(() => {
    if (selectedOrg) {
      setIsSelectedOrgAdmin(isOrgAdmin(user.roles, selectedOrg.id));
    }
  }, [selectedOrg, user.roles]);

  const onBackOrg = useCallback(() => {
    setCurrentStep(OnboardingState.Welcome);
  }, [setCurrentStep]);

  const trialSubmit = useCallback(async () => {
    if (!user) return;
    try {
      await fetchWithRetry(
        async () => await startTrial({ userId: user.userId }, { signal: canceler.signal }),
      );
      await user.refetchLicenseStatus();
      setCurrentStep(OnboardingState.OrgCreation);
    } catch (e) {
      setError('Failed to start free trial');
      handleError(e, {
        publicSubject: 'Failed to start trial',
      });
    }
  }, [canceler, fetchWithRetry, user]);

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [supportMatrix, fetchSupportMatrix]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  if (error) {
    return <Error message={error} />;
  }

  if (currentStep === OnboardingState.Init) {
    return <Spinner />;
  }

  return (
    <div className={css.base}>
      {currentStep === OnboardingState.Welcome ? (
        <ActionCard
          backButtonText="No Thanks"
          nextDisabled={!isSelectedOrgAdmin}
          title="Welcome to the HPE Machine Learning Development Environment (MLDE) Software!"
          onBack={() => routeToReactUrl('/logout')}
          onNext={async () => await setCurrentStep(OnboardingState.TrialCheck)}>
          <Welcome nextDisabled={!isSelectedOrgAdmin} />
        </ActionCard>
      ) : currentStep === OnboardingState.PendingInvites && user ? (
        <OnboardingInvitesComponent invites={invites} userId={user.userId} />
      ) : currentStep === OnboardingState.TrialSignUp ? (
        <ActionCard
          backButtonText={'Back'}
          nextButtonText={'Start Trial'}
          onBack={() => setCurrentStep(OnboardingState.Welcome)}
          onNext={trialSubmit}>
          <TrialStart />
        </ActionCard>
      ) : currentStep === OnboardingState.OrgCreation && supportMatrix ? (
        <ActionCard
          backButtonText="Back"
          nextButtonText={selectedOrg ? 'Update' : 'Create'}
          nextDisabled={!isSubmitEnabled}
          title={
            selectedOrg
              ? `Add ${getOrganizationIdentifier()} Backend Provider`
              : `Create New ${getOrganizationIdentifier()}`
          }
          onBack={onBackOrg}
          onNext={async () => {
            if (ref.current) {
              try {
                await ref.current.handleSubmit();
              } catch (ex) {
                console.error('Failed to submit org form', ex);
                return;
              }
              routeToReactUrl('/clusters');
            }
          }}>
          <OrgForm
            existingOrg={!!selectedOrg}
            fetchOrgBackendProviders={fetchOrgBackendProviders}
            ref={ref}
            setIsSubmitEnabled={setIsSubmitEnabled}
          />
        </ActionCard>
      ) : (
        <PageMessage title="">
          <Spinner />
        </PageMessage>
      )}
    </div>
  );
};

export default Onboarding;

import { Divider } from 'antd';
import Button from 'hew/Button';
import Form from 'hew/Form';
import Input from 'hew/Input';
import { Modal } from 'hew/Modal';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import RegionPicker from 'components/RegionPicker';
import Spinner from 'components/Spinner';
import { awsMldeMsgExplainCharges } from 'constants/messages';
import { useStore } from 'contexts/Store';
import { useMakeClusterCreateCall } from 'hooks/useFetch';
import {
  networkValid,
  throwCreateClusterError,
  validateClusterRequestObject,
} from 'pages/Clusters/cluster-util';
import { BasicConfigOption, ClusterCreationResourcesConfig, OverallConfig } from 'saasTypes';
import { ec2ClusterCreationPrecheck } from 'services/api';
import { ModelCreateClusterRequest, ModelMasterConfig } from 'services/regional-bindings';
import handleError from 'utils/error';
import { decodePoolConfig, generateMasterConfig, getLatestVersion } from 'utils/saas';

import AdvancedConfigForm from './AdvancedConfig';
import BasicConfigSelect from './BasicConfig';
import { NetworkingParams } from './NetworkingParamsPicker';
import css from './NewAwsMldeClusterModal.module.scss';
import {
  basicProClusterRequest,
  basicStandardClusterRequest,
  initialState,
} from './NewAwsMldeClusterModal.settings';
import {
  getAvailableResources,
  getResourcesWithLimitReached,
  quotaAlert,
} from './NewAwsMldeClusterModalUtils';

interface Props {
  onFinish: () => Promise<void>;
  isModalOpen: boolean;
  onClose: () => void;
}

export const _NewAwsMldeClusterModal: React.FC<Props> = ({
  onFinish,
  isModalOpen = false,
  onClose,
}) => {
  /**
    This modal uses either a Basic config or an Advanced config to generate a
    `ModelCreateClusterRequest` value. The config type is set by toggling `isAdvanced`.

    With Basic config, `basicConfigSelection` determines whether `basicProClusterRequest`
    or `basicStandardClusterRequest` is used as the value for `ModelCreateClusterRequest`.

    With Advanced config, `AdvancedConfigForm` (with any number of `PoolConfigCard`s) is used
    to build `OverallConfig` and `PoolConfigs`. Those values are then used to generate
    a `ModelCreateClusterRequest`.
   */

  const [form] = Form.useForm();
  const ipRange = Form.useWatch('ipRange', form);
  const publicSubnet = Form.useWatch('public', form);
  const private0 = Form.useWatch('private0', form);
  const private1 = Form.useWatch('private1', form);

  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();

  const [canceler] = useState(() => new AbortController());
  const makeClusterCreateCall = useMakeClusterCreateCall(canceler);

  const [isAdvanced, setIsAdvanced] = useState<boolean>(initialState.isAdvanced);
  const [basicConfigSelection, setBasicConfigSelection] = useState<BasicConfigOption>(
    initialState.basicConfigSelection,
  );
  const [availableQuotaResources, setAvailableQuotaResources] =
    useState<ClusterCreationResourcesConfig>(initialState.defaultAccountResources);
  const [quotaWarning, setQuotaWarning] = useState<ReactNode>(null);
  const [overallConfig, setOverallConfig] = useState<OverallConfig>(initialState.overallConfig);
  const [supportedDetVersions, setSupportedDetVersions] = useState<string[]>([]);
  const [clusterName, setClusterName] = useState<string>('');
  const [clusterRegion, setClusterRegion] = useState<string>('');
  const [masterConfig, setMasterConfig] = useState<ModelMasterConfig>(() => {
    return generateMasterConfig(initialState.poolConfigs, initialState.resourceManager);
  });
  const [networkingParams, setNetworkingParams] = useState<Partial<NetworkingParams>>({});
  const updateNetworkingParams = useCallback(
    (p: Partial<NetworkingParams>) => setNetworkingParams((prev) => ({ ...prev, ...p })),
    [setNetworkingParams],
  );

  const handleSubmit = useCallback(() => {
    // handleOk uses async/await so that if `generateMasterConfig` throws a data validation error,
    // it can return a rejected promise, which prevents modal from closing

    const makeBasicClusterRequestObject = (
      basicClusterRequest: Required<
        Omit<
          ModelCreateClusterRequest,
          'detVersion' | 'ipRange' | 'subnetRanges' | 'appVersions' | 'auxiliaryResources'
        >
      >,
    ) => {
      const defaultDetVersion = getLatestVersion(supportedDetVersions) || '';
      return {
        ...basicClusterRequest,
        detVersion: defaultDetVersion,
        ipRange: supportMatrix?.awsSupportMatrix.defaultIpRange ?? '',
        name: clusterName,
        subnetRanges: supportMatrix?.awsSupportMatrix.defaultSubnetRanges,
      };
    };

    // Create the request object with all required creation parameters
    let clusterRequest = {} as ModelCreateClusterRequest;
    const { ipRange, ...subnetRanges } = networkingParams;

    switch (true) {
      case isAdvanced:
        if (!masterConfig) {
          throwCreateClusterError('Invalid configuration, please resolve validation errors');
        }
        clusterRequest = {
          ...overallConfig,
          ipRange: ipRange ?? '',
          masterConfig,
          name: clusterName,
          subnetRanges,
        };
        break;
      case basicConfigSelection === BasicConfigOption.Standard:
        clusterRequest = makeBasicClusterRequestObject(basicStandardClusterRequest);
        break;
      case basicConfigSelection === BasicConfigOption.Pro:
        clusterRequest = makeBasicClusterRequestObject(basicProClusterRequest);
        break;
    }
    validateClusterRequestObject(clusterRequest);

    return makeClusterCreateCall(clusterRegion, clusterRequest, onFinish);
  }, [
    basicConfigSelection,
    clusterName,
    clusterRegion,
    makeClusterCreateCall,
    isAdvanced,
    masterConfig,
    onFinish,
    overallConfig,
    networkingParams,
    supportMatrix?.awsSupportMatrix.defaultIpRange,
    supportMatrix?.awsSupportMatrix.defaultSubnetRanges,
    supportedDetVersions,
  ]);

  const toggleConfigType = useCallback(() => {
    setIsAdvanced((isAdvanced) => !isAdvanced);
  }, [setIsAdvanced]);

  useEffect(() => {
    const latestVersion = getLatestVersion(supportedDetVersions);
    if (overallConfig.detVersion === '' && latestVersion !== undefined) {
      // set default detVersion value once supportedDetVersions is loaded:
      setOverallConfig((c) => ({
        ...c,
        detVersion: latestVersion,
      }));
    }
  }, [supportedDetVersions, overallConfig.detVersion]);

  useEffect(() => {
    if (supportMatrix) {
      const nonRcSupportedDetVersions = supportMatrix.supportedDetVersions.filter(
        (v) => !v.includes('rc'),
      );
      setSupportedDetVersions(nonRcSupportedDetVersions);
      updateNetworkingParams({
        ipRange: supportMatrix.awsSupportMatrix.defaultIpRange,
        ...supportMatrix.awsSupportMatrix.defaultSubnetRanges,
      });
    }
  }, [supportMatrix, updateNetworkingParams]);

  useEffect(() => {
    const quotaUtilizationPrecheck = async () => {
      if (selectedOrg?.id !== undefined && selectedOrg?.id !== '') {
        try {
          const { quotas, utilization, orgId } = await ec2ClusterCreationPrecheck(
            {
              orgId: selectedOrg?.id || '',
              regionId: 'aws-us-west-2',
            },
            { signal: canceler.signal },
          );
          // sanity check
          if (orgId === selectedOrg?.id) {
            const availableResources = getAvailableResources(quotas, utilization);
            setAvailableQuotaResources(availableResources);
          }
        } catch (e) {
          // tslint:disable:no-empty
        }
      }
    };

    if (isModalOpen) {
      quotaUtilizationPrecheck();
    }

    return () => {
      canceler.abort();
    };
  }, [canceler, isModalOpen, selectedOrg?.id]);

  useEffect(() => {
    const resourcesAtLimit = getResourcesWithLimitReached(
      isAdvanced,
      overallConfig,
      masterConfig?.resource_pools.map(decodePoolConfig) ?? [],
      basicConfigSelection,
      initialState.requiredAccountResources,
      availableQuotaResources,
    );
    if (resourcesAtLimit.length) {
      const warning = quotaAlert(resourcesAtLimit);
      setQuotaWarning(warning);
    }
  }, [
    setAvailableQuotaResources,
    basicConfigSelection,
    isAdvanced,
    masterConfig?.resource_pools,
    overallConfig,
    availableQuotaResources,
  ]);

  const modalContent = useMemo(() => {
    if (!supportedDetVersions.length) return <Spinner tip="Loading..." />;
    return (
      <div className={css.modalContent}>
        <Form form={form} labelCol={{ span: 24 }}>
          <Form.Item label="Region" name="region">
            <RegionPicker
              curRegion={clusterRegion}
              id="region"
              regionFilter={(region) => region.startsWith('aws')}
              onChange={setClusterRegion}
            />
          </Form.Item>
          <Form.Item
            label="Cluster Name"
            name="clusterName"
            rules={[{ message: 'Cluster name required', required: true }]}>
            <Input
              value={clusterName}
              onChange={(e) => {
                setClusterName(e.target.value);
              }}
            />
          </Form.Item>
          <Divider />
          {quotaWarning ?? null}
          <h2 className={css.sectionHeader}>
            Configuration
            <Button onClick={toggleConfigType}>{isAdvanced ? 'Basic' : 'Advanced'}</Button>
          </h2>
        </Form>
        {isAdvanced ? (
          <AdvancedConfigForm
            clusterRegion={clusterRegion}
            form={form}
            networkingParams={networkingParams}
            overallConfig={overallConfig}
            supportedDetVersions={supportedDetVersions}
            updateMasterConfig={setMasterConfig}
            updateNetworkingParams={updateNetworkingParams}
            onUpdateOverallConfig={setOverallConfig}
          />
        ) : (
          <BasicConfigSelect selected={basicConfigSelection} onSelect={setBasicConfigSelection} />
        )}
        <Divider />
        <div> {awsMldeMsgExplainCharges} </div>
      </div>
    );
  }, [
    basicConfigSelection,
    clusterName,
    clusterRegion,
    form,
    isAdvanced,
    overallConfig,
    quotaWarning,
    supportedDetVersions,
    toggleConfigType,
    networkingParams,
    updateNetworkingParams,
  ]);

  const submitDisabled = useMemo(
    () =>
      !(networkingParams && networkValid(ipRange, publicSubnet, private0, private1)) ||
      !clusterName ||
      !masterConfig ||
      masterConfig?.resource_pools.length === 0 ||
      supportedDetVersions.length === 0,
    [
      clusterName,
      ipRange,
      masterConfig,
      networkingParams,
      private0,
      private1,
      publicSubnet,
      supportedDetVersions.length,
    ],
  );

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="medium"
      submit={{
        disabled: submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Create Cluster',
      }}
      title="New Cluster"
      onClose={onClose}>
      {modalContent}
    </Modal>
  );
};

import { RegionalClusters } from 'experimental/notifications/api';
import { ModelOrgUserWithOverrides } from 'saasTypes';
import { getClusterOverrides } from 'services/api';
import * as GlobalApi from 'services/global-bindings';

export const addOverridesToMembers = async (
  members: GlobalApi.ModelOrgUser[],
  clusters: RegionalClusters,
  selectedOrgId: string,
  fetchWithRetry: <T>(fn: () => Promise<T>) => Promise<T>,
  canceler: AbortController,
): Promise<ModelOrgUserWithOverrides[]> => {
  const membersAndOverrides = members as ModelOrgUserWithOverrides[];

  const clusterOverrideEntries = await fetchWithRetry(
    async () => await getClusterOverrides({ orgId: selectedOrgId }, { signal: canceler.signal }),
  );

  const overridesByCluster: { [key: string]: ModelOrgUserWithOverrides[] } = {};

  // Only include overrides tied to existing clusters (there may be stale override entries from previously deleted clusters)
  for (const region in clusters) {
    const clustersInRegion = clusters[region];
    clustersInRegion.forEach((cluster) => {
      if (clusterOverrideEntries[cluster.id]) {
        const clusterID: string = cluster.id;
        overridesByCluster[clusterID] = clusterOverrideEntries[clusterID];
      }
    });
  }

  membersAndOverrides.map((member) => {
    const memOverrides: { [key: string]: string } = {};
    for (const cluster in overridesByCluster) {
      const clusterOverrides = overridesByCluster[cluster];
      clusterOverrides.forEach((overrideUser: ModelOrgUserWithOverrides) => {
        if (overrideUser.id === member.id) {
          memOverrides[cluster] = overrideUser.role;
        }
      });
    }
    member.overrides = memOverrides;
    return member;
  });
  return membersAndOverrides;
};

import { Alert } from 'antd';
import Form from 'hew/Form';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import InstanceTypeDropdown from 'components/InstanceTypeDropdown';
import Link from 'components/Link';
import Spinner from 'components/Spinner';
import useBackendProvider from 'hooks/useBackendProvider';
import { useFetchWithRetry } from 'hooks/useFetch';
import { ClusterDetails, InstanceUse } from 'saasTypes';
import { getCluster, reprovisionCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { Backend } from 'types';
import handleError, { ErrorLevel, ErrorType } from 'utils/error';

import { MachineTypeDropdown } from './GkeCluster/MachineTypeDropdown';
import css from './ReprovisionMasterModal.module.scss';

interface Props {
  onClose?: () => void;
  cluster: GlobalApi.ModelClusterInfo;
  orgId: string;
}

export const _ReprovisionMasterModal: React.FC<Props> = ({ orgId, cluster, onClose }) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [clusterDetails, setClusterDetails] = useState<ClusterDetails>();
  const backendProviderName = useBackendProvider(canceler);
  const [hideMasterDropdown, setHideMasterDropdown] = useState(true);
  const onShowDropdown = useCallback(() => {
    setHideMasterDropdown(false);
  }, [setHideMasterDropdown]);
  const [masterInstanceType, setMasterInstanceType] = useState('');

  const reprovisionClusterRequest = useCallback(async () => {
    if (!masterInstanceType) return;
    try {
      await fetchWithRetry(
        async () =>
          await reprovisionCluster(
            {
              clusterId: cluster.id,
              instanceType: { masterInstanceType: masterInstanceType },
              orgId,
              regionId: cluster.location,
            },
            { signal: canceler.signal },
          ),
      );
    } catch (error) {
      throw handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to update',
        silent: false,
        type: ErrorType.Server,
      });
    }
  }, [canceler.signal, cluster, fetchWithRetry, masterInstanceType, orgId]);

  const handleSubmit = useCallback(async () => {
    await reprovisionClusterRequest();
    onClose?.();
  }, [reprovisionClusterRequest, onClose]);

  const fetchClusterDetails = useCallback(
    async (clusterId: string, orgId: string) => {
      try {
        const clusterDetailsRes = await fetchWithRetry(
          async () =>
            await getCluster(
              {
                clusterId,
                orgId,
                regionId: cluster.location,
              },
              { signal: canceler.signal },
            ),
        );

        setClusterDetails(clusterDetailsRes);
        setMasterInstanceType(clusterDetailsRes.masterInstanceType);
      } catch (e) {
        handleError(e, {
          publicSubject: 'Failed to fetch cluster details',
        });
      }
    },
    [canceler.signal, cluster.location, fetchWithRetry],
  );

  useEffect(() => {
    fetchClusterDetails(cluster?.id, orgId);
  }, [cluster?.id, orgId, fetchClusterDetails]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  const modalContent = useMemo(() => {
    if (!clusterDetails?.masterInstanceType) return <Spinner tip="Loading cluster details" />;

    return (
      <div className={css.formWrapper}>
        <Form className={css.form}>
          <div>
            {/* wrap Alert with div to add margin */}
            <Alert
              message="Changing the master instance type may cause interruptions in running workloads. Consider scheduling this activity at a time when it is least disruptive."
              type="warning"
            />
          </div>
          {(backendProviderName === Backend.EC2 || backendProviderName === Backend.EKS) && (
            <Form.Item
              className={css.formItemWithLink}
              label={
                <>
                  <span>Master Instance Type</span>
                  <Link
                    path="https://aws.amazon.com/ec2/instance-types/#General_Purpose"
                    popout={true}>
                    List of Instance Types
                  </Link>
                </>
              }
              labelCol={{ span: 24 }}
              name="masterInstanceType">
              <InstanceTypeDropdown
                buttonLabel={masterInstanceType}
                clusterRegion={cluster.location}
                use={InstanceUse.Master}
                onSelect={setMasterInstanceType}
              />
            </Form.Item>
          )}
          {backendProviderName === Backend.GKE && (
            <Form.Item
              className={css.formItemWithLink}
              label={
                <>
                  <span>Master Instance Type</span>
                  <Link
                    external={true}
                    path="https://cloud.google.com/compute/docs/general-purpose-machines#n1_machines"
                    popout={true}>
                    List of Instance Types
                  </Link>
                </>
              }
              labelCol={{ span: 24 }}
              name="masterInstanceType">
              <MachineTypeDropdown
                buttonLabel={masterInstanceType}
                clusterRegion={cluster.location}
                hide={hideMasterDropdown}
                showArrow={false}
                use={InstanceUse.Master}
                onSelect={setMasterInstanceType}
                onVisibleChange={onShowDropdown}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    );
  }, [
    clusterDetails?.masterInstanceType,
    backendProviderName,
    masterInstanceType,
    cluster.location,
    hideMasterDropdown,
    onShowDropdown,
  ]);

  const submitDisabled = useMemo(
    () => !masterInstanceType || clusterDetails?.masterInstanceType === masterInstanceType,
    [clusterDetails?.masterInstanceType, masterInstanceType],
  );

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="medium"
      submit={{
        disabled: submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Reprovision Master',
      }}
      title={`Reprovision Master for ${cluster?.name}`}
      onClose={onClose}>
      {modalContent}
    </Modal>
  );
};

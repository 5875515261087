import { useModal } from 'hew/Modal';
import React, { useEffect } from 'react';

import useUI from 'components/UIManager';
import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';

import MspHeader from './MspHeader';
import css from './Navigation.module.scss';
import NavigationHeader from './NavigationHeader';
import NavigationSideBar from './NavigationSideBar';
import NavigationTabbar from './NavigationTabbar';
import TrialBanner from './Trial/TrialBanner';
import { _TrialEndModal } from './Trial/TrialEndModal';

interface Props {
  children?: React.ReactNode;
}

const Navigation: React.FC<Props> = ({ children }) => {
  const { orgState } = useStore();
  const { ui } = useUI();
  const storeDispatch = useStoreDispatch();
  const { Component: TrialEndModalComponent, open: openTrialEndModal } = useModal(_TrialEndModal);
  const orgLicenseExpired = orgState?.selectedOrg && !orgState.selectedOrg.licensingStatus.active;

  useEffect(() => {
    if (orgLicenseExpired) {
      openTrialEndModal();
      storeDispatch({ type: StoreAction.SetDisplayTrialBanner, value: true });
    }
  }, [orgLicenseExpired, storeDispatch, openTrialEndModal]);

  return (
    <>
      {ui.showChrome && <TrialBanner openTrialEndModal={openTrialEndModal} />}
      {ui.showChrome && <TrialEndModalComponent />}
      {ui.showChrome && ui.showMspHeader() && <MspHeader />}
      {ui.showChrome && <NavigationHeader />}
      <div className={css.base}>
        {ui.showChrome && <NavigationSideBar />}
        {children}
        {ui.showChrome && <NavigationTabbar />}
      </div>
    </>
  );
};

export default Navigation;

import Spinner from 'hew/Spinner';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { routeToExternalUrl, routeToReactUrl } from 'utils/routes';
import { getGLCPHost, isGLCPDeployment } from 'utils/saas';

import Error from './Error';

const reasonParam = 'reason';

const ErrorLanding: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [message, setMessage] = useState<string | null>();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (loaded) return;
    const reason = searchParams.get(reasonParam);
    setMessage(reason);
    setLoaded(true);
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.delete(reasonParam);
      return newSearchParams;
    });
  }, [loaded, searchParams, setSearchParams]);

  if (!loaded) return <Spinner />;
  return (
    <Error
      actionText={isGLCPDeployment() ? 'Exit' : 'Retry Login'}
      message={message ?? undefined}
      onAction={() =>
        isGLCPDeployment() ? routeToExternalUrl(getGLCPHost()) : routeToReactUrl('/login')
      }
    />
  );
};

export default ErrorLanding;

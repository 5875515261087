import { RouteConfig } from 'types';

const routes: RouteConfig[] = [
  {
    id: 'signIn',
    needAuth: false,
    path: '/login',
    title: 'Login',
  },
  {
    id: 'signOut',
    needAuth: false,
    path: '/logout',
    title: 'Logout',
  },
  {
    id: 'signedOut',
    needAuth: false,
    path: '/logged-out',
    title: 'Logged out',
  },
  {
    id: 'members',
    needAuth: true,
    path: '/members',
    title: 'Members',
  },
  {
    id: 'clusters', // temporary route
    needAuth: true,
    path: '/clusters',
    title: 'Clusters',
  },
  {
    id: 'backendProvider',
    needAuth: true,
    path: '/organization',
  },
  {
    id: 'logView',
    needAuth: true,
    path: '/clusters/:clusterId/logs',
    title: 'Cluster Logs',
  },
  {
    id: 'trialLanding',
    needAuth: false,
    path: '/trial',
    title: 'Trial Sign Up',
  },
  {
    id: 'customer-orgs',
    needAuth: true,
    path: '/customer-orgs',
    title: 'Customer Organizations',
  },
];

export default routes;

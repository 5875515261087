/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';

import { globalStorage } from 'globalStorage';
import * as GlobalApi from 'services/global-bindings';
import { User } from 'types';
import handleError, { ErrorLevel, ErrorType } from 'utils/error';

export const analyticsEvents = {
  clusterCreationInitiated: 'clusterCreationInitiated',
  logIn: 'logIn',
  logOut: 'logOut',
  orgChange: 'orgChange',
  orgNameUpdate: 'orgNameUpdated',
  orgQuotaUpdate: 'orgQuotaUpdate',
  trialRequest: 'trialRequest',
};

class Telemetry {
  isTelemetryEnabled = false;

  constructor() {
    if (process.env.SEGMENT_KEY && window.analytics?.load) {
      if (!/^[a-z0-9]{32}$/i.test(process.env.SEGMENT_KEY)) {
        handleError(undefined, {
          level: ErrorLevel.Error,
          publicMessage: 'Invalid segment key',
          silent: true,
        });
      } else {
        window.analytics.load(process.env.SEGMENT_KEY);
        this.isTelemetryEnabled = true;
      }
    }
  }

  update(org: GlobalApi.ModelListOrgsEntry, user: User) {
    if (!this.isTelemetryEnabled || !window.analytics?.identify || globalStorage.userIdentified) {
      return;
    }

    /*
     * Segment key should be 32 characters composed of upper case letters,
     * lower case letters and numbers 0-9.
     */
    try {
      window.analytics.identify(user.userId, {
        orgId: org.id,
      });
      globalStorage.userIdentified = true;
      this.track(analyticsEvents.logIn);
    } catch (e) {
      handleError(e, {
        level: ErrorLevel.Error,
        publicMessage: 'Failed to set telemetry identity.',
        silent: true,
        type: ErrorType.Server,
      });
    }
  }

  reset() {
    globalStorage.removeUserIdenfitied();
    if (this.isTelemetryEnabled && window.analytics?.reset) window.analytics.reset();
    this.track(analyticsEvents.logOut);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  page(...args: any[]) {
    if (this.isTelemetryEnabled && window.analytics?.page) window.analytics.page(...args);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  track(event: string, ...args: any[]) {
    if (this.isTelemetryEnabled && window.analytics?.track) window.analytics.track(event, ...args);
  }
}

// Create the instance outside of the hook to ensure a single instance.
export const telemetryInstance = new Telemetry();

interface TelemetryHook {
  track: (...args: any[]) => void;
  trackPage: (...args: any[]) => void;
  updateTelemetry: (org: GlobalApi.ModelListOrgsEntry, user: User) => void;
  resetTelemetry: () => void;
}

const useTelemetry = (): TelemetryHook => {
  const telemetry = useRef<Telemetry>(telemetryInstance);
  return {
    resetTelemetry: telemetry.current.reset.bind(telemetry.current),
    track: telemetry.current.track.bind(telemetry.current),
    trackPage: telemetry.current.page.bind(telemetry.current),
    updateTelemetry: telemetry.current.update.bind(telemetry.current),
  };
};

export default useTelemetry;

import React, { useLayoutEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import useUI from 'components/UIManager';
import AuthProvider from 'contexts/Auth';
import StoreProvider from 'contexts/Store';
import UserProvider from 'contexts/User';
import useKeyTracker from 'hooks/useKeyTracker';
import usePageVisibility from 'hooks/usePageVisibility';
import useResize from 'hooks/useResize';
import useSyncHeaderFooterTheme from 'hooks/useSyncHeaderFooterTheme';
import ErrorLanding from 'pages/Error/ErrorLanding';
import TrialLanding from 'pages/Landing/TrialLanding';
import SignedOut from 'pages/SignedOut';
import SignIn from 'pages/SignIn';
import SignOut from 'pages/SignOut';
import AuthenticatedRouter from 'routes/AuthenticatedRouter';
import { paths } from 'routes/utils';
import { correctViewportHeight } from 'utils/browser';
import { useInitApi } from 'utils/dialogApi';
import { isMspDeployment } from 'utils/saas';

import css from './App.module.scss';

import 'antd/dist/reset.css';

const AppView: React.FC = () => {
  useInitApi();
  const resize = useResize();

  const { ui: isHeaderLoaded } = useUI();

  useKeyTracker();
  usePageVisibility();
  useSyncHeaderFooterTheme();

  const isMsp = isMspDeployment();
  // Correct the viewport height size when window resize occurs.
  useLayoutEffect(() => {
    if (!isHeaderLoaded) {
      return;
    }
    correctViewportHeight(isMsp);
  }, [resize, isMsp, isHeaderLoaded]);

  return (
    <div className={css.base}>
      <main>
        <Routes>
          <Route element={<TrialLanding />} path={paths.trial()} />
          <Route element={<SignIn />} path={paths.login()} />
          <Route element={<SignOut />} path={paths.logout()} />
          <Route element={<SignedOut />} path={paths.loggedOut()} />
          <Route element={<ErrorLanding />} path={paths.error()} />
          <Route
            element={
              <AuthProvider>
                <UserProvider>
                  <AuthenticatedRouter />
                </UserProvider>
              </AuthProvider>
            }
            path="*"
          />
        </Routes>
      </main>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <StoreProvider>
        <AppView />
      </StoreProvider>
    </HelmetProvider>
  );
};

export default App;

import { ResourcePoolUse } from 'saasTypes';
import { Backend } from 'types';

export const awsMldeClusterDefaults = {
  AUX_INSTANCE_TYPE: 'c5.large',
  // TODO(DET-7323): to be defined as an enum.
  AWS: Backend.EC2,
  COMPUTE_INSTANCE_TYPE: 'g4dn.xlarge',
  COMPUTE_INSTANCE_TYPE_PRO: 'p3.8xlarge',
  CPU_POOL_NAME: 'cpu_pool',
  CPU_SLOTS_ALLOWED: false,
  GPU_POOL_NAME: 'gpu_pool',
  MASTER_INSTANCE_TYPE_PRO: 'm5.large',
  MASTER_INSTANCE_TYPE_STANDARD: 'm5.large',
  MAX_AUX_INSTANCES: 1,
  MAX_COMPUTE_INSTANCES: 2,
  MAX_IDLE_AGENT_PERIOD: '10m',
  MIN_AUX_INSTANCES: 0,
  MIN_COMPUTE_INSTANCES: 0,
  REQUIRED_EC2VPCELASTICIPS: 2,
  REQUIRED_NATGATEWAYS: 1,
  REQUIRED_VPCS: 1,
  RP_PROVIDER_TYPE: Backend.EC2,
};

// To be cleaned up with only what is required
export const awsMldmClusterDefaults = {
  AUX_INSTANCE_TYPE: 'c5.4xlarge',
  AWS: Backend.EKS,
  COMPUTE_INSTANCE_TYPE: 'c5.4xlarge',
  COMPUTE_INSTANCE_TYPE_PRO: 'p3.8xlarge',
  CPU_POOL_NAME: 'cpu_pool',
  CPU_SLOTS_ALLOWED: false,
  GPU_POOL_NAME: 'gpu_pool',
  MASTER_INSTANCE_TYPE_STANDARD: 'm5.2xlarge',
  MAX_AUX_INSTANCES: 4,
  MAX_COMPUTE_INSTANCES: 4,
  MIN_AUX_INSTANCES: 0,
  MIN_COMPUTE_INSTANCES: 0,
  REQUIRED_EC2VPCELASTICIPS: 2,
  REQUIRED_NATGATEWAYS: 1,
  REQUIRED_VPCS: 1,
  RP_PROVIDER_TYPE: Backend.EC2,
};

export const defaultResourcePoolUse = ResourcePoolUse.Aux;
